import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css'; // Import the default styles
import { withOktaAuth } from '@okta/okta-react';


import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Paper,
  Button
} from '@material-ui/core';
import { Rowing } from '@material-ui/icons';

const colors = {
  PASSION_SCORE: '#FF5733',
  ORGANIC_STREAMS: '#f7a35c',
  SKIP_RATE: '#90ee7e',
  STREAM_COUNT: '#7cb5ec', // Add your desired color code for the STREAM_COUNT column
  INTERNAL_STREAMS: '#7cb5ec',
  MRC_STREAMS: '#5C73A9',
  TIKTOK_CREATES: '#A0DADF'
};


class LinePlot extends Component {
  state = {
    selectedColumns: [],
    data: [], // Add a data state to store the fetched data
    loading: true, // Add a loading state to track if data is being fetched
    selectedDateRange: [/* Initial date range values */],
    selectedRegion: 'us', // Add selectedRegion to the state
    selectedPeriod: 'weekly',

  };

  componentDidMount() {
    this.fetchData(); // Fetch data when the component mounts
  }

  fetchData() {
    // Fetch data from your API endpoint
    const { endpoint,row, table_dir, id_column,selectedDateRange} = this.props;
    const {selectedRegion,selectedPeriod} = this.state;
    const queryParams = new URLSearchParams();
    console.log(row)

    queryParams.append(id_column, row[id_column]);
    queryParams.append('UNIFIED_SONG_ID', row['UNIFIED_SONG_ID']);
    queryParams.append('REGION', selectedRegion);
    queryParams.append('PERIOD', selectedPeriod);
    const queryString = queryParams.toString();
    
    const apiUrl = `${endpoint}/${table_dir}?${queryParams}`; // Replace with your API endpoint URL
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
     const headers = {
        Authorization: `Bearer ${accessToken}`
      };
    this.setState({ loading: true }); // Set loading to true when fetching data

    fetch(apiUrl,{
      headers: headers // Add the headers to the fetch options
    })
      .then((response) => response.json())
      .then((data) => {
        // Convert date strings to Unix timestamps
        const parsedData = data.map((item) => ({
          ...item,
          DATE: new Date(item.DATE).getTime(), // Convert the date string to Unix timestamp
        }));

        console.log(parsedData);
        // Determine the selectedColumns based on the available columns in the fetched data
        const selectedColumns = Object.keys(parsedData[0]).filter((column) => column !== 'DATE');
        const minDate = Math.min(...parsedData.map((item) => item.DATE));
        const maxDate = Math.max(...parsedData.map((item) => item.DATE));

        this.setState({
          data: parsedData,
          selectedColumns,
          selectedDateRange: [minDate, maxDate], // Set the initial date range
          loading: false,
          minDate, // Pass minDate as a prop
          maxDate, 
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  }
  handleRegionChange = (region) => {
    this.setState({ selectedRegion: region }, () => {
      // After setting the selected region, fetch data based on the selected region
      this.fetchData();
    });
  };
  handlePeriodChange = (period) => {
    this.setState({ selectedPeriod: period }, () => {
      // After setting the selected region, fetch data based on the selected region
      this.fetchData();
    });
  };

  handleToggle = (event) => {
    const { value } = event.target;
    this.setState((prevState) => {
      if (prevState.selectedColumns.includes(value)) {
        return {
          selectedColumns: prevState.selectedColumns.filter((column) => column !== value),
        };
      } else {
        return {
          selectedColumns: [...prevState.selectedColumns, value],
        };
      }
    });
  };

  handleDateRangeChange = (selectedDateRange) => {
    this.setState({ selectedDateRange });
  };
  

  render() {
    const { data, loading,selectedDateRange ,selectedPeriod,selectedRegion, minDate, maxDate,} = this.state;

    const { selectedColumnsLeft, selectedColumnsRight,showToggles } = this.props;
    console.log(loading)
    
  
    // Prepare data for Highcharts
    const config = {
      title: {
        text: 'Stream Details',
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%b %e}',
        },
      },
      yAxis: [
        // Left Y-axis for selectedColumnsLeft
        {
          title: {
            text: 'Streams',
          },
          labels: {
            format: '{value}', // You can customize the label format
          },
          opposite: false, // Set to true if you want this axis to be on the opposite side
        },
        // Right Y-axis for selectedColumnsRight
        {
          title: {
            text: 'Additional Data',
          },
          labels: {
            format: '{value}', // You can customize the label format
          },
          opposite: true, // Set to true if you want this axis to be on the opposite side
        },
      ],
      colors: colors,
      tooltip: {
        formatter: function () {
          let tooltip = `<b>${Highcharts.dateFormat('%b %e, %Y', this.x)}</b><br/>`;
          this.points.forEach((point) => {
            const value = point.y;
            let formattedValue = value;
            if (value >= 1000 && value < 1000000) {
              formattedValue = (value / 1000).toFixed(1) + 'K';
            } else if (value >= 1000000 && value < 1000000000) {
              formattedValue = (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000000000) {
              formattedValue = (value / 1000000000).toFixed(1) + 'B';
            }
            tooltip += `<span style="color:${point.series.color}">\u25CF</span> ${
              point.series.name
            }: <b>${formattedValue}</b><br/>`;
          });
          return tooltip;
        },
        shared: true,
      },
      series: [
        ...selectedColumnsLeft.map((column) => ({
          name: column,
          data: data
            .filter((item) => {
              // Filter data within the selected date range
              const date = item.DATE;
              return date >= selectedDateRange[0] && date <= selectedDateRange[1];
            })
            .map((item) => [item.DATE, item[column]]),
          color: colors[column],
          yAxis: 0, // Associate with the first (left) Y-axis
        })),
        ...selectedColumnsRight.map((column) => ({
          name: column,
          data: data
            .filter((item) => {
              // Filter data within the selected date range
              const date = item.DATE;
              return date >= selectedDateRange[0] && date <= selectedDateRange[1];
            })
            .map((item) => [item.DATE, item[column]]),
          color: colors[column],
          yAxis: 1, // Associate with the second (right) Y-axis
        })),
      ],
    };
  
    return (
      <Paper>
        <div>
          {showToggles && (
            <div style={{ textAlign: 'left' }}>
              <div>
                <Button
                  variant={selectedRegion === 'us' ? 'contained' : 'outlined'}
                  onClick={() => this.handleRegionChange('us')}
                >
                  US
                </Button>
                <Button
                  variant={selectedRegion === 'global' ? 'contained' : 'outlined'}
                  onClick={() => this.handleRegionChange('global')}
                >
                  Global
                </Button>
              </div>
              <div>
                <Button
                  variant={selectedPeriod === 'daily' ? 'contained' : 'outlined'}
                  onClick={() => this.handlePeriodChange('daily')}
                >
                  Daily
                </Button>
                <Button
                  variant={selectedPeriod === 'weekly' ? 'contained' : 'outlined'}
                  onClick={() => this.handlePeriodChange('weekly')}
                >
                  Weekly
                </Button>
              </div>
            </div>
          )}
    
          {loading ? (
            // Show loading message or spinner while data is being fetched
            <Typography>Loading...</Typography>
          ) : (
            // Render the chart and slider when data is not loading
            <>
              <HighchartsReact highcharts={Highcharts} options={config} />
              <div style={{ margin: '2px 20px' }}>
                <Slider
                  range
                  value={selectedDateRange}
                  min={minDate} // Set minDate as the minimum value
                  max={maxDate}
                  onChange={this.handleDateRangeChange}
                />
              </div>
            </>
          )}
        </div>
      </Paper>
    );
    
  }
  
}

export default withOktaAuth(LinePlot);
