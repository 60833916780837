import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';
import { Paper, Typography } from '@material-ui/core';
import { formatValue } from './Format';

class ScatterPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    console.log(data);
    if (data) {
      this.setState({ data, loading: false });
    } else {
      this.fetchData();
    }
  }

  fetchData() {
    console.log('coooool');
    const { sub_endpoint, xKeyName, yKeyName, artistId, authState, properties } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const queryParams = new URLSearchParams();

    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        const value = properties[key];
        queryParams.append(key, value);
      }
    }
    const queryString = queryParams.toString();

    fetch(`${endpoint}/${sub_endpoint}?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        this.setState({
          data,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { dotSize, colorShade, chart_dotTitle, xKeyTitle, xKeyName, yKeyTitle, yKeyName, title, backgroundColor, hideLegend, avg } = this.props;
    const { loading } = this.state;
    console.log(this.state.data);
    let transformedData = [];
    if (this.state.data && avg) {
      const sumDotSize = this.state.data.reduce((sum, item) => sum + item[dotSize], 0);
      transformedData = this.state.data.map((item) => ({
        x: item[xKeyName],
        y: item[yKeyName],
        color: '#7FB2D4',
        marker: {
          radius: (item[dotSize] / sumDotSize) * 100,
        },
        name: item[chart_dotTitle],
        dotSize: item[dotSize],
      }));
    } else {
      transformedData = this.state.data.map((item) => ({
        x: item[xKeyName],
        y: item[yKeyName],
        color: '#7FB2D4',
        marker: {
          radius: item[dotSize],
        },
        name: item[chart_dotTitle],
        dotSize: item[dotSize],
      }));
    }
    console.log(transformedData);

    const chartOptions = {
      chart: {
        type: 'scatter',
        backgroundColor: backgroundColor,
      },
      title: {
        text: title,
      },
      xAxis: {
        title: {
          text: xKeyTitle ? xKeyTitle : xKeyName,
        },
        labels: {
          formatter: function () {
            return formatValue(this.value, 'number', 'number');
          },
        },
      },
      yAxis: {
        title: {
          text: yKeyTitle ? yKeyTitle : yKeyName,
        },
        labels: {
          formatter: function () {
            return formatValue(this.value, 'number', 'number');
          },
        },
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: dotSize,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
              },
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormat: `<b>{point.name}</b><br>${formatValue(xKeyName, 'number', 'number')}: {point.x}<br>${formatValue(yKeyName, 'number', 'number')}: {point.y}<br>${formatValue(dotSize,'number','number')}: {point.dotSize}`,
          },
        },
      },
      series: [
        {
          name: 'Data',
          data: transformedData,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
          color: colorShade,
        },
      ],
    };

    if (hideLegend) {
      chartOptions.legend = {
        enabled: false,
      };
    }

    if (colorShade) {
      chartOptions.series[0].color = colorShade;
    }

    return (
      <div style={{ backgroundColor }}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </>
        )}
      </div>
    );
  }
}

export default withOktaAuth(ScatterPlot);
