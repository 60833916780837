import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { endpoint } from './mainEndpoint';
// import { Rowing } from '@material-ui/icons';
// import { Typography, Paper, IconButton, Tabs, Tab } from '@material-ui/core';
// import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
// import LinePlot from './LinePlot';
import { withOktaAuth } from '@okta/okta-react';
import DataBoxes from './DataBoxes';
import './App.css';
import ExportArtist from './ExportArtist';

class ArtistPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artistName: null, // Initialize artistName state
      albums: [], // Initialize albums state as an array
      isLoading: true, // Initialize loading state
      selectedTabIndex: 0, // Initialize the selected tab index
      data : [
        // Define data for each DataGroup here
        // Example:
        {
            componentType: 'DataComponent',
            info: [
                {
                    artistId: this.props.artistId,
                    data:[],
                    loading:true,
                    title: "Last Week US Streams",
                    sub_endpoint: 'artist_lw_us_streams',
                    chart_endpoint: 'artist_us_streams_historical',
                    value_format: 'number',
                    chart_xKeyName: "DATE",
                    chart_title: "Last Week US Streams",
                    chart_xAxisTitle: 'Date',
                    chart_yAxisTitle: 'Values',
                    properties: {
                        artistId: this.props.artistId,
                    },
                    chart_yKeyNames: {
                        MRC_AUDIO_STREAMS_WEEK: {
                            displayName: 'US Streams',
                            color: '#5C73A9',
                        },
                    }
                },
                {
                  artistId: this.props.artistId,
                  data:[],
                  loading:true,
                  title: "Last Week Global Streams",
                  sub_endpoint: 'artist_lw_global_streams',
                  chart_endpoint: 'artist_us_streams_historical',
                  value_format: 'number',
                  chart_xKeyName: "DATE",
                  chart_title: "Last Week Global Streams",
                  chart_xAxisTitle: 'Date',
                  chart_yAxisTitle: 'Values',
                  properties: {
                      artistId: this.props.artistId,
                  },
                  chart_yKeyNames: {
                      MRC_AUDIO_STREAMS_WEEK: {
                          displayName: 'US Streams',
                          color: '#5C73A9',
                      },
                  }
              },
                
                
                {
                    artistId: this.props.artistId,
                    data:[],
                    loading:true,
                    title: 'Instagram Engagement Score',
                    value_format: 'literal',
                    sub_endpoint: 'artist_ig_engagement',
                    chart_endpoint: 'artist_ig_percentile_historical',
                    chart_xKeyName: "DATE",
                    chart_title: "Instagram Engagement Score",
                    chart_xAxisTitle: 'Date',
                    chart_yAxisTitle: 'Values',
                    properties: {
                        artistId: this.props.artistId,
                    },
                    chart_yKeyNames: {
                        INSTAGRAM_ENGAGEMENT_PERCENTILE: {
                            displayName: 'Instagram Engagement Score',
                            color: '#AB6FCC',
                        },

                    }
                },
                {
                    artistId: this.props.artistId,
                    data:[],
                    loading:true,
                    title: 'TikTok Engagement Score',
                    value_format: 'literal',
                    sub_endpoint: 'artist_tt_engagement',
                    chart_endpoint: 'artist_tt_percentile_historical',
                    chart_xKeyName: "DATE",
                    chart_title: "TikTok Engagement Score",
                    chart_xAxisTitle: 'Date',
                    chart_yAxisTitle: 'Values',
                    properties: {
                        artistId: this.props.artistId,
                    },
                    chart_yKeyNames: {
                        TIKTOK_ENGAGEMENT_PERCENTILE: {
                            displayName: 'TikTok Engagement Score',
                            color: '#A7DBE0',
                        },

                    }
                },
                {
                  artistId: this.props.artistId,
                  data:[],
                  loading:true,
                  title: 'Streams Per Listener',
                  value_format: 'literal',
                  sub_endpoint: 'artist_spl',
                  chart_endpoint: 'artist_spl_historical',
                  chart_xKeyName: "DATE",
                  chart_title: "Streams Per Listener",
                  chart_xAxisTitle: 'Date',
                  chart_yAxisTitle: 'Values',
                  properties: {
                      PERIOD: 'daily',
                      artistId: this.props.artistId,
                  },
                  chart_yKeyNames: {
                      UNIQUE_STREAMS: {
                          displayName: 'Streams',
                          color: '#FF5733',
                          defaultNotVisible: true
                      },
                      UNIQUE_USERS: {
                          displayName: 'Users',
                          color: '#f7a35c',
                          defaultNotVisible: true
                      },
                      SPL: {
                          displayName: 'SPL',
                          color: '#f7a60c',
                      }
                  }
              },
                
            ]

            
        },
        {
          componentType: 'LinePlotSimple',
          info: {
              data:[],
              chart_png:'',
              loading:true,
              properties: {
                  artistId: this.props.artistId,
              },
              sub_endpoint: 'artist_lw_us_streams',
              chart_endpoint: 'artist_us_streams_historical',
              value_format: 'number',
              chart_xKeyName: "DATE",
              chart_title: "Weekly US Streams",
              chart_background_color: "#F0F0F0",
              chart_xAxisTitle: 'Date',
              chart_yAxisTitle: 'Values',
              hideLegend: true,
              chart_pct: false,
              chart_yKeyNames: {
                  US: {
                      displayName: 'US Streams',
                      color: '#5C73A9',
                  },
                  GLOBAL: {
                    displayName: 'Global Streams',
                    color: '#61B338',
                },
              }
          }
      },
      {
        componentType: 'DataComponent',
        info: [
            {
              artistId: this.props.artistId,
              data:[],
              loading:true,
                title: "Spotify Followers",
                sub_endpoint: 'artist_sp_followers',
                chart_endpoint: 'artist_sp_followers_historical',
                value_format: 'number',
                chart_xKeyName: "DATE",
                chart_title: "Spotify Followers",
                chart_xAxisTitle: 'Date',
                chart_yAxisTitle: 'Follower Count',
                properties: {
                  artistId: this.props.artistId,
                },
                chart_yKeyNames: {
                    FOLLOWER_COUNT: {
                        displayName: 'Spotify Followers',
                        color: '#07BC0C',
                    },
                }
            },
            {
              artistId: this.props.artistId,
              data:[],
              loading:true,
                title: 'TikTok Followers',
                value_format: 'number',
                sub_endpoint: 'artist_tt_followers',
                chart_endpoint: 'artist_tt_followers_historical',
                chart_xKeyName: "DATE",
                chart_title: "TikTok Followers",
                chart_xAxisTitle: 'Date',
                chart_yAxisTitle: 'Follower Count',
                properties: {
                  artistId: this.props.artistId,
                },
                chart_yKeyNames: {
                    FOLLOWER_COUNT: {
                        displayName: 'TikTok Followers',
                        color: '#A7DBE0',
                    },
                }
            },
            {
              artistId: this.props.artistId,
              data:[],
              loading:true,
                title: 'Instagram Followers',
                value_format: 'number',
                sub_endpoint: 'artist_ig_followers',
                chart_endpoint: 'artist_ig_followers_historical',
                chart_xKeyName: "DATE",
                chart_title: "Instagram Followers",
                chart_xAxisTitle: 'Date',
                chart_yAxisTitle: 'Fos',
                properties: {
                  artistId: this.props.artistId,
                },
                chart_yKeyNames: {
                    FOLLOWER_COUNT: {
                        displayName: 'Instagram Followers',
                        color: '#AB6FCC',
                    },

                }
            },
            {
              artistId: this.props.artistId,
              data:[],
              loading:true,
                title: 'YouTube Subscibers',
                value_format: 'number',
                sub_endpoint: 'artist_yt_followers',
                chart_endpoint: 'artist_yt_followers_historical',
                chart_xKeyName: "DATE",
                chart_title: "YouTube Subscribers",
                chart_xAxisTitle: 'Date',
                chart_yAxisTitle: 'Values',
                properties: {
                  artistId: this.props.artistId,
                },
                chart_yKeyNames: {
                    SUBSCRIBER_COUNT: {
                        displayName: 'YouTube Subscribers',
                        color: '#E74C3C',
                    },

                }
            },
        ]
    },
    {
      componentType: 'LinePlotSimple',
      info: {
        artistId: this.props.artistId,
              data:[],
              loading:true,
          properties: {
              artistId: this.props.artistId,
          },
          chart_endpoint: 'artist_all_followers_historical',
          value_format: 'number',
          chart_xKeyName: "DATE",
          chart_title: "Followers",
          chart_xAxisTitle: 'Date',
          chart_yAxisTitle: 'Values',
          chart_pct: true,
          hideLegend: false,
          chart_background_color: "#F0F0F0",
          chart_yKeyNames: {
              SPOTIFY_FOLLOWER_COUNT: {
                  displayName: 'Spotify Followers',
                  color: '#07BC0C',
              },
              IG_FOLLOWER_COUNT: {
                  displayName: 'Instagram Followers',
                  color: '#AB6FCC',
              },
              TIKTOK_FOLLOWER_COUNT: {
                  displayName: 'TikTok Followers',
                  color: '#A7DBE0',
              },
              YOUTUBE_SUBSCRIBER_COUNT: {
                  displayName: 'YouTube Subscribers',
                  color: '#E74C3C',
              },
          }
      }
  },
{
  componentType: 'BarPlot',
  info: {
    artistId: this.props.artistId,
          loading:true,
      properties: {
          artistId: this.props.artistId,
      },
      chart_endpoint: 'artist_track_gender_breakdown',
      value_format: 'number',
      chart_xKeyName: "DATE",
      chart_title: "Followers",
      chart_xAxisTitle: 'Date',
      chart_yAxisTitle: 'Values',
      chart_pct: true,
      hideLegend: false,
      
      title: "Gender Breakdown",
      chart_background_color: "#F0F0F0",
      xKeyTitles:"TITLE",
      yKeyName: "Streams",
      yKeyTitle: 'Streams',
      percent: 'percent',
      xKeys: [
        {xKeyName: 'STREAM_COUNT_M', xKeyTitle:'Male Streams Percentage'},
        {xKeyName: 'STREAM_COUNT_F', xKeyTitle:'Female Streams Percentage'},

      ]
  }
},
{
  componentType: 'BarPlot',
  info: {
    artistId: this.props.artistId,
          loading:true,
      properties: {
          artistId: this.props.artistId,
      },
      chart_endpoint: 'artist_track_age_breakdown',
      value_format: 'number',
      chart_xKeyName: "DATE",
      chart_title: "Followers",
      chart_xAxisTitle: 'Date',
      chart_yAxisTitle: 'Values',
      chart_pct: true,
      hideLegend: false,
      percent: 'percent',
      title: "Age Breakdown",
      chart_background_color: "#F0F0F0",
      xKeyTitles:"TITLE",
      yKeyName: "Streams",
      yKeyTitle: 'Streams',
      xKeys: [
        {xKeyName: 'STREAM_COUNT_60', xKeyTitle:'60+'},
        {xKeyName: 'STREAM_COUNT_45', xKeyTitle:'45-54'},
        {xKeyName: 'STREAM_COUNT_35', xKeyTitle:'35-44'},
        {xKeyName: 'STREAM_COUNT_28', xKeyTitle:'28-34'},
        {xKeyName: 'STREAM_COUNT_23', xKeyTitle:'23-27'},
        {xKeyName: 'STREAM_COUNT_18', xKeyTitle:'18-22'},
        {xKeyName: 'STREAM_COUNT_0', xKeyTitle:'0-17'},
        

      ]
  }
},
{
  componentType: 'ScatterPlot',
  info: {
      // artistId: artistId,
      properties: {
        artistId: this.props.artistId,
      },
      artistId: this.props.artistId,
      chart_endpoint: 'artist_cohort',
      value_format: 'number',
      chart_xKeyName: "MARKET_SHARE_AUDIO_STREAMS",
      chart_yKeyName: "SPL_CURRENT_VALUE",
      chart_dotTitle: "NAME",
      dot_size: 'MARKET_SHARE_AUDIO_STREAMS',
      color_shade: 'IG_FOLLOWER_PCT_CHANGE',
      chart_title: "Cohort",
      hideLegend: false,
  }
},
{
  componentType: 'PieChart',
  info: {
      // artistId: artistId,
      properties: {
        artistId: this.props.artistId,
      },
      artistId: this.props.artistId,
      chart_endpoint: 'artist_region_breakdown',
      value_format: 'number',
      // chart_xKeyName: "MARKET_SHARE_AUDIO_STREAMS",
      // chart_yKeyName: "SPL_CURRENT_VALUE",
      // chart_dotTitle: "NAME",
      // dot_size: 'MARKET_SHARE_AUDIO_STREAMS',
      // color_shade: 'IG_FOLLOWER_PCT_CHANGE',
      chart_title: "Regional Streaming",
      hideLegend: false,
      chart_background_color: "#F0F0F0",
  }
},

  {
    componentType: 'Songs',
    info: {
      artistId: this.props.artistId,
      data:[],
      loading:true,
      table_dir: 'streams_table',
            columns: [
                { key: 'ARTIST', displayName: 'Artist', type: 'category', format: 'category', fix: true, optionsMatchingKey: 'SODATONE_ARTIST_ID', table: 'STREAMS_TABLE' },
                { key: 'WARNER_ARTIST_ID', displayName: 'Warner Artist ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'SODATONE_ARTIST_ID', displayName: 'Sodatone Artist ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'TITLE', displayName: 'Title', type: 'string', id: 'FAMILY_ID', track: true, fix: true },
                { key: 'FAMILY_ID', displayName: 'Family ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'UNIFIED_SONG_ID', displayName: 'Unified Song ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'SPOTIFY_ID', displayName: 'Spotify ID', type: 'string', fix: true, hide: true, alwaysHide: true },
                {key: 'FOLDER_ID', type:'folder', hide:true, alwaysHide: true, fixedValue:true, value:-1 },
                {key: 'MRC_TOGGLED', type:'folder', hide:true,alwaysHide: true, fixedValue:true, value:true },
                { key: 'WEEK_0', displayName: 'Estimated Building Week Streams', type: 'number', format: 'number' },
                { key: 'BUILDING_WEEK_STREAM_COUNT', displayName: 'Building Week Streams', type: 'number', format: 'number' },
                { key: 'WEEK_0_PCT_CHANGE', displayName: 'Percent Change Building Week', type: 'number', format: 'percentChange' },

                { key: 'WEEK_1', displayName: 'Last Week Streams', type: 'number', format: 'number' },
                { key: 'WEEK_1_PCT_CHANGE', displayName: 'Percent Change Last Week', type: 'number', format: 'percentChange' },
                // { key: 'WEEK_2', displayName: '2 Week Ago Streams', type: 'number',format :'number'},
                // { key: 'WEEK_2_PCT_CHANGE', displayName: 'Percent Change 2 Weeks Ago', type: 'number',format :'percentChange' },
                // { key: 'WEEK_3', displayName: '3 Week Ago Streams', type: 'number',format :'number'},
                // { key: 'WEEK_3_PCT_CHANGE', displayName: 'Percent Change 3 Weeks Ago', type: 'number', format :'percentChange'},


                { key: 'BUILDING_WEEK_POST_COUNT', displayName: 'Building Week TikTok Creates', type: 'number', format: 'number' },
                { key: 'PCT_CHANGE_POST_COUNT', displayName: 'Percent Change Building Week TikTok Creates', type: 'number', format: 'percentChange' },




                { key: 'BW_SPOTIFY_SKIP_RATE', displayName: 'BW Spotify Skip Rate', type: 'number', format: 'number' },
                { key: 'BW_SPOTIFY_PASSION_SCORE', displayName: 'BW Spotify Passion Score', type: 'number', format: 'number' },
                { key: 'BW_PCT_US_ORGANIC_STREAMS', displayName: 'BW Percentage US Organic Streams', type: 'number', format: 'percentChange' },

                { key: 'LW_SPOTIFY_PASSION_SCORE', displayName: 'LW Passion Score', type: 'number', format: 'number' },
                { key: 'LW_SPOTIFY_SKIP_RATE', displayName: 'LW Skip Rate', type: 'number', format: 'percentChange' },

                { key: 'LW_US_ORGANIC_STREAMS', displayName: 'LW US Organic Streams', type: 'number', format: 'number' },
                { key: 'LW_PCT_US_ORGANIC_STREAMS', displayName: 'LW Percentage US Organic Streams', type: 'number', format: 'percentChange' },


                { key: 'FIRST_FRIDAY_STREAM_COUNT', displayName: 'First Friday Streams', type: 'number', format: 'number' },
                { key: 'FIRST_FRIDAY_ORGANIC_STREAMS', displayName: 'First Friday Organic Streams', type: 'number', format: 'number' },
                { key: 'FIRST_FRIDAY_PCT_ORGANIC_STREAMS', displayName: 'First Friday % Organic', type: 'number', format: 'number' },
                { key: 'FIRST_FRIDAY_PASSION_SCORE', displayName: 'First Friday Passion Score', type: 'number', format: 'number' },
                { key: 'FIRST_FRIDAY_SKIP_RATE', displayName: 'First Friday Skip Rate', type: 'number', format: 'number' },

                { key: 'THREE_DAY_STREAM_COUNT', displayName: 'Three Day Streams', type: 'number', format: 'number' },
                { key: 'THREE_DAY_ORGANIC_STREAMS', displayName: 'Three Day Organic Streams', type: 'number', format: 'number' },
                { key: 'THREE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Three Day % Organic', type: 'number', format: 'percentChange' },
                { key: 'THREE_DAY_PASSION_SCORE', displayName: 'Three Day Passion Score', type: 'number', format: 'number' },
                { key: 'THREE_DAY_SKIP_RATE', displayName: 'Three Day Skip Rate', type: 'number', format: 'number' },

                { key: 'FIVE_DAY_STREAM_COUNT', displayName: 'Five Day Streams', type: 'number', format: 'number' },
                { key: 'FIVE_DAY_ORGANIC_STREAMS', displayName: 'Five Day Organic Streams', type: 'number', format: 'number' },
                { key: 'FIVE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Five Day % Organic', type: 'number', format: 'percentChange' },
                { key: 'FIVE_DAY_PASSION_SCORE', displayName: 'Five Day Passion Score', type: 'number', format: 'number' },
                { key: 'FIVE_DAY_SKIP_RATE', displayName: 'Five Day Skip Rate', type: 'number', format: 'number' },


                { key: 'FIRST_WEEK_STREAM_COUNT', displayName: 'First Week Streams', type: 'number', format: 'number' },
                { key: 'FIRST_WEEK_ORGANIC_STREAMS', displayName: 'First Week Organic Streams', type: 'number', format: 'number' },
                { key: 'FIRST_WEEK_PCT_ORGANIC_STREAMS', displayName: 'First Week % Organic', type: 'number', format: 'percentChange' },
                { key: 'FIRST_WEEK_PASSION_SCORE', displayName: 'First Week Passion Score', type: 'number', format: 'number' },
                { key: 'FIRST_WEEK_SKIP_RATE', displayName: 'First Week Skip Rate', type: 'number', format: 'percentChange' },




                { key: 'YTD', displayName: 'YTD', type: 'number', format: 'number' },
                { key: 'ATD', displayName: 'ATD', type: 'number', format: 'number' },
                { key: 'RELEASE_DATE', displayName: 'Release Date', type: 'date' }

            ],
            filters: [{
                column: 'MRC_TOGGLED',
                operator: 'TOGGLE',
                value: true
            },
            {
                column: 'FOLDER_ID',
                operator: 'FOLDER',
                value: -1
            },
            {"column":"ARTIST","operator":"in","value":[this.props.artistId],"optionsMatchingKey":"SODATONE_ARTIST_ID"}
            ],
            sort: {key: 'WEEK_0', type:'number',order: 'desc'},
            selectedFolder: -1,
            data:[],
            folders:[],
            searchValue: '',
    }
},

            

        
        
        
        

    ]
    };
  }

  componentDidMount() {
    // Access the artistId from props
    const { artistId } = this.props;
    console.log(artistId)
    // Simulate fetching the artist name and albums (replace with your API calls)
    Promise.all([this.fetchArtistName(artistId)])
      .then(([name]) => {
        this.setState({ artistName: name, isLoading: false }); // Update states
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ isLoading: false }); // Update loading state in case of an error
      });
  }
  componentDidUpdate(prevProps) {
    // Check if the artistId prop has changed
    console.log(this.props.artistId)
    if (this.props.artistId !== prevProps.artistId) {
      // Reset the component state to show loading while fetching new data
      this.setState({ isLoading: true });

      // Fetch new data based on the updated artistId
      Promise.all([this.fetchArtistName(this.props.artistId), this.fetchAlbums(this.props.artistId)])
        .then(([name, albums]) => {
          this.setState({ artistName: name, albums, isLoading: false }); // Update states
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          this.setState({ isLoading: false }); // Update loading state in case of an error
        });
    }
  }

  // Define the fetchArtistName function
  fetchArtistName(artistId) {
    const queryParams = new URLSearchParams();
    queryParams.append('artistId', artistId);
    const queryString = queryParams.toString();
    const { authState } = this.props.authState;
    console.log(this.props)
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };

    const url = `${endpoint}/artist_name?${queryString}`;

    // Simulating data fetching
    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: headers // Add the headers to the fetch options
      })
        .then((response) => response.json())
        .then((data) => {
          // Assuming the API returns the artist name as 'name'
          resolve(data.NAME);
        })
        .catch((error) => {
          console.error('Error fetching artist name:', error);
          reject(error);
        });
    });
  }
  setData = (data) => {
    this.setState({data: data });
  };

  
  render() {
    const { artistId } = this.props;
    const { artistName, albums,data } = this.state;
    console.log(albums)
    const headerHeight = document.querySelector('.MuiAppBar-root')
      ? document.querySelector('.MuiAppBar-root').clientHeight
      : 0;
    const containerHeight = window.innerHeight - headerHeight;
    return (
      <div 
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: `${containerHeight}px`, // Set the calculated height
          overflowY: 'scroll',
        }}>
        <div  style={{
          position: 'absolute', // Position 'box-2' absolutely within 'main-container'
          top: 0, // Adjust this if you want to set a specific top position
          left: 0, // Adjust this if you want to set a specific left position
          zIndex: 1, // Set the z-index to ensure 'box-2' appears on top
          width: '100%', // Make 'box-2' span the full width
          textAlign: 'center', // Center text horizontally
          alignItems: 'center',

        }}>
          <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between', // Distribute space between children
      alignItems: 'center', // Center items vertically
      width: '100%', // Make 'box-2' span the full width
    }}
  >
    <h1
      className={artistName ? '' : 'pulse'}
      style={{
        margin: '0', // Remove margin from the bottom
        textAlign: 'center', // Center text horizontally
        flex: '1', // Allow the h1 to take available space
      }}
    >
      {artistName || '\u00A0'}
    </h1>
    </div>
    <div style={{ marginLeft: 'auto', position:'absolute',top:0,right:0 }}>
      <ExportArtist data={data} artistName={artistName} />
    </div>


          <DataBoxes artistId={artistId} setData={this.setData} data={data} />
        </div>
      </div>
    );
  }
}

// Wrap your class component with a function that provides route parameters
function ArtistPageWrapper(authState) {
  const { artistId } = useParams();
  console.log(artistId)
  console.log(artistId)
  console.log()
  // const navigate = useNavigate();
  // const { authState } = this.props;

  return <ArtistPage key={artistId} artistId={artistId} authState={authState} />;
}

export default withOktaAuth(ArtistPageWrapper);
