import React from 'react';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, InputBase, Typography, makeStyles, fade } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SearchBar from './SearchBar'; // Replace with the correct path to your SearchBar component
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NavLink = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to}>
      <Button color={isActive ? 'primary' : 'inherit'}>{children}</Button>
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
  },
  appBar: {
    backgroundColor: 'primary', // Set your desired background color
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logoImage: {
    width: 'auto',
    height: '48px',
    cursor: 'pointer',
    marginRight: theme.spacing(2), // Add spacing between logo and menu icon
  },
  smallLogo: {
    height: '32px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2), // Add spacing between menu icon and search bar
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  contentContainer: {
    marginTop: '64px',
    // overflowY: 'auto', // Enable vertical scrolling for the content
    height: 'calc(100vh - 64px)', // Calculate the height to fill the viewport
  },
  fixedOuterShell: {
    overflow: 'hidden', // Hide overflow on the outer shell
    height: '100vh', // Make the outer shell fill the viewport
  },
  fixedAppBar: {
    backgroundColor: 'primary',
    position: 'fixed', // Set the position to 'fixed'
    top: 0, // Stick it to the top of the viewport
    width: '100%', // Make it full-width
    zIndex: 1000, // Ensure it appears above other content
  },
}));

const OuterShell = ({ children }) => {
  const navigate = useNavigate(); // This hook provides access to the navigate function

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.fixedOuterShell}> {/* Apply the fixedOuterShell class */}
      <AppBar position="static" className={classes.fixedAppBar}>
        <Toolbar>
          <div className={classes.logoAndMenu}>
            <img
              src="/echo-high-resolution-logo-white-on-transparent-background.png"
              alt="Echo"
              className={`${classes.logoImage} ${classes.smallLogo}`}
              onClick={handleMenuOpen}
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              elevation={1}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom', // Adjust the vertical position as needed
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top', // Adjust the vertical position as needed
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  width: '220px', // Adjust the width as needed
                },
              }}
            >
              <MenuItem onClick={handleMenuClose}><NavLink to="/roster">Roster</NavLink></MenuItem>
              <MenuItem onClick={handleMenuClose}><NavLink to="/genre">Genre</NavLink></MenuItem>
              <MenuItem onClick={handleMenuClose}><NavLink to="/label">Label</NavLink></MenuItem>
              <MenuItem onClick={handleMenuClose}><NavLink to="/songs">All Songs</NavLink></MenuItem>
              <MenuItem onClick={handleMenuClose}><NavLink to="/artists">Artists</NavLink></MenuItem>

              {/* <MenuItem onClick={handleMenuClose}><NavLink to="/test">Test</NavLink></MenuItem> */}
            </Menu>
          </div>
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <SearchBar navigate={navigate} />
        </Toolbar>
      </AppBar>
      <div className={`${classes.contentContainer} content-container`}>{children}</div>
    </div>
  );
};

export default OuterShell;
