


// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter, Route, Routes, Route as NavigateRoute } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';

import OuterShell from './OuterShell'; // Import your outer shell component
import App from './App';
// import App_Genre from './App_Genre';
// import App_Label from './App_Label';
// import EmailComponent from './emailComponentText'; // Assuming EmailComponent is your email preview component
// import ArtistPage from './ArtistPage';
// import TestLayoutComponent from './TestLayoutComponent'
// import App1 from './app1.js'
// import { Security, SecureRoute, ImplicitCallback } from '@okta/okta-react';



// ReactDOM.render(
//   <BrowserRouter>
//     <Routes>
//     <NavigateRoute path="/" element={<OuterShell><App /></OuterShell>} />
//       <NavigateRoute path="/roster" element={<OuterShell><App /></OuterShell>} />
//       <NavigateRoute path="/genre" element={<OuterShell><App_Genre /></OuterShell>} />
//       <NavigateRoute path="/label" element={<OuterShell><App_Label /></OuterShell>} />
//       <NavigateRoute path="/email" element={<OuterShell><EmailComponent/></OuterShell>} />
//       <NavigateRoute path="/test" element={<OuterShell><App1/></OuterShell>} />
//       <NavigateRoute path="/artist/:artistId" element={<OuterShell><ArtistPage /></OuterShell>} />
//     </Routes>
//   </BrowserRouter>,
//   document.getElementById('root')
// );




// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Routes, Outlet, Link, Navigate } from 'react-router-dom';
// import { Security, withOktaAuth, LoginCallback } from '@okta/okta-react'; // Import LoginCallback
// import LoginPage from './LoginPage';
// import oktaAuth from './okta-client';

// const restoreOriginalUri = async (oktaAuth, originalUri) => {
//   if (originalUri) {
//     // Redirect to the original URI if it exists
//     window.location.replace(originalUri);
//   } else {
//     // If no original URI, redirect to the default route
//     oktaAuth.signInWithRedirect({ originalUri: '/' });
//   }
// };

// console.log('Initializing Okta...');

// oktaAuth.authStateManager.subscribe((authState) => {
//   console.log('Auth State Changed:', authState);
//   if (authState.isPending) {
//     console.log('Authentication is pending...');
//     ReactDOM.render(<div>Loading...</div>, document.getElementById('root'));
//   } else if (authState.isAuthenticated) {
//     console.log('User is authenticated.');
//     ReactDOM.render(
//       <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri }>
//         <App />
//       </Security>,
//       document.getElementById('root')
//     );
//   } else {
//     console.log('User is not authenticated.');
//     ReactDOM.render(<LoginPage  />, document.getElementById('root'));
//   }
// });

// if (!oktaAuth.isLoginRedirect()) {
//   // Trigger an initial authState change event when the app startup
//   oktaAuth.authStateManager.updateAuthState();
// }

// ReactDOM.render(
//   <Router>
//     <Security oktaAuth={oktaAuth} restoreOriginalUri = {restoreOriginalUri}>
//       <Routes>
//         {/* ... Other routes ... */}
//         {/* <Route path="/authorization-code/callback" element={<LoginCallback />} />  */}
//       </Routes>
//     </Security>
//   </Router>,
//   document.getElementById('root')
// );

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { Security, withOktaAuth, LoginCallback } from '@okta/okta-react';
// import LoginPage from './LoginPage';
// import oktaAuth from './okta-client';
// import Home from './Home'
// const restoreOriginalUri = async (oktaAuth, originalUri) => {
//   console.log('restoreOriginalUri called with originalUri:', originalUri);
//   if (originalUri) {
//     console.log('Redirecting to originalUri:', originalUri);
//     window.location.replace(originalUri);
//   } else {
//     console.log('No originalUri, redirecting to default route');
//     oktaAuth.signInWithRedirect();
//   }
// };

// console.log('Initializing Okta...');

// ReactDOM.render(
//   <Router>
//     <Security oktaAuth={oktaAuth} restoreOriginalUri = {restoreOriginalUri}>
//       <Routes>
//         <Route path="/" element={<App />} />
//         <Route path="/home" element={<Home/>} />
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/authorization-code/callback" element={<LoginCallback />} />
//       </Routes>
//     </Security>
//   </Router>,
//   document.getElementById('root')
// );

// index.js
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import Main1 from './Main1';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
      <Main1 />
    </BrowserRouter>
  </DndProvider>
);


// const App11 = withOktaAuth(({ authState }) => {
//   return (
//     <Router>
//       <Routes>
//         <Route path='/login' element={<LoginPage />} />
//         {authState && authState.isAuthenticated ? (
//           <Route path='/' element={<App />} />
//         ) : (
//           <Route path='/' element={<Navigate to="/login" />} />
//         )}
//       </Routes>
//     </Router>
//   );
// });

// ReactDOM.render(
//   <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
//     <App11 />
//   </Security>,
//   document.getElementById('root')
// );



// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
// import MainApp from './App_Wi.js'; // Import your main application component

// ReactDOM.render(
//   <Router>
//     <MainApp />
//   </Router>,
//   document.getElementById('root')
// );