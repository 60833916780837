import React, { Component } from 'react';
import {
  TextField, Checkbox, Table,
  TableHead, TableBody, TableRow, TableCell, IconButton,
  TablePagination, Button, Menu, MenuItem, Typography,
  FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';
import { Add, Folder } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ReactDOMServer from 'react-dom/server';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withOktaAuth } from '@okta/okta-react';
import Grid from '@material-ui/core/Grid'; // Import Grid
import CheckCircle from '@material-ui/icons/CheckCircle'; // Import CheckCircle icon
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'; // Import RadioButtonUnchecked icon
import List from '@material-ui/core/List'; // Import List
import ListItem from '@material-ui/core/ListItem'; // Import ListItem
import Radio from '@material-ui/core/Radio'; // Import Radio
import RadioGroup from '@material-ui/core/RadioGroup'; // Import RadioGroup
import ShareIcon from '@material-ui/icons/Share';


// import ExpandedRow from './ExpandedRow';


const ConfirmationDialog = ({ open, title, message, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(true)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      sortColumn: null,
      sortType: null,
      sortOrder: null,
      searchValue: '',
      filters: [],
      pageNumber: 1,
      // pageSize: 100,
      toggleValue: false,
      trackedIdMRC: null,
      thisRow: null,
      trackedIdSodatone: null,
      anchorEl: null,
      showGroupMenu: false,
      folderMenuAnchor: null,
      newFolderName: '', // Initialize with an empty string
      folders: [],
      selectedFolder: -1,
      isMenuOpen: false,
      newFolderName: '',
      deletingFolder: null,
      isDeleteConfirmationOpen: false,
      expandedRow: null,
      isEmailPreviewOpen: false,
      emailAddresses: '',
      emailHtml: '',
      isEmailSent: false,
      distinctCategories: {},
      buildingWeekOption: '',
      isShareDialogOpen: false,
      sharedEmail: '',
      sharedFolder: null,
      successDialogOpen: false,
      errorDialogOpen: false,
      errorMessage: ''
    };
    this.newFolderNameRef = React.createRef();
    this.folderSelectRef = React.createRef();

  }


  componentDidMount() {
    const { columns, default_sort_column_key, default_sort_order } = this.props;
    if (default_sort_column_key) {
      this.setState({ sortOrder: default_sort_order })
      const defaultSortColumn = columns.find(column => column.key === default_sort_column_key);
      console.log(defaultSortColumn)
      this.setState({ sortColumn: defaultSortColumn })
      this.handleSort(defaultSortColumn)
      Promise.all([this.fetchFolders()])
        .then(() => {
          console.log('Done loading data and folders');
        })
        .catch((error) => {
          console.error('Error loading data and folders:', error);
        });
    } else {
      Promise.all([this.fetchData(), this.fetchFolders()])
        .then(() => {
          console.log('Done loading data and folders');
        })
        .catch((error) => {
          console.error('Error loading data and folders:', error);
        });
    }


  }

  componentDidUpdate(prevProps, prevState) {
    const { sortColumn, searchValue, sortOrder } = this.state;
    console.log('Updating')
    // if (
    //   prevState.sortColumn !== sortColumn ||
    //   prevState.sortOrder !== sortOrder ||
    //   prevState.searchValue !== searchValue
    // ) {
    //   console.log('fetch')
    //   this.fetchData();
    // }
    console.log('doen')
  }
  // fetchDistinctCategories = async () => {
  //   const { endpoint, columns, ExpandedRowComp, id_column, song_table, pageSize } = this.props;

  //   const categoryColumns = columns
  //   .filter((column) => column.type === 'category')
  //   .map((column) => column.key);// Replace with your actual column names
  //   let distinctCategories = {};

  //   // Fetch distinct categories for each column

  //   const response = await fetch(`${endpoint}/streams_table/distinct_categories?columnNames=${categoryColumns.join(',')}`);
  //   const data = await response.json();
  //   distinctCategories = data;
  //   console.log(distinctCategories)
  //   return distinctCategories;
  // };

  fetchDistinctCategories = async () => {
    const { endpoint, columns, ExpandedRowComp, id_column, song_table, pageSize } = this.props;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const categoryColumns = columns
      .filter((column) => column.type === 'category')
      .map((column) => column.key); // Replace with your actual column names
    let distinctCategories = {};

    try {
      // Add authorization headers to the fetch request
      const response = await fetch(`${endpoint}/streams_table/distinct_categories?columnNames=${categoryColumns.join(',')}`, {
        method: 'GET', // Replace with the appropriate HTTP method
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Replace accessToken with your actual access token
          'Content-Type': 'application/json', // Add other headers as needed
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }

      const data = await response.json();
      distinctCategories = data;
      console.log(distinctCategories);
      return distinctCategories;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  };


  fetchData() {
    console.log('fetching data');
    const { endpoint, table_dir, filter_col, filter_value, pageSize } = this.props;
    const { filters, sortColumn, sortType, sortOrder, searchValue, pageNumber, toggleValue, selectedFolder, folders } = this.state;

    const queryParams = new URLSearchParams();
    if (filter_col) {
      // console.log(filter_col)
      // console.log(filter_value)
      let t_filter = {
        column: filter_col,
        operator: '=',
        value: filter_value
      };
      filters.push(t_filter);

    }

    if (filters) {
      queryParams.append('filters', JSON.stringify(filters));
    }

    if (sortColumn && sortOrder) {
      queryParams.append('sortColumn', sortColumn);
      queryParams.append('sortOrder', sortOrder);
      queryParams.append('sortType', sortType);
    }

    if (searchValue) {
      queryParams.append('searchValue', searchValue);
    }

    if (toggleValue) {
      queryParams.append('toggleValue', toggleValue);
    }

    queryParams.append('pageNumber', pageNumber);
    queryParams.append('pageSize', pageSize);

    queryParams.append('folderId', selectedFolder);
    // console.log(selectedFolder)
    const queryString = queryParams.toString();
    const url = `${endpoint}/${table_dir}?${queryString}`;
    // this.fetchDistinctCategories()
    // .then((distinctCategories) => {
    //   console.log(distinctCategories)
    //   this.setState({ distinctCategories });
    // })
    // .catch((error) => {
    //   console.error('Error fetching distinct categories:', error);
    // });

    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };

    // Simulating data fetching
    return new Promise((resolve, reject) => {

      fetch(url,
        {
          headers: headers // Add the headers to the fetch options
        })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ data, loading: false });
          console.log('Done loading data');
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });
  }

  fetchDataForExport = () => {
    console.log('fetching data');
    const { endpoint, table_dir, filter_col, filter_value, pageSize } = this.props;
    const { filters, sortColumn, sortType, sortOrder, searchValue, pageNumber, toggleValue, selectedFolder, folders } = this.state;

    const queryParams = new URLSearchParams();


    if (filters) {
      queryParams.append('filters', JSON.stringify(filters));
    }

    if (sortColumn && sortOrder) {
      queryParams.append('sortColumn', sortColumn);
      queryParams.append('sortOrder', sortOrder);
      queryParams.append('sortType', sortType);
    }

    if (searchValue) {
      queryParams.append('searchValue', searchValue);
    }

    if (toggleValue) {
      queryParams.append('toggleValue', toggleValue);
    }

    queryParams.append('pageNumber', pageNumber);
    queryParams.append('pageSize', pageSize);

    queryParams.append('folderId', selectedFolder);
    // console.log(selectedFolder)
    const queryString = queryParams.toString();
    const url = `${endpoint}/${table_dir}?${queryString}`;

    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };

    // Simulating data fetching
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          headers: headers // Add the headers to the fetch options
        })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  fetchFolders() {
    const { endpoint } = this.props;
    console.log('fetching folders');
    const url = `${endpoint}/folders`;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };

    // Simulating data fetching
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          headers: headers // Add the headers to the fetch options
        })
        .then((response) => response.json())
        .then((folders) => {
          this.setState({ folders, loadingFolders: false });
          console.log('Done loading folders');
        })
        .catch((error) => {
          console.error('Error fetching folders:', error);
        });
    });
  }


  handleSort = (column) => {
    this.setState(
      (prevState) => {
        const { sortColumn, sortOrder } = prevState;
        let newSortOrder = 'desc';

        if (sortColumn === column.key) {
          newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        }

        return {
          sortColumn: column.key,
          sortType: column.type,
          sortOrder: newSortOrder,
          pageNumber: 1
        };
      },
      this.fetchData
    );
  };

  handleSearchChange = (event) => {
    const searchValue = event.target.value;
    this.setState(
      {
        searchValue,
        pageNumber: 1
      },
      this.fetchData
    );
  };

  handleCategoryChange = (category, columnName) => {
    const { filters } = this.state;
    // Get the existing filter for this column
    const existingFilter = filters.find((filter) => filter.column === columnName);

    // Get the current list of selected categories based on the existing filter or an empty array if there's no filter
    const currentCategories = existingFilter ? existingFilter.value : [];

    // Toggle the selected category in the current list
    const updatedCategories = currentCategories.includes(category)
      ? currentCategories.filter((c) => c !== category)
      : [...currentCategories, category];

    // Create a new filter object based on the selected categories
    const filter = {
      column: columnName, // Replace with the appropriate column name
      operator: 'in', // Use 'in' to filter by categories
      value: updatedCategories,
    };

    // Update the categories state
    // setCategories(updatedCategories);

    // Update the filters state with the new category filter
    const updatedFilters = filters.filter((filter) => filter.column !== columnName); // Remove any existing filters for this column
    updatedFilters.push(filter);
    this.setState(
      {
        filters: updatedFilters,
        pageNumber: 1
      },
      this.fetchData
    );
    // Update the filters state with the new category filter
    // setFilters(updatedFilters);
  };



  handleFilterChange = (event, columnName, columnType, operator) => {
    const { filters } = this.state;
    const filterValue = event.target.value;
    let existingFilter = filters.find((filter) => filter.column === columnName);
    const updatedFilters = filters.filter((filter) => filter.column !== columnName);

    if (filterValue !== '') {
      let filter = {
        column: columnName,
        operator: '=',
        value: filterValue
      };

      if (columnType === 'number') {
        filter.operator = operator;
        if (existingFilter) {
          if ((operator === '>') && (existingFilter.operator === '<')) {
            updatedFilters.push(existingFilter)
          } else if ((operator === '<') && (existingFilter.operator === '>')) {
            updatedFilters.push(existingFilter)
          }
        }

      } else if (columnType === 'date') {
        if (operator === 'past week') {
          const inputFields = document.querySelectorAll(`input[type="date"]`);
          inputFields.forEach((input) => {
            input.value = '';
            // console.log(input)
          });
          const pastWeek = new Date();
          pastWeek.setDate(pastWeek.getDate() - 8);
          const today = new Date();
          filter.operator = '>';
          filter.value = pastWeek.toISOString().split('T')[0];
          updatedFilters.push(filter);

          filter = {
            column: columnName,
            operator: '<=',
            value: today.toISOString().split('T')[0]
          };
        } else if (operator === 'past month') {
          const inputFields = document.querySelectorAll(`input[type="date"]`);
          inputFields.forEach((input) => {
            input.value = '';
            // console.log(input)
          });
          const pastMonth = new Date();
          pastMonth.setMonth(pastMonth.getMonth() - 1);
          const today = new Date();
          filter.operator = '>';
          filter.value = pastMonth.toISOString().split('T')[0];
          updatedFilters.push(filter);

          filter = {
            column: columnName,
            operator: '<=',
            value: today.toISOString().split('T')[0]
          };
        } else {
          filter.operator = operator;
          if (existingFilter) {
            if ((operator === '>') && (existingFilter.operator === '<')) {
              updatedFilters.push(existingFilter)
            } else if ((operator === '<') && (existingFilter.operator === '>')) {
              updatedFilters.push(existingFilter)
            }
          }
        }
      }

      updatedFilters.push(filter);
    }

    this.setState(
      {
        filters: updatedFilters,
        pageNumber: 1
      },
      this.fetchData
    );
  };


  handleToggleChange = (event) => {
    const toggleValue = event.target.checked;
    this.setState(
      {
        toggleValue,
        pageNumber: 1
      },
      this.fetchData
    );
  };

  formatValue = (value, type, format) => {
    if (value === null) {
      return '';
    }

    if (type === 'number') {
      if (format === 'literal') {
        return value || ''; // If value is undefined, return an empty string
      } else if (format === 'percentChange') {
        const roundedValue = Math.round(value * 10) / 10;
        return (roundedValue || 0).toFixed(1) + '%'; // If value is undefined, return '0%'
      } else if (format === 'number') {
        const absValue = Math.abs(value);
        if (absValue >= 1e9) {
          return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
        } else if (absValue >= 1e6) {
          return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
        } else if (absValue >= 1e3) {
          return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
        } else {
          return value || ''; // If value is undefined, return an empty string
        }
      }
    } else if (type === 'date') {
      const formattedDate = new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return formattedDate;
    }
    return value;
  };


  previousPage = () => {
    this.setState(
      (prevState) => ({
        pageNumber: prevState.pageNumber - 1
      }),
      this.fetchData
    );
  };

  nextPage = () => {
    this.setState(
      (prevState) => ({
        pageNumber: prevState.pageNumber + 1
      }),
      this.fetchData
    );
  };




  generateCSVContent = (data) => {
    const { columns } = this.props;
    const headerRow = columns.map((column) => column.displayName);

    const formattedData = data.map((row) =>
      columns.map((column) => {
        const formattedValue = this.formatValue(row[column.key], column.type, column.format);
        if (column.type === 'number' || column.type === 'percentChange') {
          return row[column.key]
        }
        return (column.type === 'string' || column.type === 'date') ? `"${formattedValue.replace(/"/g, '""')}"` : formattedValue;

      })
    );

    const csvRows = [headerRow, ...formattedData];
    const csvContent = csvRows.map((row) => row.join(',')).join('\n');
    return csvContent;
  };


  exportDataToCSV = () => {
    // Perform the data export request with the updated filters
    this.fetchDataForExport()
      .then((data) => {
        // Generate the CSV content
        const csvContent = this.generateCSVContent(data);

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
        downloadLink.setAttribute('download', 'data.csv');
        downloadLink.click();

        // Clean up the temporary anchor element
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        console.error('Failed to export data:', error);
      });
  };


  handleTrackClick = (event, familyId, unifiedSongId) => {
    console.log(familyId);
    console.log(unifiedSongId);
    const { currentTarget } = event;

    this.setState(
      (prevState) => ({
        anchorEl: prevState.anchorEl === currentTarget ? null : currentTarget,
        showGroupMenu: false,
      }),
      () => {
        // Fetch data after the selectedFolder state is updated
        this.fetchData();
      }
    );
  };

  handleFolderClick = (event, row, familyId, unifiedSongId) => {
    // console.log(familyId)
    // console.log(event.currentTarget)
    console.log(event.currentTarget)
    this.setState({
      folderMenuAnchor: event.currentTarget,
      thisRow: row,
      // trackedIdMRC: familyId,
      // trackedIdSodatone: unifiedSongId
    });

    console.log('folder click done')
  };


  handleFolderMenuItemClick = (folderId, row, familyId, unifiedSongId, spotifyId, folderName) => {
    console.log('hahah')
    const { endpoint } = this.props;
    // thisRow?.folderIds?.includes(folder.FOLDER_ID);
    console.log(row?.folderIds?.includes(folderId))
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    if (folderId === 'newFolder') {
      // Handle the logic to create a new folder with the given folderName
      console.log('Create new folder:', folderName);

      // Perform the API call to add the new folder to the database
      const requestData = {
        user_id: 1, // Replace with the actual user ID value
        folder_name: folderName,
      };

      console.log(folderName);

      fetch(`${endpoint}/add_folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestData), // Send the requestData object as the request body
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('New folder added:', result);
          this.fetchFolders()
          // Handle any necessary state updates or UI changes
        })
        .catch((error) => {
          console.error('Error adding new folder:', error);
          // Handle the error case
        });



    } else {
      // Handle the logic to add the trackedIdMRC to the selected folder
      console.log('Add trackedIdMRC to folder:', folderId);
      console.log('Family ID:', familyId);
      console.log('Unified Song ID:', unifiedSongId);

      if (row?.folderIds?.includes(folderId)) {
        let user_id = 1;
        fetch(`${endpoint}/delete_from_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family deleted from folder:', result);
            // Handle any necessary state updates or UI changes
            this.fetchData()
          })
          .catch((error) => {
            console.error('Error deleting family from folder:', error);
            // Handle the error case
          });

      } else {
        let user_id = 1;
        fetch(`${endpoint}/add_to_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family added to folder:', result);
            this.fetchData()
            // Handle any necessary state updates or UI changes
          })
          .catch((error) => {
            console.error('Error adding family to folder:', error);
            // Handle the error case
          });
      }

    }

    // Close the folder menu
    this.setState({
      folderMenuAnchor: null,
    });

  };



  handleGroupSelect = (groupId) => {
    console.log(`Selected Group ID: ${groupId}`);
    this.setState({ anchorEl: null, showGroupMenu: false });
  };

  handleClickAway = () => {
    this.setState({ anchorEl: null, showGroupMenu: false });
  };

  handleFolderSelect = (event) => {
    const selectedFolder = event.target.value;
    this.setState(
      (prevState) => ({
        selectedFolder,
        isMenuOpen: prevState.isMenuOpen && selectedFolder === -1,
      }),
      () => {
        // Fetch data after the selectedFolder state is updated
        this.fetchData();
      }
    );
  };
  handleNewFolderChange = (event) => {
    this.setState({ newFolderName: event.target.value });
  };

  handleAddFolder = () => {
    const { newFolderName } = this.state;
    // Perform logic to add the new folder
    // ...
    this.setState({ newFolderName: '' }); // Reset the newFolderName value after adding the folder
  };
  handleDeleteFolder = (event, folderId) => {
    // Perform the necessary logic to delete the folder with the given folderId
    console.log('Delete folder:', folderId);
  };
  handleDeleteConfirmation = (confirm) => {
    const { endpoint } = this.props;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    if (confirm) {
      const { deletingFolder } = this.state;
      const folderId = deletingFolder.FOLDER_ID;

      // Call the delete folder endpoint
      fetch(`${endpoint}/delete_folder?folderId=${folderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,

        },
        body: JSON.stringify({ folderId }),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('Folder deleted:', result.message);
          // Handle any necessary state updates or UI changes after successful deletion
        })
        .catch((error) => {
          console.error('Error deleting folder:', error);
          // Handle the error case
        });
      this.fetchFolders()
    }

    // Reset the confirmation dialog state
    this.setState({
      deletingFolder: null,
      isDeleteConfirmationOpen: false,
    });
  };

  handleExpandRow = (rowId) => {
    console.log(this.expandedRow)
    console.log(rowId)
    // if (rowId.FAMILY_ID !== null) {
    //   this.setState((prevState) => ({
    //     expandedRow: prevState.expandedRow === rowId ? null : rowId,
    //   }));
    // }
    this.setState((prevState) => ({
      expandedRow: prevState.expandedRow === rowId ? null : rowId,
    }));

  };
  generateEmailHtml() {
    const { data, selectedFolder, folders, buildingWeekOption } = this.state;
    console.log(selectedFolder)
    console.log(data)
    console.log(folders)
    let folder_name = ''

    try {
      folder_name = folders.find(item => item.FOLDER_ID === selectedFolder)['FOLDER_NAME'];
      console.log(folder_name)
    } catch {
      folder_name = ''
    }

    // Generate the email HTML using React components
    const emailHtml = ReactDOMServer.renderToStaticMarkup(
      <html>
        <head>
          <meta charset="UTF-8" />
          <title>Email Template</title>
          <style>
            {`@media (max-width: 700px) {
            .box {
              width: 100% !important;
              display: block !important;
            }
          }`}
          </style>
        </head>
        <body>
          <div>
            <table
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              style={{ maxWidth: '800px', margin: '0 auto' }}
            >
              <tr>
                <td>
                  <h1>{folder_name}</h1>
                </td>
              </tr>
              {data.slice(0, 30).map((item, index) => (
                <div>
                  <tr style={{}}>
                    <td align="center" style={{
                      margin: '2px',
                      borderBottom: '10px',
                      backgroundColor: 'rgba(240, 240, 240, 0.25)', // Adjust the alpha value as needed
                      padding: '10px',

                    }}>
                      <h3 style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold', marginTop: '2px', marginBottom: '4px' }}>
                        {item.ARTIST} - {item.TITLE}
                      </h3>
                      <h4 style={{ textAlign: 'left', color: '#777', fontSize: '14px', margin: '4px' }}>Released: {this.formatValue(item.RELEASE_DATE, 'date', 'date')}</h4>
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tr>
                          <td className="box" style={{ width: '45%' }}>
                            <div
                              style={{
                                borderRadius: '10px',
                                backgroundColor: '#f0f0f0',
                                margin: '5px',
                                padding: '5px',
                                height: '110px', // Adjust the height as needed
                              }}
                            >
                              <h4 style={{ textAlign: 'left', fontSize: '14px', margin: '0px' }}>Streaming </h4>
                              <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'space-between', margin: '4px' }}>
                                <div style={{ width: '60%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                  {buildingWeekOption === 'Estimated Building Week' && (
                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>
                                      Estimated BW: {this.formatValue(item.WEEK_0, 'number', 'number')} {this.getArrowSymbol(item.WEEK_0_PCT_CHANGE)} ({this.formatValue(item.WEEK_0_PCT_CHANGE, 'number', 'percentChange')})
                                    </div>
                                  )}
                                  {buildingWeekOption === 'Building Week' && (
                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Building Week: {this.formatValue(item.BUILDING_WEEK_STREAM_COUNT, 'number', 'number')} {this.getArrowSymbol(item.WEEK_0_PCT_CHANGE)} ({this.formatValue(item.WEEK_0_PCT_CHANGE, 'number', 'percentChange')})</div>
                                  )}
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>This Week: {this.formatValue(item.WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Last Week: {this.formatValue(item.WEEK_2, 'number', 'number')}{this.getArrowSymbol(item.WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.WEEK_2_PCT_CHANGE, 'number', 'percentChange')})</div>
                                </div>
                                <div style={{ textAlign: 'right', width: '40%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>YTD: {this.formatValue(item.YTD, 'number', 'number')}</div>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>ATD: {this.formatValue(item.ATD, 'number', 'number')}</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="box" style={{ width: '45%' }}>
                            <div
                              style={{
                                borderRadius: '10px',
                                backgroundColor: '#f0f0f0',
                                margin: '5px',
                                padding: '5px',
                                height: '110px', // Adjust the height as needed
                              }}
                            >
                              <h4 style={{ textAlign: 'left', fontSize: '14px', margin: '0px' }}>Equivalents </h4>
                              <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'space-between', margin: '4px' }}>
                                <div style={{ width: '60%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>This Week: {this.formatValue(item.SALES_WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.SALES_WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.SALES_WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Last Week: {this.formatValue(item.SALES_WEEK_2, 'number', 'number')}{this.getArrowSymbol(item.SALES_WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.SALES_WEEK_2_PCT_CHANGE, 'number', 'percentChange')})</div>
                                </div>
                                <div style={{ textAlign: 'right', width: '40%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>YTD: {this.formatValue(item.SALES_YTD, 'number', 'number')}</div>
                                  <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>ATD: {this.formatValue(item.SALES_ATD, 'number', 'number')}</div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>

                    </td>
                  </tr>
                </div>
              ))}

            </table>
          </div>
        </body>
      </html>
    );






    console.log(emailHtml)
    this.setState({ emailHtml });
  }
  getArrowSymbol(change) {
    return change > 0 ? <span style={{ color: 'green' }}>&#8599;</span> : change < 0 ? <span style={{ color: 'red' }}>&#8600;</span> : '';
  }

  sendEmail = async () => {
    const { emailHtml } = this.state;
    const { endpoint } = this.props;

    // Get the input value from the ref
    const emailAddresses = this.emailInput.value;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/send_sendgrid_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
        },
        body: JSON.stringify({
          emailHtml,
          emailAddresses,
        }),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        this.setState({ isEmailPreviewOpen: false }); // Close the email preview popup
        // No need to reset the emailAddresses state here
        this.setState({ isEmailSent: true }); // Set the flag to show the "Email Sent!" popup
      } else {
        console.error('Error sending email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  handleShareClick = (folder) => {

    this.setState({
      sharedFolder: folder,
      isShareDialogOpen: true,
    });
  };

  handleCloseShareDialog = () => {
    this.setState({
      isShareDialogOpen: false,
      sharedEmail: '', // Clear the shared email input
      sharedFoldre: null
    });
  };

  handleShare = () => {
    // const { sharedEmail, selectedFolder } = this.state;
    const { endpoint } = this.props;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;

    const { sharedFolder, } = this.state;
    const folderId = sharedFolder.FOLDER_ID;
    const folderName = sharedFolder.FOLDER_NAME;
    const sharedEmail = this.sharedInput.value;


    // Call the delete folder endpoint
    fetch(`${endpoint}/share_folder?folderId=${folderId}&sharedEmail=${sharedEmail}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,

      },
      body: JSON.stringify({ folderId, folderName, sharedEmail }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.success) {
          console.log('Folder shared:', result.success);
          // Set successDialogOpen to true when sharing is successful
          this.setState({ successDialogOpen: true });
        } else {
          console.error('Error sharing folder:', result.error);
          // Set errorDialogOpen to true when an error occurs
          this.setState({
            errorDialogOpen: true,
            errorMessage: result.error
          });
        }

        // Handle any necessary state updates or UI changes after successful deletion
      })
      .catch((error) => {
        console.error('Error sharing folder:', error);
        // Set errorDialogOpen to true when an error occurs
        this.setState({
          errorDialogOpen: true,
          errorMessage: error
        });
        // Handle the error case
      });
    this.fetchFolders()

    // Reset the confirmation dialog state
    // Perform your sharing logic here with sharedEmail and selectedFolder
    // Close the dialog
    this.handleCloseShareDialog();
  };


  openOptionsDialog = () => {
    this.setState({ isOptionsDialogOpen: true });
  }

  closeOptionsDialog = () => {
    this.setState({ isOptionsDialogOpen: false });
  }

  selectOption = (option) => {
    this.setState({ buildingWeekOption: option });
  }

  // openEmailPreview = () => {
  //   this.generateEmailHtml(); // Generate the email HTML
  //   this.setState({ isEmailPreviewOpen: true });
  // };

  openEmailPreview = () => {
    this.generateEmailHtml();
    this.setState({
      isEmailPreviewOpen: true,
      isOptionsDialogOpen: false, // Close the options dialog when opening email preview
    });
  }

  closeEmailPreview = () => {
    this.setState({ isEmailPreviewOpen: false });
  };

  handleEmailAddressesChange = (event) => {
    this.setState({
      emailAddresses: event.target.value,
    });
  };
  getUniqueCategories(data, columnKey) {
    console.log(data)
    const uniqueCategories = new Set();
    data.forEach((row) => {
      uniqueCategories.add(row[columnKey]);
    });
    return Array.from(uniqueCategories);
  }

  render() {
    const { errorMessage, successDialogOpen, errorDialogOpen, isShareDialogOpen, sharedEmail, isOptionsDialogOpen, buildingWeekOption, distinctCategories, expandedRow, isEmailSent, filters, emailAddresses, emailHtml, isEmailPreviewOpen, data, loading, sortColumn, sortOrder, thisRow, searchValue, pageNumber, toggleValue, selectedFolder, folders, newFolderName,
      deletingFolder,
      isDeleteConfirmationOpen } = this.state;
    // console.log(expandedRow)
    const { endpoint, columns, ExpandedRowComp, id_column, song_table, pageSize } = this.props;
    // console.log(folders)
    // console.log(selectedFolder)

    if (loading) {
      return <div>Loading...</div>;
    }
    // const { trackedIdMRC, anchorEl, showGroupMenu } = this.state;
    const { trackedIdMRC, trackedIdSodatone, folderMenuAnchor, anchorEl, showGroupMenu } = this.state;
    let sortedData = data;
    // const expandedRowHeight = expandedRow ? 500 : 0; // Adjust the height as needed
    // console.log(distinctCategories)
    // console.log(distinctCategories['ARTIST'])
    // console.log(folderMenuAnchor)
    const visibleColumns = columns.filter((column) => !column.hide);
    // console.log(data.length)
    return (
      <div>
        <div style={{ position: 'sticky', top: '0', left: '0' }}>
          {(!song_table) && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <TextField
                  type="text"
                  placeholder="Filter..."
                  value={searchValue}
                  onChange={this.handleSearchChange}
                /></div></div>
          )}
          {(song_table) && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <TextField
                  type="text"
                  placeholder="Filter..."
                  value={searchValue}
                  onChange={this.handleSearchChange}
                />
                <span>MRC Data</span>
                <Checkbox
                  checked={toggleValue}
                  onChange={this.handleToggleChange}
                  color="primary"
                />
                <ConfirmationDialog
                  open={isDeleteConfirmationOpen}
                  title="Delete Folder"
                  message={`Are you sure you want to delete folder ${deletingFolder?.FOLDER_NAME}?`}
                  onConfirm={this.handleDeleteConfirmation}
                  onCancel={this.handleDeleteConfirmation}
                />

              </div>
              <div>
                <FormControl color="primary" style={{ height: '20px', marginLeft: '8px', marginTop: '5px' }}>
                  {/* <InputLabel htmlFor="folder-select">Select Folder</InputLabel> */}
                  <Select
                    ref={this.folderSelectRef}
                    // open={this.state.isMenuOpen}
                    labelId="demo-simple-select-label"
                    value={selectedFolder}
                    onChange={this.handleFolderSelect}
                    onClick={(event) => {
                      this.setState({ isMenuOpen: true });
                      event.stopPropagation();
                    }}
                    inputProps={{
                      name: 'folder',
                      id: 'folder-select',
                    }}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value="-1">All Folders</MenuItem>
                    {folders.map((folder) => (
                      <MenuItem key={folder.FOLDER_ID} value={folder.FOLDER_ID}>
                        {folder.FOLDER_NAME}
                        {selectedFolder !== folder.FOLDER_ID && folder.FOLDER_NAME !== "TRACKED" && (
                          <div>
                            <IconButton onClick={(event) => {
                              event.stopPropagation();

                              this.handleShareClick(folder)
                            }}>
                              <ShareIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                  deletingFolder: folder,
                                  isDeleteConfirmationOpen: true,
                                });
                              }}
                            >

                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}

                      </MenuItem>
                    ))}


                    <MenuItem>
                      <TextField
                        placeholder="New Folder"
                        inputRef={this.newFolderNameRef}
                        // onChange={(event) => this.setState({ newFolderName: event.target.value })}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => {
                                this.handleFolderMenuItemClick('newFolder', null, null, null, null, this.newFolderNameRef.current.value)

                              }}

                            >
                              <AddIcon />
                            </IconButton>
                          ),
                        }}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent selecting the menu item
                        }}
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button variant="outlined" color="primary" {...bindTrigger(popupState)} style={{ marginLeft: '8px', marginTop: '5px' }}>
                        Export
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}><Button onClick={this.exportDataToCSV} variant="outlined" color="primary">
                          CSV
                        </Button></MenuItem>
                        <MenuItem onClick={popupState.close}><Button onClick={this.openOptionsDialog} variant="outlined" color="primary">
                          Email
                        </Button></MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <Dialog open={isShareDialogOpen} onClose={this.handleCloseShareDialog}>
                  <DialogContent>
                    <TextField

                      // value={sharedEmail}
                      onChange={(e) => this.setState({ sharedEmail: e.target.value })}
                    />
                    <TextField
                      label="Insert Email"
                      placeholder="Insert email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.sharedInput = input)} // Create a ref for the input
                    />
                    <Button onClick={this.handleShare} color="primary">
                      Share
                    </Button>
                  </DialogContent>
                </Dialog>
              </div>
              <Dialog open={isOptionsDialogOpen} onClose={this.closeOptionsDialog}>
                <DialogTitle>Select an Option</DialogTitle>
                <DialogContent>
                  <p>Would you like to include building week streams?</p>

                  <List>
                    <ListItem>
                      <RadioGroup
                        aria-label="building-week-options"
                        name="buildingWeekOptions"
                        value={buildingWeekOption}
                        onChange={(e) => this.selectOption(e.target.value)}
                      >
                        <FormControlLabel
                          value="Building Week"
                          control={<Radio />}
                          label="Building Week Streams"
                        />
                        <FormControlLabel
                          value="Estimated Building Week"
                          control={<Radio />}
                          label="Estimated Building Week Streams"
                        />
                        <FormControlLabel
                          value="None"
                          control={<Radio />}
                          label="No Building Week Streams"
                        />
                      </RadioGroup>
                    </ListItem>
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.openEmailPreview}>Confirm</Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={isEmailPreviewOpen}
                onClose={this.closeEmailPreview}
                maxWidth="lg" // Set the maxWidth property to limit the width
              >
                <DialogTitle style={{ width: '80vw' }}>Email Preview</DialogTitle>
                <DialogContent>
                  <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                </DialogContent>
                <DialogActions>
                  <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Send To:
                    </Typography>
                    <TextField
                      label="Emails"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter email addresses, comma-separated"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailInput = input)} // Create a ref for the input
                    />
                  </div>
                  <Button onClick={this.sendEmail}>Send Email</Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={successDialogOpen}
                onClose={() => this.setState({ successDialogOpen: false })}
              >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                  <DialogContentText>The folder has been successfully shared.</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({ successDialogOpen: false })} color="primary">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={errorDialogOpen}
                onClose={() => this.setState({ errorDialogOpen: false })}
              >
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                  <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({ errorDialogOpen: false })} color="primary">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              {isEmailSent && (
                <Dialog open={true} onClose={() => this.setState({ isEmailSent: false })}>
                  <DialogTitle>Email Sent!</DialogTitle>
                </Dialog>
              )}

            </div>
          )}
        </div>
        <div style={{ position: 'relative', height: '90vh', overflow: 'auto' }}>
          {/* <div> */}
          <div style={{ position: 'sticky', top: '0', zIndex: 50, background: '#fff' }}>

            {/* <div > */}
            {/* THIS SHOULD BE HORIZONTALLY SCROLLABLE */}
            {/* <div style={{ position: 'sticky', top: '30px', zIndex: 2}}> */}
            <Table aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow >
                  {visibleColumns.map((column, index) => (
                    <TableCell
                      key={column.key}
                      onClick={() => this.handleSort(column)}
                      className={column.fix ? 'pinned-column' : ''}
                      style={{
                        fontWeight: 'bold',
                        position: column.fix ? 'sticky' : 'sticky',
                        left: column.fix ? `${index * 150}px` : 'auto',
                        padding: column.fix ? '0px' : 'auto',
                        zIndex: column.fix ? 10 : 9,
                        background: column.fix ? '#fff' : '#fff',
                        width: column.fix ? '150px' : '100px', // Set the width for pinned columns
                        gridColumn: `col-${column.key}`,
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ paddingRight: '12px' }}>{column.displayName}</div> {/* Add padding for the icon */}
                        <div style={{ position: 'absolute', right: '2px', marginRight: '8px', marginTop: '8px', bottom: '30px' }}>
                          {sortColumn === column.key && sortOrder === 'asc' && '▲'}
                          {sortColumn === column.key && sortOrder === 'desc' && '▼'}
                        </div>
                        {(column.format === 'number' || column.format === 'percentChange') && !column.no_filter && (
                          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <div onClick={(event) => {
                                console.log('Container click', event);
                                event.stopPropagation();
                                event.preventDefault();
                                // popupState.onClickAway(event);
                              }}
                            >
                              <React.Fragment>
                                <FilterListIcon
                                  fontSize="small"
                                  onClick={(event) => {
                                    event.stopPropagation(); // Stop propagation on icon click
                                    bindTrigger(popupState).onClick(event); // Manually invoke the onClick handler from bindTrigger
                                  }}
                                  onClickAway={(event) => {
                                    console.log('adaa')
                                    event.stopPropagation(); // Stop propagation on click away
                                    popupState.onClickAway(event); // Manually invoke the onClickAway handler from popupState
                                  }}

                                  style={{ marginLeft: '8px', paddingRight: '8px', color: '#888' }}
                                />
                                <Menu {...bindMenu({
                                  ...popupState,
                                  onClick: (event) => {
                                    event.stopPropagation(); // Stop propagation on Menu click
                                    popupState.onClick(event); // Manually invoke the onClick handler from popupState
                                  },
                                  onClickAway: (event) => {
                                    console.log("dass")
                                    event.stopPropagation(); // Stop propagation on click away
                                    popupState.onClickAway(event); // Manually invoke the onClickAway handler from popupState
                                  },
                                  onClose: (event) => {
                                    console.log("dadassdsass")
                                    event.stopPropagation(); // Stop propagation on click away
                                    popupState.onClickAway(event); // Manually invoke the onClickAway handler from popupState
                                  },
                                })}>
                                  <div style={{ fontSize: '12px' }}>
                                    <div>
                                      <TextField
                                        type="text"
                                        placeholder="Less Than"
                                        inputProps={{ operator: '<' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => this.handleFilterChange(event, column.key, column.type, '<')}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        type="text"
                                        placeholder="Greater Than"
                                        inputProps={{ operator: '>' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => this.handleFilterChange(event, column.key, column.type, '>')}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        type="text"
                                        placeholder="Equal To"
                                        inputProps={{ operator: '=' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => this.handleFilterChange(event, column.key, column.type, '=')}
                                      />
                                    </div>
                                  </div>
                                </Menu>
                              </React.Fragment>
                              </div>
                            )}
                          </PopupState>
                        )}
                        {column.type === 'date' && (
                          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <div onClick={(event) => {
                                console.log('Container click', event);
                                event.stopPropagation();
                                event.preventDefault();
                                // popupState.onClickAway(event);
                              }}
                            >
                              <React.Fragment>
                                <FilterListIcon
                                  fontSize="small"
                                  {...bindTrigger(popupState)}
                                  onClick={(event) => event.stopPropagation()} // Stop propagation on icon click
                                  style={{ marginLeft: '8px', paddingRight: '8px', color: '#888' }}
                                />
                                <Menu {...bindMenu(popupState)}>
                                  <div style={{ fontSize: '12px' }}>
                                    <div>
                                      <TextField
                                        type="date"
                                        placeholder="Less Than"
                                        inputProps={{ operator: '<' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => this.handleFilterChange(event, column.key, column.type, '<')}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        type="date"
                                        placeholder="Greater Than"
                                        inputProps={{ operator: '>' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => this.handleFilterChange(event, column.key, column.type, '>')}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        type="date"
                                        placeholder="Equal To"
                                        inputProps={{ operator: '=' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => this.handleFilterChange(event, column.key, column.type, '=')}
                                      />
                                    </div>
                                    <div>
                                      <Button
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          this.handleFilterChange(event, column.key, column.type, 'past week');
                                        }}
                                        variant="outlined"
                                        color="primary"
                                      >
                                        Past Week
                                      </Button>
                                    </div>
                                    <div>
                                      <Button
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          this.handleFilterChange(event, column.key, column.type, 'past month');
                                        }}
                                        variant="outlined"
                                        color="primary"
                                      >
                                        Past Month
                                      </Button>
                                    </div>
                                  </div>
                                </Menu>
                              </React.Fragment>
                              </div>
                            )}
                          </PopupState>
                        )}
                        {/* {column.type === 'category' && (
  <PopupState variant="popover" popupId="demo-popup-menu">
    {(popupState) => (
      <React.Fragment>
        <FilterListIcon
          fontSize="small"
          {...bindTrigger(popupState)}
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            marginRight: '8px',
            marginTop: '8px',
            color: '#888',
          }}
        />
        <Menu {...bindMenu(popupState)}>
          <div>
          {distinctCategories && distinctCategories[column.key] ? (
  distinctCategories[column.key].map((category) => (
    <FormControlLabel
      key={category}
      control={
        <Checkbox
        checked={filters[column.key] ? filters[column.key].includes(category) : distinctCategories[column.key]}
        onChange={() => this.handleCategoryChange(category, column.key)}
        />
      }
    />
  ))
) : (
  // Render some fallback content or a loading indicator if distinctCategories is not available
  <p>Loading categories...</p>
)}

            <Button variant="outlined" color="primary" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
)} */}

                      </div>
                    </TableCell>


                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </div>
          {/* </div> */}
          <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>

            <TableBody>
              {data.map((row) => (
                <React.Fragment key={row.id}>
                  {/* Main row */}
                  <TableRow onClick={() => this.handleExpandRow(row)}>
                    {visibleColumns.map((column, index) => (
                      <React.Fragment key={column.key}>
                        {!column.track && (
                          <TableCell
                            key={column.key}
                            className={column.fix ? 'pinned-column' : ''}
                            style={{
                              width: column.fix ? '118px' : '100px',
                              position: column.fix ? 'sticky' : 'relative',
                              left: column.fix ? `${index * 150}px` : 'auto',
                              zIndex: column.fix ? 1 : 'auto',
                              background: column.fix ? '#fff' : 'inherit',
                            }}
                          >{this.formatValue(row[column.key], column.type, column.format)}</TableCell>
                        )}
                        {column.track && (
                          <TableCell
                            key={column.key}
                            className={column.fix ? 'pinned-column' : ''}
                            style={{
                              width: column.fix ? '118px' : '100px',
                              position: column.fix ? 'sticky' : 'relative',
                              left: column.fix ? `${index * 150}px` : 'auto',
                              zIndex: column.fix ? 1 : 'auto',
                              background: column.fix ? '#fff' : 'inherit',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ flex: 1 }}>
                                {this.formatValue(row[column.key], column.type, column.format)}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    position: 'relative',
                                    display: 'inline-flex',
                                    marginRight: '8px',
                                  }}
                                >
                                  
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ padding: '4px', minWidth: 'unset', width: '24px', height: '24px' }}
                                    onClick={(event) => {
                                      this.handleFolderClick(event, row, row.FAMILY_ID, row.UNIFIED_SONG_ID, row.folderIds);
                                      event.stopPropagation();
                                    }}
                                  >
                                    <FolderIcon style={{ fontSize: '16px' }} />
                                  </Button>
                                  {folderMenuAnchor && (
                                    <ClickAwayListener onClickAway={(event) => {
                                      const isMenuClick = event.target.closest('#simple-menu');

                                      // If the click was within the menu, return early to prevent closing the menu
                                      if (isMenuClick) {
                                        return;
                                      }

                                      this.setState({ folderMenuAnchor: null }); // Close the menu
                                    }}>
                                      <Menu
                                        id="simple-menu"
                                        anchorEl={folderMenuAnchor}
                                        open={Boolean(folderMenuAnchor)}
                                        onClose={() => this.setState({ folderMenuAnchor: null })}
                                        anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                        }}
                                      >
                                        {folders.map((folder) => {
                                          const isFolderSelected = thisRow?.folderIds?.includes(folder.FOLDER_ID);

                                          return (
                                            <MenuItem

                                              onClick={(event) => {
                                                console.log('ha111')
                                                event.stopPropagation();
                                                console.log('ha')
                                                this.handleFolderMenuItemClick(
                                                  folder.FOLDER_ID,
                                                  thisRow,
                                                  thisRow.FAMILY_ID,
                                                  thisRow.UNIFIED_SONG_ID,
                                                  thisRow.SPOTIFY_ID,
                                                  null
                                                )
                                              }
                                              }
                                              key={folder.FOLDER_ID}
                                              value={folder.FOLDER_NAME}
                                            >
                                              {folder.FOLDER_NAME}
                                              {isFolderSelected && <CheckIcon style={{ marginLeft: 'auto' }} />}
                                            </MenuItem>
                                          );
                                        })}
                                        {/* <MenuItem>
                                          <TextField
                                            placeholder="New Folder"
                                            inputRef={this.newFolderNameRef}
                                            onKeyDown={(event) => {
                                              event.stopPropagation();
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <IconButton
                                                  onClick={() =>
                                                    this.handleFolderMenuItemClick(
                                                      'newFolder',
                                                      thisRow.FAMILY_ID,
                                                      thisRow,
                                                      thisRow.UNIFIED_SONG_ID,
                                                      this.newFolderNameRef.current.value
                                                    )
                                                  }
                                                >
                                                  <AddIcon />
                                                </IconButton>
                                              ),
                                            }}
                                          />
                                        </MenuItem> */}
                                        {/* Add more menu items as needed */}
                                      </Menu>
                                    </ClickAwayListener>
                                  )}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        )}
                      </React.Fragment>
                    ))}
                  </TableRow>
                  {(ExpandedRowComp && expandedRow) === row && (
                    <TableRow className="expanded-row" style={{ zIndex: 2, width: '100vw' }}>
                      <TableCell colSpan={columns.length} style={{ padding: 0, backgroundColor: '#fff', width: '100vw' }}>
                        <div style={{ position: 'sticky', left: 0, width: '100vw', overflow: 'hidden' }}>
                          <ExpandedRowComp endpoint={endpoint} row={expandedRow} id_column={id_column} />

                        </div>
                      </TableCell>
                    </TableRow>
                  )}

                </React.Fragment>
              ))}
            </TableBody>



          </Table>

          <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={data.length * 10}
            rowsPerPage={pageSize}
            page={pageNumber - 1}
            onChangePage={(event, newPage) =>
              this.setState({ pageNumber: newPage + 1 }, this.fetchData)
            }
            onChangeRowsPerPage={() => { }}
            ActionsComponent={() => (
              <div>
                <Button
                  onClick={this.previousPage}
                  disabled={pageNumber === 1}
                  color="primary"
                >
                  Previous
                </Button>
                <Button
                  onClick={this.nextPage}
                  disabled={pageNumber * pageSize >= data.length * 10}
                  color="primary"
                >
                  Next
                </Button>
              </div>
            )}
          />
          {/* </div> */}

        </div></div>
    );
  }
}

export default withOktaAuth(TableComponent);
