import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { endpoint } from './mainEndpoint';
// import { Rowing } from '@material-ui/icons';
import { Typography, IconButton, Tabs, Tab } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import LinePlot from './LinePlot';
import { withOktaAuth } from '@okta/okta-react';

class ArtistPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artistName: '', // Initialize artistName state
      albums: [], // Initialize albums state as an array
      isLoading: true, // Initialize loading state
      selectedTabIndex: 0, // Initialize the selected tab index

    };
  }

  componentDidMount() {
    // Access the artistId from props
    const { artistId } = this.props;
    console.log(artistId)
    // Simulate fetching the artist name and albums (replace with your API calls)
    Promise.all([this.fetchArtistName(artistId), this.fetchAlbums(artistId)])
      .then(([name, albums]) => {
        this.setState({ artistName: name, albums, isLoading: false }); // Update states
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ isLoading: false }); // Update loading state in case of an error
      });
  }
  componentDidUpdate(prevProps) {
    // Check if the artistId prop has changed
    console.log(this.props.artistId)
    if (this.props.artistId !== prevProps.artistId) {
      // Reset the component state to show loading while fetching new data
      this.setState({ isLoading: true });

      // Fetch new data based on the updated artistId
      Promise.all([this.fetchArtistName(this.props.artistId), this.fetchAlbums(this.props.artistId)])
      .then(([name, albums]) => {
        this.setState({ artistName: name, albums, isLoading: false }); // Update states
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ isLoading: false }); // Update loading state in case of an error
      });
    }
  }

  // Define the fetchArtistName function
  fetchArtistName(artistId) {
    const queryParams = new URLSearchParams();
    queryParams.append('artistId', artistId);
    const queryString = queryParams.toString();
    const { authState } = this.props.authState;
    console.log(this.props)
     const accessToken = authState.accessToken.accessToken;
     const headers = {
        Authorization: `Bearer ${accessToken}`
      };
    
    const url = `${endpoint}/artist_name?${queryString}`;
    
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(url,{
          headers: headers // Add the headers to the fetch options
        })
          .then((response) => response.json())
          .then((data) => {
            // Assuming the API returns the artist name as 'name'
            resolve(data.NAME);
          })
          .catch((error) => {
            console.error('Error fetching artist name:', error);
            reject(error);
          });
      }, 1000); // Simulate a delay
    });
  }

  // Define the fetchAlbums function
  fetchAlbums(artistId) {
    const queryParams = new URLSearchParams();
    queryParams.append('artistId', artistId);
    const queryString = queryParams.toString();
    const { authState } = this.props.authState;
     const accessToken = authState.accessToken.accessToken;
     const headers = {
        Authorization: `Bearer ${accessToken}`
      };
    const url = `${endpoint}/artist_album_metadata?${queryString}`;
    
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(url,{
          headers: headers // Add the headers to the fetch options
        } )
          .then((response) => response.json())
          .then((data) => {
            // Assuming the API returns album metadata as an array
            resolve(data);
          })
          .catch((error) => {
            console.error('Error fetching album metadata:', error);
            reject(error);
          });
      }, 1000); // Simulate a delay
    });
  }

  // Toggle album expansion
  toggleAlbumExpansion = (spotifyAlbumId) => {
    this.setState((prevState) => {
      const updatedAlbums = [...prevState.albums];

      const albumIndex = updatedAlbums.findIndex(
        (album) => album.SPOTIFY_ALBUM_ID === spotifyAlbumId
      );
      console.log(albumIndex)
      if (albumIndex !== -1) {
        const isExpanded = !updatedAlbums[albumIndex].isExpanded;
        console.log(isExpanded)
        updatedAlbums[albumIndex].isExpanded = isExpanded;
        console.log(updatedAlbums)
        console.log(!updatedAlbums[albumIndex].data)
        if (isExpanded && !updatedAlbums[albumIndex].data) {
          // Set the loading state for this album
          updatedAlbums[albumIndex].loading = true;

          this.fetchAlbumData(spotifyAlbumId)
            .then((data) => {
              // Update the album with the fetched data and mark it as no longer loading
              updatedAlbums[albumIndex].data = data;
              updatedAlbums[albumIndex].loading = false;
              console.log(updatedAlbums)
              this.setState({ albums: updatedAlbums });
            })
            .catch((error) => {
              console.error('Error fetching album data:', error);
              // Set loading to false even on error to prevent repeated requests
              updatedAlbums[albumIndex].loading = false;
              console.log(updatedAlbums)
              this.setState({ albums: updatedAlbums });
            });
        }
      }

      return { albums: updatedAlbums };
    });
  };

  
  
  fetchAlbumData = (spotifyAlbumId) => {
    const queryParams = new URLSearchParams();
    queryParams.append('AlbumId', spotifyAlbumId);
    const queryString = queryParams.toString();
    const url = `${endpoint}/artist_album_last_week_data?${queryString}`;
    const { authState } = this.props.authState;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
       Authorization: `Bearer ${accessToken}`
     };
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(url,{
          headers: headers // Add the headers to the fetch options
        })
          .then((response) => response.json())
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.error('Error fetching album data:', error);
            reject(error);
          });
      }, 1000); // Simulate a delay
    });
  };
  
  formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  
  handleTabChange = (event, newIndex) => {
    this.setState({ selectedTabIndex: newIndex });
  };
  formatValue = (value, type, format) => {
    if (value === null) {
      return '';
    }

    if (type === 'number') {
      if (format === 'literal') {
        return value || ''; // If value is undefined, return an empty string
      } else if (format === 'percentChange') {
        const roundedValue = Math.round(value * 10) / 10;
        return (roundedValue || 0).toFixed(1) + '%'; // If value is undefined, return '0%'
      } else if (format === 'number') {
        const absValue = Math.abs(value);
        if (absValue >= 1e9) {
          return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
        } else if (absValue >= 1e6) {
          return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
        } else if (absValue >= 1e3) {
          return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
        } else {
          return value || ''; // If value is undefined, return an empty string
        }
      }
    } else if (type === 'date') {
      const formattedDate = new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return formattedDate;
    }
    return value;
  };


  render() {
    // const { artistId } = this.props;
    const { artistName, albums, isLoading, selectedTabIndex } = this.state;
    console.log(albums)
    return (
      <div style={{ overflow: 'auto', maxHeight: '95vh' }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <h1>{artistName}</h1>
            {albums.map((album, index) => (
              <div key={album.SPOTIFY_ALBUM_ID}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <h2 onClick={() => this.toggleAlbumExpansion(album.SPOTIFY_ALBUM_ID)}>
                      {album.ALBUM_NAME}
                      <Typography variant="subtitle2">
                        Released: {this.formatDate(album.RELEASE_DATE)}
                      </Typography>
                    </h2>
                  </div>
                  <div>
                    <IconButton onClick={() => this.toggleAlbumExpansion(album.SPOTIFY_ALBUM_ID)}>
                      <ExpandMoreIcon
                        style={{
                          transform: album.isExpanded ? 'rotate(180deg)' : 'none',
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
                {album.isExpanded && (
                    <div>
                      {/* Add tabs for different views */}
                      <Tabs
                        value={selectedTabIndex}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label="Streaming" />
                        <Tab label="Album Songs" />
                        
                        {/* Add more tabs as needed */}
                      </Tabs>

                      {/* Render content based on the selected tab */}
                      {selectedTabIndex === 0 && (
                      <div>
                      <LinePlot endpoint = {endpoint} table_dir= {'artist_album_streams_data'} row = {album} id_column={'SPOTIFY_ALBUM_ID'} selectedColumnsLeft={['MRC_STREAMS']} selectedColumnsRight={[]} showToggles={true}></LinePlot>

                    </div>
                    )}


                      {selectedTabIndex === 1 && (
                        
                        <div>
                        {album.loading ? (
                          <p>Loading album data...</p>
                        ) : !album.data ? (
                          <></>
                        ) : (
                          
                            <div>
                              {album.data.map((rowData) => (
                                <div key={rowData.UNIFIED_SONG_ID}>
                                <p><strong>{rowData.TITLE}</strong> </p>
                                <p style={{ marginLeft: '20px' }}>This Week: {this.formatValue(rowData.THIS_WEEK,'number','number')}</p>
                                <p style={{ marginLeft: '20px' }}>YTD: {this.formatValue(rowData.YEAR_TO_DATE,'number','number')}</p>
                              </div>
                              ))}
                            </div>
                        )}
                      </div>
                      )}
                      {/* Add more content for other tabs */}
                    </div>)}
                    
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

// Wrap your class component with a function that provides route parameters
function ArtistPageWrapper(authState) {
  const { artistId } = useParams();
  console.log(artistId)
  console.log(artistId)
  console.log()
  // const navigate = useNavigate();
  // const { authState } = this.props;
  
  return <ArtistPage key={artistId} artistId={artistId} authState ={authState} />;
}

export default withOktaAuth(ArtistPageWrapper);
