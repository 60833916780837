import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { endpoint } from './mainEndpoint';

class ExportData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageSrc: null,
    };
  }

  generateChartImage = async () => {
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;

    // Define your Highcharts chart options
    const chartOptions = {
      chart: {
        type: 'bar', // Specify the type of chart (bar, line, etc.)
      },
      title: {
        text: 'My Chart',
      },
      series: [
        {
          data: [1, 2, 3, 4, 5],
        },
      ],
    };

    try {
      const response = await fetch(`${endpoint}/generate-chart-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          chartOptions: JSON.stringify(chartOptions), // Send chartOptions as a string
        }),
      });

      const result = await response.json();

      // Assuming the response contains the base64-encoded image data
      const { imageb64 } = result;

      console.log(imageb64)
      // Update the state to render the image
      this.setState({ imageSrc: `data:image/png;base64,${imageb64.data}` });
    } catch (error) {
      console.error('Error generating chart image:', error);
      // Handle errors here
    }
  };

  render() {
    const { imageSrc } = this.state;

    return (
      <div>
        <button onClick={this.generateChartImage}>Generate Chart Image</button>
        {imageSrc && <img src={imageSrc} alt="Generated Chart" />}
      </div>
    );
  }
}

export default withOktaAuth(ExportData);
