import React, { Component } from 'react';
import DataGroup from './DataGroup'; // Import your DataGroup component

class DataBoxes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGroupComponents: [], // Store DataComponent instances
            data: this.props.data
        };
        this.dataGroupComponentRefs = []; // Array to store refs for DataComponents
    }

    componentDidMount() {
        // Create DataComponent instances and store them in state
        // console.log('running')
        this.createDataGroupComponents();
    }
    createDataGroupComponents() {
        // const { data } = this.props;
        // const artistId = 2004796
        const {artistId,data} = this.props;
        // const {data} = this
        // Create DataComponent instances and attach refs
        const dataGroupComponents = data.map((item, index) => (
            <DataGroup key={index}
            key1={index}
                data={item}
                setDataComponent={this.setDataComponent}
                ref={(ref) => this.dataGroupComponentRefs[index] = ref}
            />
        ));
        // this.setState({  });

        this.setState({ dataGroupComponents }, () => {
            // This callback ensures that the state has been updated before accessing it
            // console.log(this.dataGroupComponentRefs);
          });
    }

    setDataComponent = (index, sub_index, newData, isChart=false) => {
        this.setState((prevState) => {
          const updatedDataGroupComponents = [...prevState.dataGroupComponents];
          const updatedDataGroup = { ...updatedDataGroupComponents[index].props.data };

          console.log(index,sub_index)
          console.log(updatedDataGroupComponents)
          console.log(updatedDataGroup)
          console.log(isChart)
          if (updatedDataGroup.componentType == 'DataComponent') {
            const updatedInfo = [...updatedDataGroup.info];
      
            // Update the specific data for the given index and sub_index
            updatedInfo[sub_index] = { ...updatedInfo[sub_index], data: newData, loading: false };
        
            // Update the DataGroup with the modified info
            updatedDataGroup.info = updatedInfo;
            updatedDataGroupComponents[index] = React.cloneElement(updatedDataGroupComponents[index], {
                data: updatedDataGroup,
                // loading: false,
            });
        
            // Update the data state directly
            const updatedData = [...prevState.data];
            updatedData[index] = updatedDataGroup;
            this.props.setData(updatedData);
            return { dataGroupComponents: updatedDataGroupComponents, data: updatedData, loading: false };
          } else if (updatedDataGroup.componentType == 'LinePlotSimple' || updatedDataGroup.componentType == 'PieChart' || updatedDataGroup.componentType == 'BarPlot'){
            if (isChart) {
                console.log('Chart Change')
                console.log(newData)
                updatedDataGroup.info= { ...updatedDataGroup.info, chart_png: newData, loading: false };

            } else {
                updatedDataGroup.info= { ...updatedDataGroup.info, data: newData, loading: false };
            }
        
        

            updatedDataGroupComponents[index] = React.cloneElement(updatedDataGroupComponents[index], {
                data: updatedDataGroup,
                // loading: false,
            });
        
            // Update the data state directly
            const updatedData = [...prevState.data];
            updatedData[index] = updatedDataGroup;
            this.props.setData(updatedData);
            return { dataGroupComponents: updatedDataGroupComponents, data: updatedData, loading: false };
          }
          else if (updatedDataGroup.componentType == 'Songs'){
            console.log('Song Change')
                console.log(newData)
                updatedDataGroup.info= { ...updatedDataGroup.info, data: newData, loading: false };
        

            updatedDataGroupComponents[index] = React.cloneElement(updatedDataGroupComponents[index], {
                data: updatedDataGroup,
                // loading: false,
            });
        
            // Update the data state directly
            const updatedData = [...prevState.data];
            updatedData[index] = updatedDataGroup;
            this.props.setData(updatedData);
            return { dataGroupComponents: updatedDataGroupComponents, data: updatedData, loading: false };
          }

          
         

        }, () => {
          console.log('Data updated:', index, sub_index, newData);
        });

      };
      


    logRefs() {
        if (this.dataGroupComponentRefs.length > 0) {
            // console.log(this.dataGroupComponentRefs);
        }
    }
    handleLogRefsClick = () => {
        this.logRefs();
    };

    
    

    render() {
        // Define data for each DataGroup

        const { dataGroupComponents, dataComponentRefs } = this.state;
        console.log(dataGroupComponents)
        console.log('rerender DataBoxes')

        // console.log(this.dataGroupComponentRefs)
        const headerHeight = document.querySelector('.MuiAppBar-root')
            ? document.querySelector('.MuiAppBar-root').clientHeight
            : 0;
        const containerHeight = window.innerHeight - headerHeight;
        return (
            <div>
                <div className='boxes-container' style={{
                    position: 'relative',
                    flexGrow: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    // height: `${containerHeight}px`, // Set the calculated height
                    // overflowY: 'scroll',
                }}>
                    {dataGroupComponents}

                </div>
                {/* <button onClick={this.handleLogRefsClick}>Log Refs</button> */}

            </div>
        );
    }
}

export default DataBoxes;
