import React, { Component } from 'react';
import {
  FormControl, Select, MenuItem, IconButton, TextField, Button,
  Dialog, DialogContent,DialogActions,DialogTitle
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { withOktaAuth } from '@okta/okta-react';
import {endpoint} from './mainEndpoint';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmationDialog = ({ open, title, message, onConfirm, onCancel }) => {
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onConfirm(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

class FolderComponent extends Component {
  state = {
    folders: [],
    selectedFolder: -1,
    isMenuOpen: false,
    isShareDialogOpen: false,
    isDeleteConfirmationOpen: false,
    deletingFolder: null,
    sharedFolder: null,
    sharedEmail: '',
    successDialogOpen:false,
      errorDialogOpen:false,
      errorMessage: ''
  };

  folderSelectRef = React.createRef();
  newFolderNameRef = React.createRef();

  componentDidMount() {
    this.fetchFolders();
  }

  fetchFolders() {
    console.log('fetching folders');
    // const url = `${endpoint}/folders`;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    const queryParams = new URLSearchParams();
    if (this.props.folderType) {
      queryParams.append('folderType', this.props.folderType);
    }
    const queryString = queryParams.toString();
    const url = `${endpoint}/folders?${queryString}`;
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          headers: headers // Add the headers to the fetch options
        })
        .then((response) => response.json())
        .then((folders) => {
          this.setState({ folders, loadingFolders: false });
          if (this.props.setFolders){
            this.props.setFolders(folders)
          }
          console.log('Done loading folders');
        })
        .catch((error) => {
          console.error('Error fetching folders:', error);
        });
    });
  }

  handleFolderSelect = (event) => {
    const selectedFolder = event.target.value;
    this.setState(
      (prevState) => ({
        selectedFolder,
        isMenuOpen: prevState.isMenuOpen && selectedFolder === -1,
      }),
      () => {
        // Call the setSelectedFolder callback to update the selected folder in the App component
        this.props.setSelectedFolder(selectedFolder);
      }
    );
  };
  


  handleShareClick = (folder) => {
    this.setState({
      sharedFolder: folder,
      isShareDialogOpen: true,
    });
  };

  handleCloseShareDialog = () => {
    this.setState({
      isShareDialogOpen: false,
      sharedEmail: '', // Clear the shared email input
      sharedFoldre: null,
    });
  };
  handleDeleteConfirmation = (confirm) => {
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    if (confirm) {
        const { deletingFolder } = this.state;
        const folderId = deletingFolder.FOLDER_ID;
    
        // Call the delete folder endpoint
        fetch(`${endpoint}/delete_folder?folderId=${folderId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ folderId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Folder deleted:', result.message);
            // Handle any necessary state updates or UI changes after successful deletion
          })
          .catch((error) => {
            console.error('Error deleting folder:', error);
            // Handle the error case
          })
          .finally(() => {
            // This code will be executed whether the delete operation succeeds or fails
            this.fetchFolders();
          });
      }

    // Reset the confirmation dialog state
    this.setState({
      deletingFolder: null,
      isDeleteConfirmationOpen: false,
    });
  };

  handleShare = () => {
    // const { sharedEmail, selectedFolder } = this.state;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;

    const { sharedFolder, } = this.state;
    const folderId = sharedFolder.FOLDER_ID;
    const folderName = sharedFolder.FOLDER_NAME;
    const sharedEmail = this.sharedInput.value;
    const folderType = this.props.folderType


    // Call the delete folder endpoint
    fetch(`${endpoint}/share_folder?folderId=${folderId}&sharedEmail=${sharedEmail}&folderType=${this.props.folderType}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,

      },
      body: JSON.stringify({ folderId, folderName, sharedEmail, folderType}),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.success) {
          console.log('Folder shared:', result.success);
          // Set successDialogOpen to true when sharing is successful
          this.setState({ successDialogOpen: true });
        } else {
          console.error('Error sharing folder:', result.error);
          // Set errorDialogOpen to true when an error occurs
          this.setState({
            errorDialogOpen: true,
            errorMessage: result.error
          });
        }

        // Handle any necessary state updates or UI changes after successful deletion
      })
      .catch((error) => {
        console.error('Error sharing folder:', error);
        // Set errorDialogOpen to true when an error occurs
        this.setState({
          errorDialogOpen: true,
          errorMessage: error
        });
        // Handle the error case
      });
    this.fetchFolders()

    // Reset the confirmation dialog state
    // Perform your sharing logic here with sharedEmail and selectedFolder
    // Close the dialog
    this.handleCloseShareDialog();
  };


  handleFolderMenuItemClick = (folderId, row, familyId, unifiedSongId, spotifyId, folderName) => {
    console.log('hahah')
    // thisRow?.folderIds?.includes(folder.FOLDER_ID);
    console.log(row?.folderIds?.includes(folderId))
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    if (folderId === 'newFolder') {
      // Handle the logic to create a new folder with the given folderName
      console.log('Create new folder:', folderName);

      // Perform the API call to add the new folder to the database
      const requestData = {
        user_id: 1, // Replace with the actual user ID value
        folder_name: folderName,
      };

      console.log(folderName);
      const queryParams = new URLSearchParams();
      if (this.props.folderType) {
        queryParams.append('folderType', this.props.folderType);
      }
      const queryString = queryParams.toString();
      const url = `${endpoint}/add_folder?${queryString}`;

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestData), // Send the requestData object as the request body
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('New folder added:', result);
          this.fetchFolders()
          // Handle any necessary state updates or UI changes
        })
        .catch((error) => {
          console.error('Error adding new folder:', error);
          // Handle the error case
        });



    } else {
      // Handle the logic to add the trackedIdMRC to the selected folder
      console.log('Add trackedIdMRC to folder:', folderId);
      console.log('Family ID:', familyId);
      console.log('Unified Song ID:', unifiedSongId);

      if (row?.folderIds?.includes(folderId)) {
        let user_id = 1;
        fetch(`${endpoint}/delete_from_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family deleted from folder:', result);
            // Handle any necessary state updates or UI changes
            this.fetchData()
          })
          .catch((error) => {
            console.error('Error deleting family from folder:', error);
            // Handle the error case
          });

      } else {
        let user_id = 1;
        fetch(`${endpoint}/add_to_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family added to folder:', result);
            this.fetchData()
            // Handle any necessary state updates or UI changes
          })
          .catch((error) => {
            console.error('Error adding family to folder:', error);
            // Handle the error case
          });
      }

    }

    // Close the folder menu
    this.setState({
      folderMenuAnchor: null,
    });

  };

  render() {
    const { folders,successDialogOpen,
      errorDialogOpen,
      errorMessage, selectedFolder, isShareDialogOpen, deletingFolder,isDeleteConfirmationOpen } = this.state;

    return (
      <div>
        <FormControl color="primary" style={{}}>
          <Select
            ref={this.folderSelectRef}
            labelId="demo-simple-select-label"
            value={selectedFolder}
            onChange={this.handleFolderSelect}
            onClick={(event) => {
              this.setState({ isMenuOpen: true });
              event.stopPropagation();
            }}
            inputProps={{
              name: 'folder',
              id: 'folder-select',
            }}
            style={{ width: '100%' }}
          >
            <MenuItem value="-1">All Folders</MenuItem>
            {folders.map((folder) => (
              <MenuItem key={folder.FOLDER_ID} value={folder.FOLDER_ID}>
                {folder.FOLDER_NAME}
                {selectedFolder !== folder.FOLDER_ID && folder.FOLDER_NAME !== "TRACKED" && (
                  <div>
                    <IconButton onClick={(event) => {
                      event.stopPropagation();
                      this.handleShareClick(folder);
                    }}>
                      <ShareIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => {
                        event.stopPropagation();
                        this.setState({
                            deletingFolder: folder,
                            isDeleteConfirmationOpen: true,
                        });
                        }}
                    >

                        <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </MenuItem>
            ))}
            <MenuItem>
                <TextField
                placeholder="New Folder"
                inputRef={this.newFolderNameRef}
                // onChange={(event) => this.setState({ newFolderName: event.target.value })}
                InputProps={{
                    endAdornment: (
                    <IconButton
                        onClick={() => {
                        this.handleFolderMenuItemClick('newFolder', null, null, null, null, this.newFolderNameRef.current.value)

                        }}

                    >
                        <AddIcon />
                    </IconButton>
                    ),
                }}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
                onClick={(event) => {
                    event.stopPropagation(); // Prevent selecting the menu item
                }}
                />
            </MenuItem>
          </Select>
        </FormControl>
        <ConfirmationDialog
                  open={isDeleteConfirmationOpen}
                  title="Delete Folder"
                  message={`Are you sure you want to delete folder ${deletingFolder?.FOLDER_NAME}?`}
                  onConfirm={this.handleDeleteConfirmation}
                  onCancel={this.handleDeleteConfirmation}
                />
        <Dialog open={isShareDialogOpen} onClose={this.handleCloseShareDialog}>
          <DialogContent>
            <TextField
              onChange={(e) => this.setState({ sharedEmail: e.target.value })}
            />
            <TextField
              label="Insert Email"
              placeholder="Insert email"
              variant="outlined"
              size="small"
              fullWidth
              inputRef={(input) => (this.sharedInput = input)}
            />
            <Button onClick={this.handleShare} color="primary">
              Share
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={successDialogOpen}
          onClose={() => this.setState({ successDialogOpen: false })}
        >
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <DialogContentText>The folder has been successfully shared.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ successDialogOpen: false })} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
        open={errorDialogOpen}
        onClose={() => this.setState({ errorDialogOpen: false })}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ errorDialogOpen: false })} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    );
  }
}

export default withOktaAuth(FolderComponent);
