import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class Toggle extends Component {
  render() {
    const { selectedValue, handleToggleChange, options } = this.props;

    return (
      <div>
        {options.map((option) => (
          <Button
            key={option.key}
            variant={selectedValue === option.key ? 'contained' : 'outlined'}
            onClick={() => handleToggleChange(option.key)}
          >
            {option.title}
          </Button>
        ))}
      </div>
    );
  }
}

export default Toggle;
