import React, { Component } from 'react';
import { Tabs, Tab, Typography, Box,  Paper,  TextField, Checkbox} from '@material-ui/core';
import LinePlot from './LinePlot';
import ScatterPlot from './ScatterPlot';
import TableComponent from './TableComponent';
import Table_All from './Table_All';
import ExportData from './ExportData';
import SettingsDialog from './SettingsDialog';
class ExpandedRow extends Component {
  state = {
    activeTab: 0,
    maxWidth: window.innerWidth - 160, // Initial value based on window size
    table_dir: 'streams_table_albums',
    columns: [
        { key: 'ARTIST', displayName: 'Artist', type: 'category', format: 'category', fix: true, optionsMatchingKey: 'SODATONE_ARTIST_ID', table: 'STREAMS_TABLE' , hide: true,},
        { key: 'WARNER_ARTIST_ID', displayName: 'Warner Artist ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
        { key: 'SODATONE_ARTIST_ID', displayName: 'Sodatone Artist ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
        { key: 'TITLE', displayName: 'Title', type: 'string', id: 'FAMILY_ID', track: true, fix: true },
        { key: 'FAMILY_ID', displayName: 'Family ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
        { key: 'UNIFIED_SONG_ID', displayName: 'Unified Song ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
        { key: 'SPOTIFY_ID', displayName: 'Spotify ID', type: 'string', fix: true, hide: true, alwaysHide: true },
        // {key: 'FOLDER_ID', type:'folder', hide:true, alwaysHide: true, fixedValue:true, value:-1 },
        {key: 'MRC_TOGGLED', type:'folder', hide:true,alwaysHide: true, fixedValue:true, value:true },
        { key: 'WEEK_0', displayName: 'Estimated Building Week Streams', type: 'number', format: 'number' },
        { key: 'BUILDING_WEEK_STREAM_COUNT', displayName: 'Building Week Streams', type: 'number', format: 'number' },
        { key: 'WEEK_0_PCT_CHANGE', displayName: 'Percent Change Building Week', type: 'number', format: 'percentChange' },

        { key: 'WEEK_1', displayName: 'Last Week Streams', type: 'number', format: 'number' },
        { key: 'WEEK_1_PCT_CHANGE', displayName: 'Percent Change Last Week', type: 'number', format: 'percentChange' },
        // { key: 'WEEK_2', displayName: '2 Week Ago Streams', type: 'number',format :'number'},
        // { key: 'WEEK_2_PCT_CHANGE', displayName: 'Percent Change 2 Weeks Ago', type: 'number',format :'percentChange' },
        // { key: 'WEEK_3', displayName: '3 Week Ago Streams', type: 'number',format :'number'},
        // { key: 'WEEK_3_PCT_CHANGE', displayName: 'Percent Change 3 Weeks Ago', type: 'number', format :'percentChange'},


        { key: 'BUILDING_WEEK_POST_COUNT', displayName: 'Building Week TikTok Creates', type: 'number', format: 'number',hide: true },
        { key: 'PCT_CHANGE_POST_COUNT', displayName: 'Percent Change Building Week TikTok Creates', type: 'number', format: 'percentChange',hide: true },




        { key: 'BW_SPOTIFY_SKIP_RATE', displayName: 'BW Spotify Skip Rate', type: 'number', format: 'number',hide: true },
        { key: 'BW_SPOTIFY_PASSION_SCORE', displayName: 'BW Spotify Passion Score', type: 'number', format: 'number' ,hide: true},
        { key: 'BW_PCT_US_ORGANIC_STREAMS', displayName: 'BW Percentage US Organic Streams', type: 'number', format: 'percentChange',hide: true },

        { key: 'LW_SPOTIFY_PASSION_SCORE', displayName: 'LW Passion Score', type: 'number', format: 'number',hide: true },
        { key: 'LW_SPOTIFY_SKIP_RATE', displayName: 'LW Skip Rate', type: 'number', format: 'percentChange',hide: true },

        { key: 'LW_US_ORGANIC_STREAMS', displayName: 'LW US Organic Streams', type: 'number', format: 'number',hide: true },
        { key: 'LW_PCT_US_ORGANIC_STREAMS', displayName: 'LW Percentage US Organic Streams', type: 'number', format: 'percentChange',hide: true },


        { key: 'FIRST_FRIDAY_STREAM_COUNT', displayName: 'First Friday Streams', type: 'number', format: 'number' ,hide: true},
        { key: 'FIRST_FRIDAY_ORGANIC_STREAMS', displayName: 'First Friday Organic Streams', type: 'number', format: 'number',hide: true },
        { key: 'FIRST_FRIDAY_PCT_ORGANIC_STREAMS', displayName: 'First Friday % Organic', type: 'number', format: 'number',hide: true },
        { key: 'FIRST_FRIDAY_PASSION_SCORE', displayName: 'First Friday Passion Score', type: 'number', format: 'number',hide: true },
        { key: 'FIRST_FRIDAY_SKIP_RATE', displayName: 'First Friday Skip Rate', type: 'number', format: 'number',hide: true },

        { key: 'THREE_DAY_STREAM_COUNT', displayName: 'Three Day Streams', type: 'number', format: 'number',hide: true },
        { key: 'THREE_DAY_ORGANIC_STREAMS', displayName: 'Three Day Organic Streams', type: 'number', format: 'number',hide: true },
        { key: 'THREE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Three Day % Organic', type: 'number', format: 'percentChange',hide: true },
        { key: 'THREE_DAY_PASSION_SCORE', displayName: 'Three Day Passion Score', type: 'number', format: 'number',hide: true  },
        { key: 'THREE_DAY_SKIP_RATE', displayName: 'Three Day Skip Rate', type: 'number', format: 'number',hide: true },

        { key: 'FIVE_DAY_STREAM_COUNT', displayName: 'Five Day Streams', type: 'number', format: 'number',hide: true },
        { key: 'FIVE_DAY_ORGANIC_STREAMS', displayName: 'Five Day Organic Streams', type: 'number', format: 'number',hide: true },
        { key: 'FIVE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Five Day % Organic', type: 'number', format: 'percentChange',hide: true },
        { key: 'FIVE_DAY_PASSION_SCORE', displayName: 'Five Day Passion Score', type: 'number', format: 'number' ,hide: true},
        { key: 'FIVE_DAY_SKIP_RATE', displayName: 'Five Day Skip Rate', type: 'number', format: 'number',hide: true },


        { key: 'FIRST_WEEK_STREAM_COUNT', displayName: 'First Week Streams', type: 'number', format: 'number' ,hide: true},
        { key: 'FIRST_WEEK_ORGANIC_STREAMS', displayName: 'First Week Organic Streams', type: 'number', format: 'number',hide: true },
        { key: 'FIRST_WEEK_PCT_ORGANIC_STREAMS', displayName: 'First Week % Organic', type: 'number', format: 'percentChange',hide: true },
        { key: 'FIRST_WEEK_PASSION_SCORE', displayName: 'First Week Passion Score', type: 'number', format: 'number' ,hide: true},
        { key: 'FIRST_WEEK_SKIP_RATE', displayName: 'First Week Skip Rate', type: 'number', format: 'percentChange',hide: true },




        { key: 'YTD', displayName: 'YTD', type: 'number', format: 'number' },
        { key: 'ATD', displayName: 'ATD', type: 'number', format: 'number' },
        { key: 'RELEASE_DATE', displayName: 'Release Date', type: 'date' }

    ],
    filters: [{
        column: 'MRC_TOGGLED',
        operator: 'TOGGLE',
        value: true
    },{
        column: 'SPOTIFY_ALBUM_ID',
        operator: '=',
        value: this.props.row['SPOTIFY_ALBUM_ID']
    },
    {
        column: 'FOLDER_ID',
        operator: 'FOLDER',
        value: -1
    }
    ],
    sort: {key: 'WEEK_0', type:'number',order: 'desc'},
    selectedFolder: -1,
    selectedFilterColumn:-1,
    data:[],
    folders:[],
    searchValue: '',
    folderType:'songs',
  };

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  componentDidMount() {
    // Update maxWidth when the window is resized
    window.addEventListener('resize', this.updateMaxWidth);
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('resize', this.updateMaxWidth);
  }

  updateMaxWidth = () => {
    this.setState({
      maxWidth: window.innerWidth - 160,
    });
  };

  setData = (data) => {
    console.log('Setting data in Table_All:', data);
    this.setState({ data: data });
  };
  setFilters = (filters) => {
    this.setState({ filters: filters });
  };
  setColumns = (columns) => {
    this.setState({columns: columns });
  };
  

  setSort = (sort) => {
    this.setState({ sort: sort });
  };
  
  setFolders = (folders) => {
    this.setState({ folders: folders });
  };

  handleSearchChange = (event) => {
    const searchValue = event.target.value;
    this.setState(
      {
        searchValue,
      },
    );
  };
  handleApplyColumnSettings = (columnHideSettings) => {
    // Separate pinned and alwaysHide columns
    const pinnedColumns = [];
    const alwaysHideColumns = [];
    const otherColumns = [];
    console.log(columnHideSettings)
    this.state.columns.forEach((setting) => {
      const correspondingColumn = columnHideSettings.find((column) => column.key === setting.key);
      if (setting) {
        if (setting.fix) {
          pinnedColumns.push({ ...setting});
        } else if (setting.alwaysHide) {
          alwaysHideColumns.push({ ...setting });
        } else {
        //   otherColumns.push({ ...correspondingColumn });
        }
      }
    });
    console.log(pinnedColumns)
    console.log(alwaysHideColumns)
    console.log(otherColumns)
    // Combine columns with the desired order
    const updatedColumns = [...pinnedColumns, ...alwaysHideColumns, ...columnHideSettings];
  
    // Update the state with the modified columns
    this.setState({
      columns: updatedColumns,
    });
  };
  render() {
    const { activeTab,columns,filters,table_dir,sort,selectedFolder, maxWidth,data  } = this.state;
    const { endpoint,row,id_column } = this.props;
    

    console.log(row)
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        {/* Tabs */}
        <Tabs value={activeTab} onChange={this.handleTabChange} orientation="vertical">
          <Tab label="Stream Details" />
          <Tab label="Songs" />
          {/* <Tab label="Tab 3" /> */}
        </Tabs>

        {/* Content */}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', maxWidth: `${maxWidth}px`, maxHeight: '500px', overflowY: 'auto' }}>
          <TabPanel value={activeTab} index={0}>
          <div style= {{maxHeight: '500px'}}>
          <Paper>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft:'7px'}}>

                    <div style={{ marginLeft: '10px' }}>
                    <span >MRC Data</span>

                    <Checkbox
                    checked={filters.find((filter) => filter.column === 'MRC_TOGGLED').value}
                    onChange={this.handleToggleChange}
                    color="primary"
                    />
                    </div>
          <div style={{ marginLeft: '10px' }}>
          <ExportData
                    columns={columns}
                    filters={filters}
                    table_dir={table_dir}
                    sort={sort}
                    // selectedFolder={selectedFolder}
                    data={data}
                    />
            </div>
            <div style={{ marginLeft: '10px' }}>
                    <SettingsDialog
                    columns={columns}
                    handleApplyColumnSettings={this.handleApplyColumnSettings}
                    
                    />
                    </div></div>
          <Table_All
                    columns={columns}
                    filters={filters}
                    table_dir={table_dir}
                    sort= {sort}
                    selectedFolder={selectedFolder} 
                    setData= {this.setData}
                    setFilters={this.setFilters}
                    setSort={this.setSort}
                    />      </Paper>   </div>
                
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
          <LinePlot endpoint = {endpoint} table_dir= {'artist_album_streams_data'} row = {row} id_column={'SPOTIFY_ALBUM_ID'} selectedColumnsLeft={['MRC_STREAMS']} selectedColumnsRight={[]} showToggles={true}></LinePlot>
            </TabPanel>
          
        </div>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default ExpandedRow;
