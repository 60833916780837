

import React, { Component } from 'react';
// import DataLoader from './DataLoader';
import DynamicTable from './DynamicTable';
import './App.css';
import ExpandedRow from './ExpandedRow';
import { endpoint } from './mainEndpoint';
import ColumnSettings from './ColumnSettings';
import SettingsDialog from './SettingsDialog';
import {
    TextField, Checkbox, Table,
    TableHead, TableBody, TableRow, TableCell, IconButton,
    TablePagination, Button, Menu, MenuItem, Typography,
    FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { Sort } from '@material-ui/icons';
import Table_All from './Table_All';
class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
          table_dir: 'artist_table',
          columns: [
            // { key: 'ARTIST', displayName: 'Artist', type: 'category', format:'category', fix: true, optionsMatchingKey:'SODATONE_ARTIST_ID', table:'ALL_ARTIST_DATA'},
            { key: 'ARTIST', displayName: 'Artist', type: 'string'},

            { key: 'SODATONE_ARTIST_ID', displayName: 'Sodatone Artist ID', type: 'number',format :'literal', fix: true, hide:true, alwaysHide: true},
            { key: 'LABEL', displayName: 'Label', type: 'category', format:'category', optionsMatchingKey:'LABEL', table:'ALL_ARTIST_DATA'},
            // { key: 'SODATONE_GENRE', displayName: 'Genre', type: 'category', format:'category', optionsMatchingKey:'SODATONE_GENRE', table:'ALL_ARTIST_DATA'},
            { key: 'SODATONE_GENRE', displayName: 'Genre', type: 'string'},

            { key: 'LIST_GENRES', displayName: 'Spotify Genres', type: 'string'},
    
    
            { key: 'EST_BW_AUDIO_STREAMS', displayName: 'Estimated Building Week Audio Streams', type: 'number', format :'number'},
            { key: 'BW_PCT_CHANGE', displayName: 'Percent Change Building Week', type: 'number',format :'percentChange'},
    
            { key: 'AUDIO_STREAMS_CURRENT_VALUE', displayName: 'Last Week Audio Streams', type: 'number', format :'number'},
            { key: 'AUDIO_STREAMS_PCT_CHANGE', displayName: 'Percent Change Last Week', type: 'number',format :'percentChange'},
            
            { key: 'SPL_CURRENT_VALUE', displayName: 'Streams Per Listener', type: 'number', format :'number'},
            // { key: 'SPL_PREVIOUS_VALUE', displayName: 'Streams Per Listener 7 Days Ago', type: 'number', format :'number'},
    
            { key: 'STREAMS_PERCENTILE', displayName: 'Streams Percentile', type: 'number', format :'number'},
    
            
            { key: 'SPOTIFY_FOLLOWERS', displayName: 'Spotify Followers', type: 'number',format :'number'},
            { key: 'SP_FOL_PCT_CHANGE', displayName: '7 Day Percent Change Spotify Followers', type: 'number',format :'percentChange'},
            { key: 'SP_FOL_PERCENTILE', displayName: 'Spotify Follower Growth Percentile', type: 'number',format :'number'},
    
            { key: 'INSTAGRAM_FOLLOWERS', displayName:'Instagram Followers', type: 'number',format :'number'},
            { key: 'IG_FOL_PCT_CHANGE', displayName: '7 Day Percent Change Instagram Followers', type: 'number',format :'percentChange'},
            { key: 'IG_FOL_PERCENTILE', displayName: 'Instagram Follower Growth Percentile', type: 'number',format :'number'},
            { key: 'IG_ENG', displayName: 'Instagram Engagement Percentile', type: 'number',format :'number'},
    
            { key: 'TIKTOK_FOLLOWERS', displayName:'TikTok Followers', type: 'number',format :'number'},
            { key: 'TT_FOL_PCT_CHANGE', displayName: '7 Day Percent Change TikTok Followers', type: 'number',format :'percentChange'},
            { key: 'TT_FOL_PERCENTILE', displayName: 'TikTok Follower Growth Percentile', type: 'number',format :'number'},
            { key: 'TT_ENG', displayName: 'TikTok Engagement Percentile', type: 'number',format :'number'},
            { key: 'TT_CREATES', displayName: 'Last 7 Days TikTok Creates', type: 'number', format :'number'},
            { key: 'TT_CREATES_PERCENTILE', displayName: 'TikTok Creates Growth Percentile', type: 'number',format :'number'},
    
            { key: 'TT_LIKES', displayName: 'Last 7 Days TikTok Likes', type: 'number', format :'number'},
            { key: 'TT_LIKES_PERCENTILE', displayName: 'TikTok Likes Growth Percentile', type: 'number',format :'number'},
    
    
            { key: 'SHAZAM_TAGS', displayName: 'Last 7 Days Shazam Tags', type: 'number', format :'number'},
            { key: 'SHAZAM_TAGS_PERCENTILE', displayName: 'Shazam Tags Growth Percentile', type: 'number',format :'number'},
    
            { key: 'SOUNDCLOUD_PLAYS', displayName: 'Last 7 Days Soundcloud Plays', type: 'number', format :'number'},
            { key: 'SOUNDCLOUD_PLAYS_PERCENTILE', displayName: 'Soundcloud Plays Growth Percentile', type: 'number',format :'number'},
    
            { key: 'AUDIOMACK_PLAYS', displayName: 'Last 7 Days Audiomack Plays', type: 'number', format :'number'},
            { key: 'AUDIOMACK_PLAYS_PERCENTILE', displayName: 'Audiomack Plays Growth Percentile', type: 'number',format :'number'},
    
            { key: 'YOUTUBE_SUBSCRIBERS', displayName: 'YouTube Subscribers', type: 'number', format :'number'},
            { key: 'YT_SUB_PCT_CHANGE', displayName: '7 Day Percent Change YouTube Subscribers', type: 'number',format :'percentChange'},
            { key: 'YT_SUB_PERCENTILE', displayName: 'YouTube Subscriber Growth Percentile', type: 'number',format :'number'},
    
            { key: 'MOST_RECENT_RELEASE', displayName: 'Most Recent Release Date', type: 'date'},
            { key: 'FIRST_RELEASE', displayName: 'First Release Date', type: 'date'},
             
          ],
          filters :[],
          sort: {key: 'STREAMS_PERCENTILE', type:'number',order: 'desc'},
        };
        

    }

    render() {
        // const endpoint = 'http://localhost:3500'; // Replace with your desired endpoint
        const { columns, filters,table_dir,sort } = this.state;

        return (
            <div>
                <Table_All
                    columns={columns}
                    filters={filters}
                    table_dir={table_dir}
                    sort= {sort} />
            </div>
        );

    }
}

export default withOktaAuth(App);


