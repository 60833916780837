import React, { Component } from 'react';
// import DataLoader from './DataLoader';
import TableComponent from './TableComponent';
import './App.css'; 
import ExpandedRow from './ExpandedRow';
import {endpoint} from './mainEndpoint';

class App extends Component {
  render() {
    // const endpoint = 'http://localhost:3500'; // Replace with your desired endpoint

    const table_dir = 'streams_table'
    console.log(endpoint)
    const columns = [
      { key: 'ARTIST', displayName: 'Artist', type: 'category', format:'category', fix: true},
      { key: 'WARNER_ARTIST_ID', displayName: 'Warner Artist ID', type: 'number',format :'literal', fix: true, hide:true},
      { key: 'SODATONE_ARTIST_ID', displayName: 'Sodatone Artist ID', type: 'number',format :'literal', fix: true, hide:true},
      { key: 'TITLE', displayName: 'Title', type: 'string', id: 'FAMILY_ID', track: true, fix: true},
      { key: 'FAMILY_ID', displayName: 'Family ID', type: 'number', format :'literal', fix: true, hide:true},
      { key: 'UNIFIED_SONG_ID', displayName: 'Unified Song ID', type: 'number', format :'literal', fix: true,hide:true},
      { key: 'SPOTIFY_ID', displayName: 'Spotify ID', type: 'string', fix: true,hide:true},
      { key: 'WEEK_0', displayName: 'Estimated Building Week Streams', type: 'number',format :'number' },
      { key: 'BUILDING_WEEK_STREAM_COUNT', displayName: 'Building Week Streams', type: 'number', format :'number'},
      { key: 'WEEK_0_PCT_CHANGE', displayName: 'Percent Change Building Week', type: 'number', format :'percentChange'},
      
      { key: 'WEEK_1', displayName: 'Last Week Streams', type: 'number', format :'number'},
      { key: 'WEEK_1_PCT_CHANGE', displayName: 'Percent Change Last Week', type: 'number',format :'percentChange'},
      // { key: 'WEEK_2', displayName: '2 Week Ago Streams', type: 'number',format :'number'},
      // { key: 'WEEK_2_PCT_CHANGE', displayName: 'Percent Change 2 Weeks Ago', type: 'number',format :'percentChange' },
      // { key: 'WEEK_3', displayName: '3 Week Ago Streams', type: 'number',format :'number'},
      // { key: 'WEEK_3_PCT_CHANGE', displayName: 'Percent Change 3 Weeks Ago', type: 'number', format :'percentChange'},

      
      { key: 'BUILDING_WEEK_POST_COUNT', displayName: 'Building Week TikTok Creates', type: 'number', format :'number'},
      { key: 'PCT_CHANGE_POST_COUNT', displayName: 'Percent Change Building Week TikTok Creates', type: 'number', format :'percentChange'},
      



      { key: 'BW_SPOTIFY_SKIP_RATE', displayName: 'BW Spotify Skip Rate', type: 'number',format :'number'},
      { key: 'BW_SPOTIFY_PASSION_SCORE', displayName: 'BW Spotify Passion Score', type: 'number',format :'number'},
      { key: 'BW_PCT_US_ORGANIC_STREAMS', displayName: 'BW Percentage US Organic Streams', type: 'number', format :'percentChange'},
      
      { key: 'LW_SPOTIFY_PASSION_SCORE', displayName: 'LW Passion Score', type: 'number',format :'number'},
      { key: 'LW_SPOTIFY_SKIP_RATE', displayName: 'LW Skip Rate', type: 'number',format :'percentChange'},
      
      { key: 'LW_US_ORGANIC_STREAMS', displayName: 'LW US Organic Streams', type: 'number', format :'number'},
      { key: 'LW_PCT_US_ORGANIC_STREAMS', displayName: 'LW Percentage US Organic Streams', type: 'number', format :'percentChange'},
      

      { key: 'FIRST_FRIDAY_STREAM_COUNT', displayName: 'First Friday Streams', type: 'number',format :'number'},
      { key: 'FIRST_FRIDAY_ORGANIC_STREAMS', displayName: 'First Friday Organic Streams', type: 'number',format :'number'},
      { key: 'FIRST_FRIDAY_PCT_ORGANIC_STREAMS', displayName: 'First Friday % Organic', type: 'number',format :'number'},
      { key: 'FIRST_FRIDAY_PASSION_SCORE', displayName: 'First Friday Passion Score', type: 'number',format :'number'},
      { key: 'FIRST_FRIDAY_SKIP_RATE', displayName: 'First Friday Skip Rate', type: 'number',format :'number'},

      { key: 'THREE_DAY_STREAM_COUNT', displayName: 'Three Day Streams', type: 'number',format :'number'},
      { key: 'THREE_DAY_ORGANIC_STREAMS', displayName: 'Three Day Organic Streams', type: 'number',format :'number'},
      { key: 'THREE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Three Day % Organic', type: 'number',format :'percentChange'},
      { key: 'THREE_DAY_PASSION_SCORE', displayName: 'Three Day Passion Score', type: 'number',format :'number'},
      { key: 'THREE_DAY_SKIP_RATE', displayName: 'Three Day Skip Rate', type: 'number',format :'number'},

      { key: 'FIVE_DAY_STREAM_COUNT', displayName: 'Five Day Streams', type: 'number',format :'number'},
      { key: 'FIVE_DAY_ORGANIC_STREAMS', displayName: 'Five Day Organic Streams', type: 'number',format :'number'},
      { key: 'FIVE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Five Day % Organic', type: 'number',format :'percentChange'},
      { key: 'FIVE_DAY_PASSION_SCORE', displayName: 'Five Day Passion Score', type: 'number',format :'number'},
      { key: 'FIVE_DAY_SKIP_RATE', displayName: 'Five Day Skip Rate', type: 'number',format :'number'},
      

      { key: 'FIVE_DAY_STREAM_COUNT', displayName: 'Five Day Streams', type: 'number',format :'number'},
      { key: 'FIVE_DAY_ORGANIC_STREAMS', displayName: 'Five Day Organic Streams', type: 'number',format :'number'},
      { key: 'FIVE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Five Day % Organic', type: 'number',format :'percentChange'},
      { key: 'FIVE_DAY_PASSION_SCORE', displayName: 'Five Day Passion Score', type: 'number',format :'number'},
      { key: 'FIVE_DAY_SKIP_RATE', displayName: 'Five Day Skip Rate', type: 'number',format :'number'},

      { key: 'FIRST_WEEK_STREAM_COUNT', displayName: 'First Week Streams', type: 'number',format :'number'},
      { key: 'FIRST_WEEK_ORGANIC_STREAMS', displayName: 'First Week Organic Streams', type: 'number',format :'number'},
      { key: 'FIRST_WEEK_PCT_ORGANIC_STREAMS', displayName: 'First Week % Organic', type: 'number',format :'percentChange'},
      { key: 'FIRST_WEEK_PASSION_SCORE', displayName: 'First Week Passion Score', type: 'number',format :'number'},
      { key: 'FIRST_WEEK_SKIP_RATE', displayName: 'First Week Skip Rate', type: 'number',format :'percentChange'},


      


      { key: 'YTD', displayName: 'YTD', type: 'number',format :'number'},
      { key: 'ATD', displayName: 'ATD', type: 'number',format :'number'},
      { key: 'RELEASE_DATE', displayName: 'Release Date', type: 'date'},
      
    ];
    return (
      
      <div>
          <TableComponent columns ={columns} table_dir= {table_dir} endpoint={endpoint} song_table={true} 
          ExpandedRowComp={ExpandedRow} id_column = 'FAMILY_ID' pageSize = {100} default_sort_column_key= {'WEEK_0'} default_sort_order={'desc'}/>
      </div>
    );
  }
}

export default App;


