import React, { Component } from 'react';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';
import {
  TextField, Checkbox, Table,
  TableHead, TableBody, TableRow, TableCell, IconButton,
  TablePagination, Button, Menu, MenuItem, Typography,
  FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';

class OptionsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
    //   loading: true,
      options: {},
      filteredOptions: {},
      searchTerm: '',
    };
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    // Simulate fetching options from an API
    // this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Update the state when selectedOptions prop changes
    // if (this.props.selectedOptions !== prevProps.selectedOptions) {
    //   this.setState({ selectedOptions: this.props.selectedOptions });
    // }
  }

//   fetchData() {
//     console.log('fetching data');
//     const { column } = this.props;

//     const queryParams = new URLSearchParams();

//     if (column) {
//       queryParams.append('key', JSON.stringify(column.key));
//       queryParams.append('optionsMatchingKey', JSON.stringify(column.optionsMatchingKey));
//       queryParams.append('table', JSON.stringify(column.table));
//     }

//     const queryString = queryParams.toString();
//     const url = `${endpoint}/column_options?${queryString}`;

//     const { authState } = this.props;
//     const accessToken = authState.accessToken.accessToken;
//     const headers = {
//       Authorization: `Bearer ${accessToken}`,
//     };

//     return new Promise((resolve, reject) => {
//       fetch(url, {
//         headers: headers,
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           const dictionary = {};
//           data.forEach((item) => {
//             const key = item[column.key];
//             const value = item[column.optionsMatchingKey];
//             dictionary[key] = value;
//           });
//           console.log(dictionary);
//           this.setState({
//             options: dictionary,
//             filteredOptions: { ...dictionary }, // Initialize filteredOptions with all keys
//             loading: false,
//           });
//           console.log('Done loading data');
//         })
//         .catch((error) => {
//           console.error('Error fetching data:', error);
//         });
//     });
//   }

  handleSearchChange = (e) => {
    console.log('yo')
    const searchTerm = e.target.value.toLowerCase();
    const filteredOptions = {};
    Object.keys(this.state.options).forEach((key) => {
      if (key.toLowerCase().includes(searchTerm)) {
        filteredOptions[key] = this.state.options[key];
      }
    });
    this.setState({ searchTerm, filteredOptions });
  };


  handleApplyFilter = () => {
    // You can customize this method based on your needs
    const { column, handleFilterChange, popupState} = this.props;
    const { searchTerm } = this.state;
    console.log(searchTerm)
    // You might need to adjust the operator based on your requirements
    const operator = 'search';

    // Call the handleFilterChange method to apply the filter
    handleFilterChange(null, column.key, column.type, operator, searchTerm, column.optionsMatchingKey,  popupState);
  };
  
  handleToggleAllOptions = () => {
    // Toggle the visibility of all selected options
    this.setState((prevState) => ({
      showAllOptions: !prevState.showAllOptions,
    }));
  };

  handleKeyDown = (e) => {
    // Prevent focusing on buttons when certain keys are pressed
    console.log(e.key)
    if (e.key === "a" || e.key === "s") {
      // e.preventDefault();
      e.stopPropagation();
    }
  };
  

  render() {
    const {showAllOptions,searchTerm } = this.state;

    console.log(searchTerm)

    return (
      <div style={{ height: '50px',width:'200px', overflow: 'hidden', padding: '10px', paddingBottom:'14px' }}>
       <form>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => 
              {console.log('wtf')
                this.handleSearchChange(e)
                // e.stopPropagation()
                // e.preventDefault()
                
              }}
              onKeyDown={(e) => {
                console.log('ds');
                this.handleKeyDown(e); // Add parentheses to call the method
              }}
          style={{ width: '100%', boxSizing: 'border-box', padding: '8px' }}
          autoFocus
        />
        
        {/* <button onClick={this.handleApplyFilter}>Apply</button> */}
        <Button
          onClick={(event) => {
            event.stopPropagation();
            this.handleApplyFilter();
          }}
          key={'0000'}
          tabindex="-1"
          variant="contained"
          color="primary"
          style={{ fontSize: '12px', padding: '2px 4px' }}

        >
          Apply
        </Button>
        
        </form>
      </div>
    );
  }
}

export default withOktaAuth(OptionsSelector);
