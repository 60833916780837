import React, { Component } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';


import {
    TextField, Checkbox, Table,
    TableHead, TableBody, TableRow, TableCell, IconButton,
    TablePagination, Button, Menu, MenuItem, Typography,
    FormControl, InputLabel, Select, ClickAwayListener
  } from '@material-ui/core';
import OptionsSelector from './OptionsSelector';
import SearchSelector from './SearchSelector';
import NumberFilter from './NumberFilter'
class TableHeaderColumn extends Component {


  render() {
    const { column, index, handleSort, sortColumn, sortOrder,handleFilterChange, filters, table_dir } = this.props;

    let existingFilters = filters.filter((filter) => filter.column === column.key);

    if (column.key === 'ARTIST') {
        console.log(filters)
        console.log(column.key)
        console.log(existingFilters)
    }
    // if (existingFilters?.length > 0) {
    //     console.log(existingFilters)
    // }
    const pastWeek = new Date();
    pastWeek.setDate(pastWeek.getDate() - 8);
    let pastWeekValue = pastWeek.toISOString().split('T')[0];

    const pastMonth = new Date();
    pastMonth.setMonth(pastMonth.getMonth() - 1);
    let pastMonthValue = pastMonth.toISOString().split('T')[0];
    return (
      <TableCell
        key={column.key}
        onClick={() => handleSort(column)}
        className={column.fix ? 'pinned-column' : ''}
        style={{
          fontWeight: 'bold',
          position: column.fix ? 'sticky' : 'sticky',
          left: column.fix ? `${index * 150}px` : 'auto',
          padding: column.fix ? 'auto' : 'auto',
          zIndex: column.fix ? 10 : 9,
          background: column.fix ? '#fff' : '#fff',
          width: column.fix ? '118px' : '100px', // Set the width for pinned columns
          gridColumn: `col-${column.key}`,
          cursor: 'pointer',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ paddingRight: '12px' }}>{column.displayName}</div>
          <div style={{ position: 'absolute', right: '2px', marginRight: '8px', marginTop: '8px', bottom: '30px' }}>
            {sortColumn === column.key && sortOrder === 'asc' && '▲'}
            {sortColumn === column.key && sortOrder === 'desc' && '▼'}
          </div>
          {(column.format === 'number' || column.format === 'percentChange') && !column.no_filter && (
            <PopupState variant="popover" popupId={`popup-menu-${column.key}`}>
              {(popupState) => (
                <div onClick={(event) => {
                    console.log('Container click', event);
                    event.stopPropagation();
                    event.preventDefault();
                    // popupState.onClickAway(event);
                  }}
                >
                <React.Fragment>
                 <FilterListIcon
        fontSize="small"
        onClick={(event) => {
            console.log(event)
          
          event.stopPropagation();
            event.preventDefault();
            bindTrigger(popupState).onClick(event);
        }}
        onClickAway= {(event) => {
            console.log(event)
            event.stopPropagation();
            event.preventDefault();
            popupState.onClickAway(event);
            
          }}
        onClose={(event) => {
            event.stopPropagation();
            event.preventDefault();
            popupState.onClickAway(event);
            
          }}
        style={{ marginLeft: '8px', paddingRight: '8px', color: '#888', cursor: 'pointer' }}
      />
      <Menu
        {...bindMenu(popupState)}
      >
                    <NumberFilter 
                    existingFilters={existingFilters}
                    handleFilterChange={handleFilterChange}
                    column={column}
                    popupState={popupState}

                    />
                  </Menu>
                </React.Fragment>
                </div>
              )}
              
            </PopupState>
            
          )}
          {column.type === 'category' && (
            <PopupState variant="popover" popupId={`popup-menu-${column.key}`}>
            {(popupState) => (
              <div onClick={(event) => {
                  console.log('Container click', event);
                  event.stopPropagation();
                  event.preventDefault();
                  // popupState.onClickAway(event);
                }}
              >
              <React.Fragment>
                <FilterListIcon
                  fontSize="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    bindTrigger(popupState).onClick(event);
                  }}
                  onClickAway={(event) => {
                    event.stopPropagation();
                    popupState.onClickAway(event);
                  }}
                  style={{ marginLeft: '8px', paddingRight: '8px', color: '#888' }}
                />
                <Menu {...bindMenu(popupState)}>
                    <OptionsSelector column={column} table_dir={table_dir} handleFilterChange={handleFilterChange} selectedOptions={existingFilters.find((v) => v.operator === 'in')?.value || []} popupState={popupState}/>
                    </Menu>
                </React.Fragment>
                </div>
              )}
            </PopupState>
          )}
          {column.type === 'date' && (
            <PopupState variant="popover" popupId={`popup-menu-${column.key}`}>
              {(popupState) => (
                <div onClick={(event) => {
                    console.log('Container click', event);
                    event.stopPropagation();
                    event.preventDefault();
                    // popupState.onClickAway(event);
                  }}
                >
                <React.Fragment>
                  <FilterListIcon
                    fontSize="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      bindTrigger(popupState).onClick(event);
                    }}
                    onClickAway={(event) => {
                      event.stopPropagation();
                      popupState.onClickAway(event);
                    }}
                    style={{ marginLeft: '8px', paddingRight: '8px', color: '#888' }}
                  />
                  <Menu {...bindMenu(popupState)}>
                  <div style={{ fontSize: '12px' }}>
                                    <div>
                                      <TextField
                                        value= {existingFilters.find((v) => v.operator === '<')?.value || null}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true, // This ensures that the label doesn't overlap with the input value
                                          }}
                                        label="Less Than"
                                        placeholder="Less Than"
                                        inputProps={{ operator: '<' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => handleFilterChange(event, column.key, column.type, '<')}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        value= {existingFilters.find((v) => v.operator === '>')?.value || null}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true, // This ensures that the label doesn't overlap with the input value
                                          }}
                                        label="Greater Than"
                                        placeholder="Greater Than"
                                        inputProps={{ operator: '>' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => handleFilterChange(event, column.key, column.type, '>')}
                                      />
                                    </div>
                                    <div>
                                      <TextField
                                        value= {existingFilters.find((v) => v.operator === '=')?.value || null}
                                        type="date"
                                        placeholder="Equal To"
                                        InputLabelProps={{
                                            shrink: true, // This ensures that the label doesn't overlap with the input value
                                          }}
                                        label="Equal To"
                                        inputProps={{ operator: '=' }}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => handleFilterChange(event, column.key, column.type, '=')}
                                      />
                                    </div>
                                    <div>
                                      <Button
                                        
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleFilterChange(event, column.key, column.type, 'past week');
                                        }}
                                        variant={(existingFilters.find((v) => v.operator === '>') && existingFilters.find((v) => v.operator === '>').value == pastWeekValue)? "contained" : "outlined"}
                                        color='primary'
                                      >
                                        Past Week
                                      </Button>
                                    </div>
                                    <div>
                                      <Button
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleFilterChange(event, column.key, column.type, 'past month');
                                        }}
                                        variant={(existingFilters.find((v) => v.operator === '>') && existingFilters.find((v) => v.operator === '>').value == pastMonthValue)? "contained" : "outlined"}
                                        color="primary"
                                      >
                                        Past Month
                                      </Button>
                                    </div>
                                  </div>
                  </Menu>
                </React.Fragment>
                </div>
              )}
            </PopupState>
          )}
          {column.type === 'string' && (
            <PopupState variant="popover" popupId={`popup-menu-${column.key}`}>
            {(popupState) => (
              <div onClick={(event) => {
                  console.log('Container click', event);
                  event.stopPropagation();
                  event.preventDefault();
                  // popupState.onClickAway(event);
                }}
              >
              <React.Fragment>
                <FilterListIcon
                  fontSize="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    bindTrigger(popupState).onClick(event);
                  }}
                  onClickAway={(event) => {
                    event.stopPropagation();
                    popupState.onClickAway(event);
                  }}
                  style={{ marginLeft: '8px', paddingRight: '8px', color: '#888' }}
                />
                <Menu {...bindMenu(popupState)}>
                    <SearchSelector column={column} table_dir={table_dir} handleFilterChange={handleFilterChange} popupState={popupState} />
                    </Menu>
                </React.Fragment>
                </div>
              )}
            </PopupState>
          )}
        </div>
      </TableCell>
    );
  }
}

export default TableHeaderColumn;
