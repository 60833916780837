import React, { Component } from 'react';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';
import {
  TextField, Checkbox, Table,
  TableHead, TableBody, TableRow, TableCell, IconButton,
  TablePagination, Button, Menu, MenuItem, Typography,
  FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';

class OptionsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      options: {},
      filteredOptions: {},
      searchTerm: '',
      showAllOptions: true,
      selectedOptions: this.props.selectedOptions || [],
      
    };
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    // Simulate fetching options from an API
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Update the state when selectedOptions prop changes
    if (this.props.selectedOptions !== prevProps.selectedOptions) {
      this.setState({ selectedOptions: this.props.selectedOptions });
    }
  }

  fetchData() {
    console.log('fetching data');
    const { column } = this.props;

    const queryParams = new URLSearchParams();

    if (column) {
      queryParams.append('key', JSON.stringify(column.key));
      queryParams.append('optionsMatchingKey', JSON.stringify(column.optionsMatchingKey));
      queryParams.append('table', JSON.stringify(column.table));
    }

    const queryString = queryParams.toString();
    const url = `${endpoint}/column_options?${queryString}`;

    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          const dictionary = {};
          data.forEach((item) => {
            const key = item[column.key];
            const value = item[column.optionsMatchingKey];
            dictionary[key] = value;
          });
          console.log(dictionary);
          this.setState({
            options: dictionary,
            filteredOptions: { ...dictionary }, // Initialize filteredOptions with all keys
            loading: false,
          });
          console.log('Done loading data');
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });
  }

  handleSearchChange = (e) => {
    console.log('yo')
    const searchTerm = e.target.value.toLowerCase();
    const filteredOptions = {};
    Object.keys(this.state.options).forEach((key) => {
      if (key.toLowerCase().includes(searchTerm)) {
        filteredOptions[key] = this.state.options[key];
      }
    });
    this.setState({ searchTerm, filteredOptions });
  };

  handleCheckboxChange = (key) => {
    // Toggle the selected state for the checkbox
    const selectedOptions = this.state.selectedOptions.includes(key)
      ? this.state.selectedOptions.filter((selectedKey) => selectedKey !== key)
      : [...this.state.selectedOptions, key];
  
    // Update the state with the new selected options
    this.setState({ selectedOptions }, () => {
      // The callback function is invoked after the state has been updated
      console.log(this.state.selectedOptions);
  
      // Pass the updated selected options to the parent component using a callback
      // this.props.onSelectedOptionsChange(selectedOptions);
    });
  };

  handleApplyFilter = () => {
    // You can customize this method based on your needs
    const { column,handleFilterChange, popupState} = this.props;
    const { selectedOptions } = this.state;

    // You might need to adjust the operator based on your requirements
    const operator = 'in';

    // Call the handleFilterChange method to apply the filter
    handleFilterChange(null, column.key, column.type, null, Object.values(this.state.selectedOptions), column.optionsMatchingKey, popupState);
  };
  
  handleToggleAllOptions = () => {
    // Toggle the visibility of all selected options
    this.setState((prevState) => ({
      showAllOptions: !prevState.showAllOptions,
    }));
  };

  handleKeyDown = (e) => {
    // Prevent focusing on buttons when certain keys are pressed
    console.log(e.key)
    if (e.key.toLowerCase() === "a" || e.key.toLowerCase() === "s") {
      // e.preventDefault();
      e.stopPropagation();
    }
  };
  

  render() {
    const { selectedOptions, loading, showAllOptions,searchTerm } = this.state;
    console.log(selectedOptions);
    if (loading) {
      // Display a loading symbol while data is being fetched
      return <div>Loading...</div>;
    }
    console.log(searchTerm)
    
    const optionsToDisplay = showAllOptions
      ? Object.entries(this.state.filteredOptions)
      : Object.entries(this.state.filteredOptions).filter(([key, value]) =>
          selectedOptions.includes(value)
        );

    return (
      <div style={{ height: '200px', overflow: 'hidden', padding: '10px', paddingBottom:'14px' }}>
       <form>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => 
              {console.log('wtf')
                this.handleSearchChange(e)
                // e.stopPropagation()
                // e.preventDefault()
                
              }}
              onKeyDown={(e) => {
                console.log('ds');
                this.handleKeyDown(e); // Add parentheses to call the method
              }}
          style={{ width: '100%', boxSizing: 'border-box', padding: '8px' }}
          autoFocus
        />
        <div style={{ maxHeight: '150px', overflowY: 'auto', borderTop: '1px solid #ccc' }}>
          {optionsToDisplay.map(([key, value]) => (
            <div key={Math.random()} style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
              <label>
                <input
                  type="checkbox"
                  value={Math.random()}
                  checked={selectedOptions.includes(value)}
                  onChange={(evt) => {
                    // const box = evt.target.checked
                    // this.setState([box]: evt.target.checked);
                    // console.log(box)
                    this.handleCheckboxChange(value);
                  }}
                />{' '}
                {`${key}`}
              </label>
            </div>
          ))}
        </div>
        {/* <button onClick={this.handleApplyFilter}>Apply</button> */}
        <Button
          onClick={(event) => {
            event.stopPropagation();
            this.handleApplyFilter();
          }}
          key={'0000'}
          tabindex="-1"
          variant="contained"
          color="primary"
          style={{ fontSize: '12px', padding: '2px 4px' }}

        >
          Apply
        </Button>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            this.handleToggleAllOptions();
          }}
          variant={!showAllOptions? "contained" : "outlined"}
          color="default"
          style={{ fontSize: '12px', padding: '2px 4px' }}

        >
          Show Seleted Options
        </Button>
        </form>
      </div>
    );
  }
}

export default withOktaAuth(OptionsSelector);
