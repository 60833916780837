import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';
const styles = {
  graphContainer: {
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
  },
};

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { sub_endpoint, properties} = this.props;
    const { artistId } = this.props;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const queryParams = new URLSearchParams();
    // Add any other necessary props for the API request
    console.log(properties)
    for (const key in properties) {
        if (properties.hasOwnProperty(key)) {
          const value = properties[key];
          queryParams.append(key, value);
        }
      }
    const queryString = queryParams.toString();
    // Make an API request using the provided endpoint
    fetch(`${endpoint}/${sub_endpoint}?${queryString}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
      // Add any other necessary headers or parameters
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is in the expected format, modify as needed
        console.log(data)
        this.setState({
          data,
          loading: false,
        });
        if (this.props.setDataComponent){
            this.props.setDataComponent(this.props.parent_key,this.props.key1, data)
            // this.handleChartExport()
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { data, loading } = this.state;
    console.log(data)
    const config = {
      chart: {
        type: 'pie',
        height: 370,
        backgroundColor: this.props.backgroundColor,
      },
      title: {
        text: this.props.title,
      },
      series: [
        {
          name: 'Categories',
          colorByPoint: true,
          data: data && data.length > 0 ? data.map((item) => ({
            name: item.REGION, // Replace with the actual field in your data
            y: item.STREAM_COUNT, // Replace with the actual field in your data
          })) : [], 
        },
      ],
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
          },
        },
      },
    };

    return (
      <div style={styles.graphContainer}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={config} />
        )}
      </div>
    );
  }
}

export default withOktaAuth(PieChart);
