import React, { Component } from 'react';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import LinePlot from './LinePlot';
import ScatterPlot from './ScatterPlot';
import TableComponent from './TableComponent';

class ExpandedRow extends Component {
  state = {
    activeTab: 0,
  };

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  render() {
    const { activeTab } = this.state;
    const { endpoint,row,id_column } = this.props;
    const columns = [
      { key: 'ARTIST', id: 'ARTIST', displayName: 'ARTIST', type: 'string' , fix: true},
    //   { key: 'CACHED_LABEL_CATEGORY', displayName: 'LABEL CATEGORY', type: 'string', fix: true},
      { key: 'TOTAL_TW_STREAMS', displayName: 'This Week Streams', type: 'number', format :'number', no_filter: true},
      { key: 'TOTAL_LW_STREAMS', displayName: 'Last Week Streams', type: 'number', format :'number', no_filter: true},
      { key: 'TOTAL_YTD_STREAMS', displayName: 'YTD', type: 'number',format :'number', no_filter: true},
      { key: 'TOTAL_ATD_STREAMS', displayName: 'ATD', type: 'number',format :'number', no_filter: true},
      
    ];
    const table_dir = 'genre_table'

    console.log(row)
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        {/* Tabs */}
        <Tabs value={activeTab} onChange={this.handleTabChange} orientation="vertical">
          <Tab label="Stream Details" />
          <Tab label="Artists" />
          <Tab label="Tab 3" />
        </Tabs>

        {/* Content */}
        <div style={{ flex: 1, marginLeft: '16px', width: '100%' }}>
          <TabPanel value={activeTab} index={0}>
            <LinePlot endpoint = {endpoint} table_dir= {'label_historical'} row = {row} id_column={id_column} selectedColumnsLeft={['MRC_STREAMS']} selectedColumnsRight={[]}></LinePlot>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
              <TableComponent columns ={columns} table_dir={'label_artists'}endpoint={endpoint} ExpandedRowComp={null} id_column = {null} filter_col = {id_column} filter_value = {row[id_column]} pageSize = {5} default_sort_column_key= {'TOTAL_TW_STREAMS'}/>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Typography>Content for Tab 3</Typography>
          </TabPanel>
        </div>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default ExpandedRow;
