import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';

class ColumnSettings extends Component {
  constructor(props) {
    super(props);
    const { columns } = this.props;
    const filteredColumns = columns.filter(
      (column) => !column.fix && !column.alwaysHide
    );
    const columnHideSettings = filteredColumns.map((column) => ({
      ...column,
      hide: column.hide,
    }));
    this.state = {
      columnHideSettings: columnHideSettings,
      showAllColumns: true
    };
  }

  handleCheckboxChange = (key) => {
    // Toggle the hide state for the column with the given key
    this.setState((prevState) => {
      const updatedColumnSettings = prevState.columnHideSettings.map((setting) => {
        if (setting.key === key) {
          // Toggle the hide setting for the found column
          return {
            ...setting,
            hide: !setting.hide,
          };
        }
        return setting;
      });

      return {
        columnHideSettings: updatedColumnSettings,
      };
    });
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedColumnSettings = [...this.state.columnHideSettings];
    const [reorderedColumn] = updatedColumnSettings.splice(result.source.index, 1);
    updatedColumnSettings.splice(result.destination.index, 0, reorderedColumn);

    this.setState({ columnHideSettings: updatedColumnSettings });
  };

  hideAll = () => {
    const columnHideSettings = this.state.columnHideSettings.map((column) => ({
      ...column,
      hide: true
    }));
    this.setState({ columnHideSettings: columnHideSettings });

  };

  render() {
    const { handleApplyColumnSettings, closeDialog } = this.props;
    const { columnHideSettings } = this.state;
    let shownColumns = []
    if (this.state.showAllColumns){
      shownColumns = this.state.columnHideSettings
    } else {
      shownColumns = this.state.columnHideSettings.filter((column) => !column.hide);


    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="columns">
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '600px',
              }}
            >
              <div
                style={{
                  maxHeight: '600px',
                  overflowY: 'auto',
                  paddingBottom: '15px',
                }}
              >
                {shownColumns.map((column, index) => (
                  <Draggable key={column.key} draggableId={column.key} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <label>
                          <input
                            type="checkbox"
                            checked={!column.hide}
                            onChange={() => this.handleCheckboxChange(column.key)}
                          />
                          {column.displayName}
                        </label>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'sticky',
                  bottom: '0',
                  backgroundColor: 'white',
                  padding: '10px',
                  boxShadow: '0px -5px 5px -5px rgba(0, 0, 0, 0.2)',
                  zIndex: 1,
                }}
              >
                <Button
                  onClick={this.hideAll}
                  variant="contained"
                  color="default"
                  style={{ fontSize: '12px', padding: '2px 4px' }}
                >
                  Deselect All Columns
                </Button>
                <Button
                  onClick={() => {
                    handleApplyColumnSettings(columnHideSettings);
                    closeDialog();
                  }}
                  variant="contained"
                  color="primary"
                  style={{ fontSize: '12px', padding: '2px 4px' }}
                >
                  Apply Settings
                </Button>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default ColumnSettings;



