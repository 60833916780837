export const formatValue = (value, type, format) => {
    if (value === null) {
      return '';
    }
  
    if (type === 'number') {
      if (format === 'literal') {
        return value || ''; // If value is undefined, return an empty string
      } else if (format === 'percentChange') {
        const roundedValue = Math.round(value * 10) / 10;
        if (format === 'percentChangeWplus' && roundedValue >= 0) {
            console.log(`+${(roundedValue || 0).toFixed(1)}%`)
            return `+${(roundedValue || 0).toFixed(1)}%`; // Add + sign for positive percent change
          } else {
            console.log(`${(roundedValue || 0).toFixed(1)}%`)
            return `${(roundedValue || 0).toFixed(1)}%`; // If value is undefined, return '0%'
          }          
      } else if (format === 'number') {
        const absValue = Math.abs(value);
        if (absValue >= 1e9) {
          return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
        } else if (absValue >= 1e6) {
          return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
        } else if (absValue >= 1e3) {
          return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
        } else {
          return value || ''; // If value is undefined, return an empty string
        }
      }
    } else if (type === 'date') {
      const formattedDate = new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return formattedDate;
    }
    return value;
  };

  export const formatColor = (value) => {
    if (value > 0) {
      return 'green'; // Positive value, color it green
    } else if (value < 0) {
      return 'red'; // Negative value, color it red
    } else {
      return 'black'; // Zero value, you can choose another color or leave it as black
    }
  };