// LoginPage.js
import React from 'react';
import { withOktaAuth } from '@okta/okta-react';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }
  componentDidMount() {
    const { authState, oktaAuth } = this.props;
    console.log(authState)
    console.log(oktaAuth)
  }

  async login() {
    console.log('aa')
    const { authState, oktaAuth } = this.props;
    console.log(authState)
    oktaAuth.signInWithRedirect();
    console.log(authState)
    console.log('done')
  }

  render() {
    return (
      <div>
        <h2>Login Page</h2>
        <button onClick={this.login}>Login</button>
      </div>
    );
  }
}

export default withOktaAuth(LoginPage);
