import React, { Component } from 'react';
import { Button, Menu, MenuItem, RadioGroup, FormControlLabel, Radio, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ReactDOMServer from 'react-dom/server';
import { withOktaAuth } from '@okta/okta-react';
import {endpoint} from './mainEndpoint';
import { formatValue, formatColor } from './Format'; // Assuming Format.js is in the same directory

class ExportData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table_dir: this.props.table_dir,
            columns: this.props.columns,
            filters: this.props.filters,
            selectedFolder:this.props.selectedFolder? this.props.selectedFolder : -1,
            sort: this.props.sort,
            isOptionsDialogOpen: false,
            isEmailPreviewOpen: false,
            emailHtml: '',
            buildingWeekOption: 'Building Week',
            emailAddresses: '',
        };
    }

    

    generateCSVContent = (data) => {
        const { columns } = this.props;
        const headerRow = columns.map((column) => column.displayName);
    
        const formattedData = data.map((row) =>
          columns.map((column) => {
            const formattedValue = this.formatValue(row[column.key], column.type, column.format);
            if (column.type === 'number' || column.type === 'percentChange') {
              return row[column.key]
            }
            return (column.type === 'string' || column.type === 'date') ? `"${formattedValue.replace(/"/g, '""')}"` : formattedValue;
    
          })
        );
    
        const csvRows = [headerRow, ...formattedData];
        const csvContent = csvRows.map((row) => row.join(',')).join('\n');
        return csvContent;
      };

    exportDataToCSV = () => {
        // Perform the data export request with the updated filters
        const {data} = this.props
        
        // Generate the CSV content
        const csvContent = this.generateCSVContent(data);

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
        downloadLink.setAttribute('download', 'data.csv');
        downloadLink.click();

        // Clean up the temporary anchor element
        URL.revokeObjectURL(downloadLink.href);
            
    };


    openOptionsDialog = () => {
        this.setState({ isOptionsDialogOpen: true });
    }

    closeOptionsDialog = () => {
        this.setState({ isOptionsDialogOpen: false });
    }

    openEmailPreview = () => {
        this.generateEmailHtml();
        this.setState({
            isEmailPreviewOpen: true,
            isOptionsDialogOpen: false, // Close the options dialog when opening email preview
        });
    }

    closeEmailPreview = () => {
        this.setState({ isEmailPreviewOpen: false });
    };

    selectOption = (option) => {
        this.setState({ buildingWeekOption: option });
      }
      formatValue = (value, type, format) => {
        if (value === null) {
          return '';
        }
    
        if (type === 'number') {
          if (format === 'literal') {
            return value || ''; // If value is undefined, return an empty string
          } else if (format === 'percentChange') {
            const roundedValue = Math.round(value * 10) / 10;
            return (roundedValue || 0).toFixed(1) + '%'; // If value is undefined, return '0%'
          } else if (format === 'number') {
            const absValue = Math.abs(value);
            if (absValue >= 1e9) {
              return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
            } else if (absValue >= 1e6) {
              return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
            } else if (absValue >= 1e3) {
              return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
            } else {
              return value || ''; // If value is undefined, return an empty string
            }
          }
        } else if (type === 'date') {
          const formattedDate = new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          return formattedDate;
        }
        return value;
      };
    generateEmailHtml = async () => {
        const {buildingWeekOption } = this.state;
        const {data,folders,selectedFolder} = this.props
        console.log(this.props.artistName)
        console.log(selectedFolder)
        console.log(data)
        console.log(folders)
        let folder_name = ''

        try {
            folder_name = folders.find(item => item.FOLDER_ID === selectedFolder)['FOLDER_NAME'];
            console.log(folder_name)
        } catch {
            folder_name = ''
        }
        // console.log(data)
        const componentDivs =await this.generateAllComponentDivs(data);
        console.log(componentDivs)
        const songsComponent = data.find(item => item.componentType === 'Songs');
        console.log(songsComponent)
        console.log(songsComponent.info)
        // Generate the email HTML using React components
        const emailHtml = ReactDOMServer.renderToStaticMarkup(
            <html>
                <head>
                    <meta charset="UTF-8" />
                    <title>Email Template</title>
                    <style>
                        {`
    .box1 {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
      }
      .box {
        margin-right: 10px; /* Remove margin for smaller screens */

      }

    @media (max-width: 700px) {
      .box {
        width: 100% !important;
        display:block !important;
        margin-right: 0; /* Remove margin for smaller screens */
      }
    }`}
                    </style>
                </head>
                <body>
    <div style={{flexDirection: 'column'}}>
        <div >
            {await this.generateAdditionalDivs(componentDivs)}
        </div>
        <div style={{marginTop:'20px'}}>
            <h1>Top Songs</h1>
            {await this.generateSongs(songsComponent.info.data)}
        </div>
    </div>
</body>

            </html>
        );
        console.log(emailHtml)
        this.setState({ emailHtml });
    }

    generateSongs = (data) => {
        console.log(data)
        return (
            <div>
                <table
                    width="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                    style={{ maxWidth: '800px', margin: '0 auto' }}
                >
                            {data.slice(0, 30).map((item, index) => (
                                <div>
                                    <tr style={{}}>
                                        <td align="center" style={{
                                            margin: '2px',
                                            borderBottom: '10px',
                                            backgroundColor: 'rgba(240, 240, 240, 0.25)', // Adjust the alpha value as needed
                                            padding: '10px',

                                        }}>
                                            <h3 style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold', marginTop: '2px', marginBottom: '4px' }}>
                                                {item.ARTIST} - {item.TITLE}
                                            </h3>
                                            <h4 style={{ textAlign: 'left', color: '#777', fontSize: '14px', margin: '4px' }}>Released: {this.formatValue(item.RELEASE_DATE, 'date', 'date')}</h4>
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <tr>
                                                    <td className="box" style={{ width: '45%' }}>
                                                        <div
                                                            style={{
                                                                borderRadius: '10px',
                                                                backgroundColor: '#f0f0f0',
                                                                margin: '5px',
                                                                padding: '5px',
                                                                height: '110px', // Adjust the height as needed
                                                            }}
                                                        >
                                                            <h4 style={{ textAlign: 'left', fontSize: '14px', margin: '0px' }}>Streaming </h4>
                                                            <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'space-between', margin: '4px' }}>
                                                                <div style={{ width: '60%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Global This Week: {this.formatValue(item.GLOBAL_WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.GLOBAL_WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.GLOBAL_WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US This Week: {this.formatValue(item.WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US Last Week: {this.formatValue(item.WEEK_2, 'number', 'number')}{this.getArrowSymbol(item.WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.WEEK_2_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    {/* <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Global Last Week: {this.formatValue(item.GLOBAL_WEEK_2, 'number', 'number')} {this.getArrowSymbol(item.GLOBAL_WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.GLOBAL_WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div> */}

                                                                </div>
                                                                <div style={{ textAlign: 'right', width: '40%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US YTD: {this.formatValue(item.YTD, 'number', 'number')}</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US ATD: {this.formatValue(item.ATD, 'number', 'number')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="box" style={{ width: '45%' }}>
                                                        <div
                                                            style={{
                                                                borderRadius: '10px',
                                                                backgroundColor: '#f0f0f0',
                                                                margin: '5px',
                                                                padding: '5px',
                                                                height: '110px', // Adjust the height as needed
                                                            }}
                                                        >
                                                            <h4 style={{ textAlign: 'left', fontSize: '14px', margin: '0px' }}>Equivalents </h4>
                                                            <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'space-between', margin: '4px' }}>
                                                                <div style={{ width: '60%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US This Week: {this.formatValue(item.SALES_WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.SALES_WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.SALES_WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US Last Week: {this.formatValue(item.SALES_WEEK_2, 'number', 'number')}{this.getArrowSymbol(item.SALES_WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.SALES_WEEK_2_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                </div>
                                                                <div style={{ textAlign: 'right', width: '40%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US YTD: {this.formatValue(item.SALES_YTD, 'number', 'number')}</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>US ATD: {this.formatValue(item.SALES_ATD, 'number', 'number')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </div>
                            ))}

                        </table>
                    </div>)
    }
    
    
      

      generateAdditionalDivs = async (componentDivs) => {
        let { data } = this.props;
        console.log(data);
        data = this.props.data.filter(item => item.componentType === "DataComponent" || item.componentType === "LinePlotSimple" || item.componentType === "PieChart");

        console.log(componentDivs);
        const divs = [];
      
        const resolvedComponentDivs = componentDivs;
        console.log(resolvedComponentDivs)
        return (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                zIndex: 1,
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <h1
                  style={{
                    margin: '0',
                    textAlign: 'center',
                    flex: '1',
                  }}
                >
                  {this.props.artistName}
                </h1>
              </div>
              <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                {data.map((item, index) => (
                  index % 2=== 0 && item.componentType !== 'Songs'  ? (
                    <tr key={index}>
                      <td className="box" style={{ width: '45%', height: '275px', marginRight:'10px'}}>
                      <div
    style={{
        borderRadius: '10px',
        backgroundColor: '#f0f0f0',
        margin: '5px',
        padding: '5px',
        width: '100%',
        height:'100%',
        position: 'relative',
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'table',  // Use display: table
        justifyContent: 'space-between',
        

    }}
>
    <div className="box1" style={{ display: 'table-cell', verticalAlign: 'middle' }}>

                            {item.componentType === 'DataComponent' ? (
                            resolvedComponentDivs[index]
                            // null
                            ) : item.componentType === 'LinePlotSimple' || item.componentType === "PieChart" ? (
                            resolvedComponentDivs[index]
                            // null
                            ) : null}
                            </div>
                        </div>
                        </td>

                      {index + 1 < data.length && (
                        <td className="box" style={{ width: '45%', height: '275px'}}>
                        <div
    style={{
        borderRadius: '10px',
        backgroundColor: '#f0f0f0',
        margin: '5px',
        padding: '5px',
        width: '100%',
        height:'100%',
        position: 'relative',
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'table',  // Use display: table
        justifyContent: 'space-between',
        

    }}
>
    <div className="box1" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            {data[index + 1].componentType === 'DataComponent' ? (
                              resolvedComponentDivs[index + 1]
                            // null
                            ) : data[index + 1].componentType === 'LinePlotSimple' || data[index + 1].componentType === "PieChart" ? (
                                resolvedComponentDivs[index +1]
                                // null
                            ) : null}
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ) : null
                ))}
              </table>
            </div>
          </div>
        );
      };

      

      generateLinePlot = async (props) => {
        const { authState } = this.props;
        const accessToken = authState.accessToken.accessToken;
      
        try {
          const response = await fetch(`${endpoint}/generate-chart-image`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              props: props, // Send chartOptions as a string
            }),
          });
          
      
          const result = await response.json();
      
          // Assuming the response contains the base64-encoded image data
          const { fileLink } = result;
      
          console.log(fileLink);
      
          // Return the <img> element
          return <img src={fileLink} alt="Chart"  style={{ maxWidth: '100%', maxHeight: '100%' }}/>;
        } catch (error) {
          console.error('Error generating chart image:', error);
          // Handle errors here
          return <span>Error generating chart</span>;
        }
      };
      generatePieChart = async (props) => {
        const { authState } = this.props;
        const accessToken = authState.accessToken.accessToken;
      
        try {
          const response = await fetch(`${endpoint}/generate-pie-chart-image`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              props: props, // Send chartOptions as a string
            }),
          });
      
          const result = await response.json();
      
          // Assuming the response contains the base64-encoded image data
          const { fileLink } = result;
      
          console.log(fileLink);
      
          // Return the <img> element
          return <img src={fileLink} alt="Chart"  style={{ maxWidth: '100%', maxHeight: '100%' }}/>;
        } catch (error) {
          console.error('Error generating chart image:', error);
          // Handle errors here
          return <span>Error generating chart</span>;
        }
      };

      generateAllComponentDivs = async (data) => {
        const componentDivs = [];
      
        for (const item of data) {
          if (item.componentType === 'DataComponent') {
            const componentDiv = await this.generateComponentDiv(item.info);
            componentDivs.push(componentDiv);
          } else if (item.componentType === 'LinePlotSimple') {
            const linePlot = await this.generateLinePlot(item.info);
            componentDivs.push(linePlot);
          } else if (item.componentType === 'PieChart') {
            const linePlot = await this.generatePieChart(item.info);
            componentDivs.push(linePlot);
          }
        }
      
        return Promise.all(componentDivs);
      };

      generateComponentDiv = (data1) => {
        return (
            <table width="100%" height="100%" border="0" cellSpacing="0" cellPadding="0" style={{ maxWidth: '100%', maxHeight: '100%'}}>
                <tbody style={{ maxWidth: '100%', maxHeight: '100%'}}>
            {data1.map((this_data, index) => {
              // Render a new tr for every two items
              const marginBottom = index + 1 < data1.length - 2 ? '20%' : '0';
          
              return index % 2 === 0 && (
                <tr key={index} style={{ display: 'flex',  marginTop: '10%', marginBottom: '10%'}}>
          
                  {/* Render first td */}
                  <td style={{ width: '45%' }}>
                    <div style={{ textAlign: 'center', flex: 1 }}>
                      <div className="follower-count" style={{ display: 'inline-block', margin: '0 auto' }}>
                        <p style={{ margin: 0, padding: 0, fontSize: '20px' }}>
                          <strong>{formatValue(this_data.data.value, 'number', this_data.value_format)}</strong>
                        </p>
                      </div>
                      <div className="follower-change" style={{ display: 'inline-block', margin: '0 auto' }}>
                        {this_data.data.percent_change !== null ? (
                          <p style={{
                            margin: 0,
                            padding: 0,
                            fontSize: '20px',
                          }}> (
                            <span style={{ color: formatColor(this_data.data.percent_change) }}>
                              {formatValue(this_data.data.percent_change, 'number', 'percentChangeWplus')}%
                            </span>
                          )</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="follower-label">
                      <p style={{
                        margin: 0,
                        padding: 0,
                        fontSize: '14px',
                      }}><span className="gray-text">{this_data.data.title}</span></p>
                    </div>
                  </td>
          
                  {/* Render second td */}
                  {index + 1 < data1.length && (
                    <td key={index + 1} style={{ width: '45%' }}>
                      <div style={{ textAlign: 'center', flex: 1 }}>
                        <div className="follower-count" style={{ display: 'inline-block', margin: '0 auto' }}>
                          <p style={{ margin: 0, padding: 0, fontSize: '20px' }}>
                            <strong>{formatValue(data1[index + 1].data.value, 'number', data1[index + 1].value_format)}</strong>
                          </p>
                        </div>
                        <div className="follower-change" style={{ display: 'inline-block', margin: '0 auto' }}>
                          {data1[index + 1].data.percent_change !== null ? (
                            <p style={{
                              margin: 0,
                              padding: 0,
                              fontSize: '20px',
                            }}> (
                              <span style={{ color: formatColor(data1[index + 1].data.percent_change) }}>
                                {formatValue(data1[index + 1].data.percent_change, 'number', 'percentChangeWplus')}%
                              </span>
                            )</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="follower-label">
                        <p style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '14px',
                        }}><span className="gray-text">{data1[index + 1].data.title}</span></p>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
            </tbody>
          </table>
          
        );
      };
      
      


    getArrowSymbol(change) {
        return change > 0 ? <span style={{ color: 'green' }}>&#8599;</span> : change < 0 ? <span style={{ color: 'red' }}>&#8600;</span> : '';
    }

    handleEmailAddressesChange = (event) => {
        this.setState({
            emailAddresses: event.target.value,
        });
    };
    sendEmail = async () => {
        const { emailHtml } = this.state;
    
        // Get the input value from the ref
        const emailAddresses = this.emailInput.value;
        const { authState } = this.props;
        const emailTitle= this.emailTitle.value;

        const accessToken = authState.accessToken.accessToken;
        try {
          const response = await fetch(`${endpoint}/send_sendgrid_email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
            },
            body: JSON.stringify({
              emailHtml,
              emailAddresses,
              emailTitle,
            }),
          });
    
          if (response.ok) {
            console.log('Email sent successfully');
            this.setState({ isEmailPreviewOpen: false }); // Close the email preview popup
            // No need to reset the emailAddresses state here
            this.setState({ isEmailSent: true }); // Set the flag to show the "Email Sent!" popup
          } else {
            console.error('Error sending email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
    render() {
        const { isOptionsDialogOpen, isEmailPreviewOpen, emailHtml, buildingWeekOption } = this.state;

        return (
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button variant="outlined" color="primary" onClick={this.openOptionsDialog} style={{  }}>
                            Export
                        </Button>
                        {/* <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                                <Button onClick={this.exportDataToCSV} variant="outlined" color="primary">
                                    CSV
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                                <Button onClick={this.openOptionsDialog} variant="outlined" color="primary">
                                    Email
                                </Button>
                            </MenuItem>
                        </Menu> */}
                        <Dialog open={isOptionsDialogOpen} onClose={this.closeOptionsDialog}>
                <DialogTitle>Select an Option</DialogTitle>
                <DialogContent>
                  <p>Confirm you would like to run an artist report?</p>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.openEmailPreview}>Confirm</Button>
                </DialogActions>
              </Dialog>
                        <Dialog
                open={isEmailPreviewOpen}
                onClose={this.closeEmailPreview}
                maxWidth="lg" // Set the maxWidth property to limit the width
              >
                <DialogTitle style={{ width: '80vw' }}>Email Preview</DialogTitle>
                <DialogContent style={{ height: '90vh' }}>
                  <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                </DialogContent>
                <DialogActions>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Title:
                    </Typography>
                    <TextField
                      label="Title"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter Email Title"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailTitle = input)} // Create a ref for the input
                    />
                  
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Send To:
                    </Typography>
                    <TextField
                      label="Emails"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter email addresses, comma-separated"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailInput = input)} // Create a ref for the input
                    />
                  </div>
                  <Button onClick={this.sendEmail}>Send Email</Button>
                </DialogActions>
              </Dialog>
                    </React.Fragment>
                )}
            </PopupState>
        );
    }
}

export default withOktaAuth(ExportData);
