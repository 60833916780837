import React, { Component } from 'react';
import DataComponent from './DataComponent'; // Import your DataComponent
// import './DataGroup.css'; // Import your CSS
import LinePlotSimple from './LinePlotSimple'
import ScatterPlot from './ScatterPlot.js';
import Table_All from './Table_All';
import PieChart from './PieChart';
import BarPlot from './BarPlot.js';
class DataGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataComponents: [], // Store DataComponent instances
      windowWidth: window.innerWidth,
      // Remove dataComponentRefs from state
    };
    this.dataComponentRefs = []; // Array to store refs for DataComponents
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleLogRefsClick = this.handleLogRefsClick.bind(this);
    this.getFinalHTMLList = this.getFinalHTMLList.bind(this);
  }

  componentDidMount() {
    // Create DataComponent instances and store them in state
    const { data } = this.props;
    console.log(data)
    // console.log('running')
    this.createDataComponents();
    window.addEventListener('resize', this.handleWindowResize);
  }
  componentDidUpdate(prevProps) {


    // this.createDataComponents();

    if (prevProps.data !== this.props.data) {
      // Data has changed, update components
      console.log('udpdate')
      this.createDataComponents();
    }
  }

  logRefs() {
    if (this.dataComponentRefs.length > 0) {
      console.log(this.dataComponentRefs);
    }
  }
  handleLogRefsClick = () => {
    this.logRefs();
  };



  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };


  createDataComponents() {
    const { data } = this.props;
    let dataComponents = [];
    console.log(data)
    if (data.componentType === 'DataComponent') {
      dataComponents = data.info.map((item, index) => (
        // Use a functional component with ref forwarding

          <DataComponent
            key={index}
            key1={index}
            parent_key={this.props.key1}
            setDataComponent={this.props.setDataComponent}

            ref={(ref) => 
              this.dataComponentRefs[index] = ref}
            data={item.data}
            loading={item.loading}
            value={item.value}
            percentChange={item.percentChange}
            title={item.title}
            sub_endpoint={item.sub_endpoint}
            artistId={item.artistId}
            value_format={item.value_format}
            chart_endpoint={item.chart_endpoint}
            chart_xKeyName={item.chart_xKeyName}
            chart_title={item.chart_title}
            chart_xAxisTitle={item.chart_xAxisTitle}
            chart_yAxisTitle={item.chart_yAxisTitle}
            chart_yKeyNames={item.chart_yKeyNames}
            properties={item.properties}
            // Use the callback function to get the ref and store it in the array
            
          />)
      );
    }
    else if (data.componentType === 'LinePlotSimple') {
      dataComponents = [
        <div
          key={0}
          style={{
            width: '98%',
            position: 'relative',
            height: 'auto',
            alignSelf: 'center',
            backgroundColor: 'rgba(240, 240, 240, 1)',
          }}
        >
          <LinePlotSimple
            data={data.info.data}
            loading={data.info.loading}
            key1={0}
            parent_key={this.props.key1}
            setDataComponent={this.props.setDataComponent}
            sub_endpoint={data.info.chart_endpoint}
            properties={data.info.properties}
            xKeyName={data.info.chart_xKeyName}
            title={data.info.chart_title}
            xAxisTitle={data.info.chart_xAxisTitle}
            yAxisTitle={data.info.chart_yAxisTitle}
            yKeyNames={data.info.chart_yKeyNames}
            pct_change={data.info.chart_pct}
            backgroundColor={data.info.chart_background_color}
            hideLegend={data.info.hideLegend}
            // Use the callback function to get the ref and store it in the array
            ref={(ref) => (this.dataComponentRefs[0] = ref)}
          />
        </div>,
      ];
    } else if (data.componentType === 'ScatterPlot') {
      dataComponents = [
        <div
          key={0}
          style={{
            width: '98%',
            position: 'relative',
            height: 'auto',
            alignSelf: 'center',
            backgroundColor: 'rgba(240, 240, 240, 1)',
          }}
        >
          <ScatterPlot
          data={data.info.data}
          loading={data.info.loading}
          key1={0}
          parent_key={this.props.key1}
            sub_endpoint={data.info.chart_endpoint}
            properties={data.info.properties}
            xKeyName={data.info.chart_xKeyName}
            yKeyName={data.info.chart_yKeyName}
            dotSize={data.info.dot_size}
            colorShade={data.info.color_shade}
            title={data.info.chart_title}
            chart_dotTitle={data.info.chart_dotTitle}
            backgroundColor="#F0F0F0"
            hideLegend={data.info.hideLegend}
            // Use the callback function to get the ref and store it in the array
            ref={(ref) => (this.dataComponentRefs[0] = ref)}
          />
        </div>,
      ];
    } else if (data.componentType === 'BarPlot') {
      dataComponents = [
        <div
          key={0}
          style={{
            width: '98%',
            position: 'relative',
            height: 'auto',
            alignSelf: 'center',
            backgroundColor: 'rgba(240, 240, 240, 1)',
          }}
        >
          <BarPlot
              data={data.info.data}
              loading={data.info.loading}
              key1={0}
              parent_key={this.props.key1}
              sub_endpoint={data.info.chart_endpoint}
              properties={data.info.properties}
              title={data.info.title}
              percent={data.info.percent}

              xKeys={data.info.xKeys}
              xKeyTitles={data.info.xKeyTitles}
              yKeyName={data.info.yKeyName}
              yKeyTitle={data.info.yKeyTitle}
              // chart_dotTitle={this.props.chart_dotTitle}
              backgroundColor={data.info.backgroundColor}
              avg={true}
              ref={(ref) => (this.dataComponentRefs[0] = ref)}
          />
          
        </div>,
      ];
    }
    else if (data.componentType === 'PieChart') {
      dataComponents = [
        <div
          key={0}
          style={{
            width: '98%',
            position: 'relative',
            height: 'auto',
            alignSelf: 'center',
            backgroundColor: 'rgba(240, 240, 240, 1)',
          }}
        >
          <PieChart
            data={data.info.data}
            loading={data.info.loading}
            key1={0}
            parent_key={this.props.key1}
            setDataComponent={this.props.setDataComponent}
            sub_endpoint={data.info.chart_endpoint}
            properties={data.info.properties}
            xKeyName={data.info.chart_xKeyName}
            title={data.info.chart_title}
            xAxisTitle={data.info.chart_xAxisTitle}
            yAxisTitle={data.info.chart_yAxisTitle}
            yKeyNames={data.info.chart_yKeyNames}
            pct_change={data.info.chart_pct}
            backgroundColor={data.info.chart_background_color}
            hideLegend={data.info.hideLegend}
            // Use the callback function to get the ref and store it in the array
            ref={(ref) => (this.dataComponentRefs[0] = ref)}
          />
        </div>,
      ];
    }
    else if (data.componentType === 'Songs') {
      dataComponents = [
        <div
          key={0}
          style={{
            width: '98%',
            position: 'relative',
            height: 'auto',
            alignSelf: 'center',
            backgroundColor: 'rgba(240, 240, 240, 1)',
          }}
        >
          <Table_All
          data={data.info.data}
          loading={data.info.loading}
          key1={0}
          parent_key={this.props.key1}
                    columns={data.info.columns}
                    filters={data.info.filters}
                    table_dir={data.info.table_dir}
                    sort= {data.info.sort}
                    selectedFolder={data.info.selectedFolder} 
                    
                    setDataComponent= {this.props.setDataComponent}
                    folders={data.info.folders}
                    updateFlag={true}
                    setUpdateFlag={this.setUpdateFlag}
                    setFilters={this.setFilters}
                    setSort={this.setSort}
                    dontLoadMore={true}
                    />
        </div>,
      ];
    }

    this.setState({ dataComponents }, () => {
      // This callback ensures that the state has been updated before accessing it
      // console.log(this.dataComponentRefs);
    });
  }

  getFinalHTMLList() {
    const finalHTMLList = [];
    // console.log(this.dataComponentRefs)
    // Iterate over DataComponent instances and get finalHTML
    this.dataComponentRefs.forEach((ref) => {
      if (ref && ref.getFinalHTML) {
        finalHTMLList.push(ref.getFinalHTML());
      }
    });
    // console.log(finalHTMLList);

    return finalHTMLList;
  }

  render() {
    const { dataComponents} = this.state;
    const { windowWidth } = this.state;
    // console.log('rerender datagroup')
    // Access isDataLoaded for the first DataComponent (assuming at least one exists)
    // console.log(this.dataComponentRefs)
    // console.log(dataComponents)
    
    // console.log(this.dataComponentRefs);
    return (

      <div  style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        borderRadius: '10px',
        backgroundColor: 'rgba(240, 240, 240, 1)',
        justifyContent: "space-around",
        flexWrap: 'wrap',
        marginTop: '20px',
        width: '49%',
        alignItems: 'center',
        height: '400px', // Set the height to 500px
        width: windowWidth <= 640 ? '98%' : '48%', // Adjust width based on screen width

      }}>
          {dataComponents}
          {/* <button onClick={this.handleLogRefsClick}>Log Refs</button> */}
          {/* <button onClick={this.getFinalHTMLList}>Log Refs</button> */}

      </div>
    
      
    );
  }
  
}

export default DataGroup;
