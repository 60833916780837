import React, { useEffect } from 'react';
import Home from './Home';
import LoginPage from './LoginPage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { LoginCallback, Security } from '@okta/okta-react';
import OuterShell from './OuterShell'; // Import your outer shell component
import App from './App';
import App_Genre from './App_Genre';
import App_Label from './App_Label';
// import EmailComponent from './emailComponentText'; // Assuming EmailComponent is your email preview component
import ArtistPage from './ArtistPage';
import DataComponent from './DataComponent';
import DataGroup from './DataGroup';
import DataBoxes from './DataBoxes';
import ArtistPageNew from './ArtistPageNew';
import SongPageNew from './SongPageNew';
import App_All from './App_All';
import Roster_Songs from './Roster_Songs'
import Roster_Albums from './Roster_Albums'
import App_Artists from './App_Artists'
import TestGraph from './TestGraph'
import App_Artists_New from './App_Artists_New';
import All_Songs from './All_Songs';

const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER, // Replace with your Okta issuer URL
    clientId: process.env.REACT_APP_OKTA_SPA_CLIENT_ID, // Replace with your Okta client ID
    // clientSecret: process.env.REACT_APP_OKTA_SECRET,
    // redirectUri: process.env.REACT_APP_OKTA_CALLBACK, // Redirect URI after login
    redirectUri: process.env.REACT_APP_OKTA_CALLBACK_E, // Redirect URI after login

    pkce: true, // Enable PKCE (Proof Key for Code Exchange) for enhanced security
    scopes: ['openid', 'profile'], // Scopes you need for user information
  });
console.log('aas')

function Main1() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log('aasdafdas')
    navigate("", {replace: true});
  }

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
        <Routes>
          <Route element={ <PrivateRoutes />}>
          {/* PRIVATE_ROUTES_BEGIN */}
            <Route element={<OuterShell><Roster_Songs /></OuterShell>} path="/" exact/>
            <Route element={<OuterShell><Roster_Songs /></OuterShell>} path="/roster" exact/>
            <Route element={<OuterShell><All_Songs /></OuterShell>} path="/songs" exact/>
            <Route element={<OuterShell><Roster_Albums /></OuterShell>} path="/albums" exact/>
            <Route element={<OuterShell><App_Label /></OuterShell>} path="/label" exact/>
            <Route element={<OuterShell><App_Genre /></OuterShell>} path="/genre" exact/>
            <Route element={<OuterShell><App_Artists_New /></OuterShell>} path="/artists" exact/>
            
            <Route element={<OuterShell><TestGraph/></OuterShell>} path="/test_graph" exact/>
            
            {/* <Route element={<OuterShell><DataBoxes artistId={2004796} /></OuterShell>} path="/test" exact/> */}
            <Route element={<OuterShell><ArtistPage /></OuterShell>} path="/artist_albums/:artistId"/>
            <Route element={<OuterShell><ArtistPageNew /></OuterShell>} path="/artist/:artistId"/>
            <Route element={<OuterShell><SongPageNew /></OuterShell>} path="/song/:unifiedSongId/:familyId"/>
          {/* PRIVATE_ROUTES_END */}
          </Route>
          <Route  element={ <LoginPage /> } path="/login"/>
          <Route element={ <LoginCallback />} path="/authorization-code/callback"/>
        </Routes>
      </Security>
    </div>
  );
}

{/* <NavigateRoute path="/roster" element={<OuterShell><App /></OuterShell>} />
<NavigateRoute path="/genre" element={<OuterShell><App_Genre /></OuterShell>} />
<NavigateRoute path="/label" element={<OuterShell><App_Label /></OuterShell>} />
<NavigateRoute path="/email" element={<OuterShell><EmailComponent/></OuterShell>} />
<NavigateRoute path="/test" element={<OuterShell><App1/></OuterShell>} />
<NavigateRoute path="/artist/:artistId" element={<OuterShell><ArtistPage /></OuterShell>} /> */}

export default Main1;