import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {endpoint} from './mainEndpoint';

class Home extends Component {
  state = {
    folders: [],
    loadingFolders: true,
  };

  componentDidMount() {
    const { authState } = this.props;
    console.log(authState)
    // Check if the user is authenticated before fetching data
    if (authState.isAuthenticated) {
      this.fetchFolders();
    }
  }

  fetchFolders() {
    console.log('fetching folders');
     const { authState } = this.props;
     const accessToken = authState.accessToken.accessToken;
     const headers = {
        Authorization: `Bearer ${accessToken}`
      };
    const url = `${endpoint}/folders`;
  
    // Get the access token from authState
    
    // Configure headers with the Authorization header
    
  
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(url, {
          headers: headers // Add the headers to the fetch options
        })
          .then((response) => response.json())
          .then((folders) => {
            this.setState({ folders, loadingFolders: false });
            console.log('Done loading folders');
          })
          .catch((error) => {
            console.error('Error fetching folders:', error);
          });
      }, 1000);
    });
  }
  

  render() {
    const { authState } = this.props;
    const { folders, loadingFolders } = this.state;

    return (
      <div>
        <h1>Welcome to the Home Page</h1>
        {authState.isAuthenticated ? (
          <div>
            <p>This is the authenticated home page of your application.</p>
            {loadingFolders ? (
              <p>Loading folders...</p>
            ) : (
              <div>
                <p>Folders:</p>
                <ul>
                  {folders.map((folder) => (
                    <li key={folder.id}>{folder.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <p>You are not authenticated.</p>
        )}
      </div>
    );
  }
}

export default withOktaAuth(Home);
