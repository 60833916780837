import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import {endpoint} from './mainEndpoint'
import { withOktaAuth } from '@okta/okta-react';
import { formatValue, formatColor } from './Format'; // Assuming Format.js is in the same directory
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, makeStyles } from '@material-ui/core';
import LinePlotSimple from './LinePlotSimple'
const styles = {
  customPaperClass: {
    margin: '0',
  },
  // Add more styles as needed
};
class DataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: null,
      value: '', // Initialize with an empty value
      percent_change: '', // Initialize with an empty percent_change
      title: '', // Initialize with an empty title
      // isDataLoaded: false, // Track whether data is loaded
      innerHTML: '',
      finalHTML: '', // Initialize with an empty finalHTML
      open: false, // Add open state to control dialog visibility

    };
  }

  componentDidMount() {
    // Simulate fetching data (replace with your API call)
    // Assume you have a function fetchData() that fetches the data
    // fetchData()
    //   .then((data) => {
    //     // Build the final HTML

    //     const { innerHTML, this_html } = this.buildFinalHTML(data);
    //     // Update state with the received data and final HTML
    //     this.setState({
    //       value: data.value,
    //       percent_change: data.percent_change,
    //       title: data.title,
    //       isDataLoaded: true,
    //       innerHTML: innerHTML,
    //       finalHTML: this_html,
    //     });
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching data:', error);
    //   });
    this.fetchData();

  }

  async fetchData() {
    const { sub_endpoint } = this.props;
    // const sub_endpoint = 'artist_spl'
    const {artistId,title} = this.props;
    // const title = 'Streams Per Listener'
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const queryParams = new URLSearchParams();
    queryParams.append('artistId', artistId);
    const queryString = queryParams.toString();
    
    try {
      // Replace with your actual API endpoint URL
      const response = await fetch(`${endpoint}/${sub_endpoint}?${queryString}`,
      {
        method: 'GET', // Replace with the appropriate HTTP method
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Replace accessToken with your actual access token
          'Content-Type': 'application/json', // Add other headers as needed
        },
      });
      const data = await response.json();

      data.title = title;
      const { innerHTML, this_html } = this.buildFinalHTML(data);

      this.setState({
        // data: data,
        value: data.value,
        percent_change: data.percent_change,
        title: data.title,
        // isDataLoaded: true,
        innerHTML: innerHTML,
        finalHTML: this_html,
      });
      this.props.setDataComponent(this.props.parent_key,this.props.key1, data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  // Build the final HTML using the received data
  buildFinalHTML(data) {
    const { value, percent_change, title } = data;
    const { isDataLoaded } = this.state;
    const innerHTML = (
      <div className="follower-container">
        <div className="bod">
          <div className="follower-count">
            <p>
              <strong>{value}</strong>
            </p>
          </div>
          <div className="follower-change">
          <p> (
              <span style={{ color: formatColor(percent_change) }}>
                {formatValue(percent_change, 'number', 'percentChangeWplus')}
              </span>
            )</p>

          </div>
        </div>
        <div className="follower-label">
          <p>
            <span className="gray-text">{title}</span>
          </p>
        </div>
      </div>
    );


    const this_html = ReactDOMServer.renderToStaticMarkup(
      <html>
        <head>
          <meta charset="UTF-8" />
          <title>blank</title>
          <style>{`
      .main-container {
        position: relative;
        margin-top: 20px;
        flex-grow: 0;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @media (max-width: 640px) {
        .main-container {
          width: auto;
          flex-grow: 1;
        }
      }

      
      
      .follower-container p {
        margin: 0;
        padding: 0;
        
      }

      @media (max-width: 640px) {
        .follower-container {
          height: auto;
          align-self: center;
          margin-right: 20px;
          margin-left: 20px;
          margin-bottom: 20px;
        }
        .box-container {
          width: auto;
          flex-grow: 1;
        }
      }
      .bod{
        display: flex;
        flex-direction: row;
        
      }
      .follower-count {
        position: relative;
        height: auto;
        font-size: 30px; 
      
      }

      .follower-change {
        position: relative;
        height: auto;
        font-size: 30px; 
      }

      .follower-label {
        position: relative;
        height: auto;
        white-space: wrap; /* Prevent wrapping */
      }

      .gray-text {
        color: rgb(74, 74, 74);
      } 
      .follower-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around; /* Center vertically */
        align-items: center; /* Center horizontally */
        position: relative;
        
        height: auto;
        align-self: center;
        // margin-left: 80px;
        // margin-top: 80px;
        // margin-right: 80px;
        // margin-bottom: 80px;
        width: 50%;
      }
      
      .box-container {
        display: flex;
        flex-direction: row;
        position: relative;
        border-radius: 10px;
        background-color: rgba(240, 240, 240, 1);
        flex-wrap: wrap;
        margin-top: 20px;
        
        #DOUBLE STUFF
        position: relative;
        margin-top: 20px;
        flex-grow: 0;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        
      }
      .boxes-container {
        position: relative;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
      }
    
      
      `}
        </style>
        </head>
        <body>
          {/* <div class="main-container"> */}
          <div class="follower-container">
            <div class="bod">
              <div class="follower-count">
                <p><strong>{value}</strong></p>
              </div>
              <div class="follower-change">
              <p> (
                <span style={{ color: formatColor(percent_change) }}>
                  {formatValue(percent_change, 'number', 'percentChangeWplus')}
                </span>
              )</p>
             </div>
            </div>
            <div class="follower-label">
              <p><span class="gray-text">{title}</span></p>
            </div>
          </div>
          {/* </div> */}
        </body>
      </html>
    )
    return { innerHTML, this_html };
  }


  handleClickOpen = (event) => {
    // console.log('aaaa');
    this.setState({ open: true }, () => {
      // Trigger chart redraw or reflow after the dialog is fully displayed
      window.dispatchEvent(new Event('resize'));
    });
  };
  

  // Close the dialog
  handleClose = (event) => {
    // console.log('ha')
    event.stopPropagation(); // Prevent event propagation
    this.setState({ open: false });
    // console.log(this.state.open)
  };
  // Inside DataComponent class
  getFinalHTML() {
    // const { innerHTML, this_html } = this.buildFinalHTML(this.state.data);
    return this.state.this_html;
  }


  
  
  render() {
    // console.log('rerender component')
    const { finalHTML, open} = this.state;
    const {data, loading} = this.props
    const {artistId} = this.props;
    // console.log(this.props.value_format)
    // console.log(data)
    // console.log(loading)
    return (
      <div
  class="follower-container"
  style={{
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', /* Center horizontally */
    position: 'relative',
    height: 'auto',
    alignSelf: 'center',
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
  }}
  onClick={(event) => this.handleClickOpen(event)}

  >
  {/* Render the final HTML */}
  {!loading ? (
    <div>
      <div class="bod" style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}>
        <div class="follower-count">
          <p style={{
            margin: 0,
            padding: 0,
            fontSize: '25px',
          }}><strong>{formatValue(data.value, 'number', this.props.value_format)}</strong></p>
        </div>
        <div class="follower-change">
        {data.percent_change !== null ? (
          <p style={{
            margin: 0,
            padding: 0,
            fontSize: '25px',
          }}> (
            <span style={{ color: formatColor(data.percent_change) }}>
              {formatValue(data.percent_change, 'number', 'percentChangeWplus')}%
            </span>
          )</p>
        ) : null}
      </div>
      </div>
      <div class="follower-label">
        <p style={{
          margin: 0,
          padding: 0,
        }}><span class="gray-text">{data.title}</span></p>
      </div>
      <Dialog
      style={{margin: '0px' }}
  open={this.state.open}
  onClose={this.handleClose}
  classes={{  }} // Apply the custom styles
  
  PaperProps={{
    style: {
      margin:'0px',
      width:'100%',
      maxWidth:'1500px'
    },
  }}
>
  <DialogTitle>{data.title}</DialogTitle>
  <DialogContent style={{ padding: '2px' }}>
    {/* Embed the LinePlot component inside the Dialog */}
    <div>
    <LinePlotSimple
      sub_endpoint={this.props.chart_endpoint}
      // artistId={this.props.artistId}
      xKeyName={this.props.chart_xKeyName}
      title ={this.props.chart_title}
      xAxisTitle = {this.props.chart_xAxisTitle}
      yAxisTitle = {this.props.chart_yAxisTitle}
      yKeyNames={this.props.chart_yKeyNames}
      pct_change={this.props.chart_pct}
      properties={this.props.properties}
    />
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={this.handleClose} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>

    </div>
    
  ) : (
    <p>Loading...</p>
  )}
</div>

    );
  }
  
}


export default withOktaAuth(DataComponent);
