import React, { Component } from 'react';
import {
  FormControl, Select, MenuItem, IconButton, TextField, Button,
  Dialog, DialogContent,DialogActions,DialogTitle
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { withOktaAuth } from '@okta/okta-react';
import {endpoint} from './mainEndpoint';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmationDialog = ({ open, title, message, onConfirm, onCancel }) => {
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onConfirm(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

class SavedFiltersColumnsComponent extends Component {
  state = {
    folders: [],
    selectedFolder: -1,
    isMenuOpen: false,
    isShareDialogOpen: false,
    isDeleteConfirmationOpen: false,
    deletingFolder: null,
    sharedFolder: null,
    sharedEmail: '',
    successDialogOpen:false,
      errorDialogOpen:false,
      errorMessage: ''
  };

  folderSelectRef = React.createRef();
  newFolderNameRef = React.createRef();

  componentDidMount() {
    this.fetchFolders();
  }

  fetchFolders() {
    console.log('fetching folders');
    // const url = `${endpoint}/saved_columns_filters`;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
      Authorization: `Bearer ${accessToken}`
    };
    const queryParams = new URLSearchParams();
    if (this.props.folderType) {
      queryParams.append('folderType', this.props.folderType);
    }
    const queryString = queryParams.toString();
    const url = `${endpoint}/saved_columns_filters?${queryString}`;
    // Simulating data fetching
    return new Promise((resolve, reject) => {
      fetch(url,
        {
          headers: headers // Add the headers to the fetch options
        })
        .then((response) => response.json())
        .then((folders) => {
          this.setState({ folders, loadingFolders: false });
          if (this.props.setFolders){
            this.props.setFolders(folders)
          }
          console.log('Done loading folders');
        })
        .catch((error) => {
          console.error('Error fetching folders:', error);
        });
    });
  }

  fetchSavedColumnsFiltersDetailsById = (id) => {
    return new Promise((resolve, reject) => {
      const { authState } = this.props;
      const accessToken = authState.accessToken.accessToken;
  
      const requestData = {
        id: id
      };
  
      fetch(`${endpoint}/get_saved_columns_filters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((result) => {
          // Sort the details based on index
          const sortedDetails = result.sort((a, b) => a.index - b.index);
          resolve(sortedDetails); // Resolve the promise with the sorted result
        })
        .catch((error) => {
          console.error('Error fetching saved columns filters details:', error);
          reject(error); // Reject the promise with the error
        });
    });
  }
  
  handleSavedColumnsFiltersSelect = (event) => {
    const { columns, filters, sort, setColumns, setFilters, setSort} = this.props;
    console.log(columns);
    console.log(filters);
    console.log(sort);
  
    const selectedSavedColumnsFiltersID = event.target.value;
    this.setState({
        selectedFolder: selectedSavedColumnsFiltersID
    })
    this.props.setSelectedFilterColumns(selectedSavedColumnsFiltersID)
    console.log(selectedSavedColumnsFiltersID);
    if (selectedSavedColumnsFiltersID === '-1') {
        // Reload the page when selectedSavedColumnsFiltersID is -1
        window.location.reload();
        return;
      }
    // Call the function and use .then on the returned promise
    this.fetchSavedColumnsFiltersDetailsById(selectedSavedColumnsFiltersID)
      .then((details) => {
        console.log(details);
        console.log(columns)
        // Map the details to update columns
        // const updatedColumns = columns.map((detail) => {
        //   const column = details.find((c) => c.KEY === detail.key);
        //   console.log(column)
        //   console.log(detail)
        //   if (column) {
        //     return {
        //       ...column,
        //       hide: detail.HIDE, // Set hide to the value in each details row hide variable
        //     };
        //   } 
          
        // });
        const updatedColumns = details.map((detail) => {
          const column = columns.find((c) => c.key === detail.KEY);
          return {
            ...column,
            hide: detail.HIDE, // Set hide to the value in each details row hide variable
          };
        });
        
        // Add columns from `columns` that are not in `details` and set `hide` to true
        const missingColumns = columns
          .filter((column) => !details.some((detail) => detail.KEY === column.key))
          .map((column) => ({
            ...column,
            hide: true, // Set hide to true for missing columns
          }));
        
        const finalUpdatedColumns = [...updatedColumns, ...missingColumns];
  
        const nonNullSortDetail = details.find(
          (detail) => detail.SORT_ORDER !== 'null' && detail.SORT_TYPE !== 'null'
        );
  
        // Update the sort object based on the non-null detail
        const updatedSort =
          nonNullSortDetail &&
          nonNullSortDetail.SORT_ORDER !== 'null' &&
          nonNullSortDetail.SORT_TYPE !== 'null'
            ? {
                key: nonNullSortDetail.KEY,
                type: nonNullSortDetail.SORT_TYPE,
                order: nonNullSortDetail.SORT_ORDER,
              }
            : sort;
  
        const updatedFilters = details.reduce((acc, detail) => {
          if (detail.OPERATOR !== 'null' && detail.VALUE !== 'null') {
            // Define a new filter based on the detail
            const column = columns.find((c) => c.key === detail.KEY);

            const newFilter = {
              column: detail.KEY,
              operator: detail.OPERATOR,
              optionsMatchingKey: column.optionsMatchingKey,
              value: (() => {
                if (detail.OPERATOR === 'in' && !detail.VALUE.includes(',')) {
                  return [detail.VALUE];
                } else if (detail.OPERATOR === 'in') {
                  console.log(detail.VALUE)
                  console.log(detail.VALUE.split(',').map((v) => parseInt(v.trim(), 10)))
                  const parsedValues = detail.VALUE.split(',').map(v => {
                    const trimmedValue = v.trim();
                    const parsedInt = parseInt(trimmedValue, 10);
                
                    // Check if parsing was successful
                    return isNaN(parsedInt) ? trimmedValue : parsedInt;
                  });
                  console.log(parsedValues)
                  return parsedValues
                } else if (['>', '<', '='].includes(detail.OPERATOR)) {
                  return parseInt(detail.VALUE, 10);
                } else if (detail.KEY === "MRC_TOGGLED"){
                  return detail.VALUE.toLowerCase() === "true";
                }
                else {
                  return detail.VALUE;
                }
              })(),
            };
  
            // If optionsMatchingKey is defined in columns, add it to the new filter
            const columnWithMatchingKey = columns.find(
              (column) => column.key === detail.KEY && column.optionsMatchingKey
            );
            if (columnWithMatchingKey) {
              newFilter.optionsMatchingKey = columnWithMatchingKey.optionsMatchingKey;
            }
  
            // Add the new filter to the accumulator
            acc.push(newFilter);
          }
          // console.log(updatedFilters)
  
          return acc;
        }, []);
        // const columnKeyToUpdate = 'FOLDER_ID';
    
        // let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
        // console.log(existingColumn)
        // if (existingColumn) {
        //   // Set the existingColumn.hide as the inverse of its current value
          
        //   const newFilter = {
        //       column: 'FOLDER_ID',
        //       operator: 'FOLDER', // You can set the operator as per your requirement
        //       value: existingColumn.value
        //   };
  
        //   updatedFilters.push(newFilter); // Push the new filter to the updatedFilters array
        // }
          
  
        // Now you can use updatedColumns, updatedFilters, and sort as needed
        console.log(updatedFilters)
        setColumns(finalUpdatedColumns)
        setFilters(updatedFilters)
        setSort(updatedSort)
      })
      .catch((error) => {
        console.error('Error handling saved columns filters details:', error);
        // Handle the error case
      });
  };
  
  
  

  
  


  handleShareClick = (folder) => {
    this.setState({
      sharedFolder: folder,
      isShareDialogOpen: true,
    });
  };

  handleCloseShareDialog = () => {
    this.setState({
      isShareDialogOpen: false,
      sharedEmail: '', // Clear the shared email input
      sharedFoldre: null,
    });
  };
  handleDeleteConfirmation = (confirm) => {
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    if (confirm) {
        const { deletingFolder } = this.state;
        const folderId = deletingFolder.ID;
        console.log(folderId)
    
        // Call the delete folder endpoint
        fetch(`${endpoint}/delete_saved_columns_filters?id=${folderId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ folderId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Folder deleted:', result.message);
            // Handle any necessary state updates or UI changes after successful deletion
          })
          .catch((error) => {
            console.error('Error deleting folder:', error);
            // Handle the error case
          })
          .finally(() => {
            // This code will be executed whether the delete operation succeeds or fails
            this.fetchFolders();
          });
      }

    // Reset the confirmation dialog state
    this.setState({
      deletingFolder: null,
      isDeleteConfirmationOpen: false,
    });
  };

  handleShare = () => {
    // const { sharedEmail, selectedFolder } = this.state;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;

    const { sharedFolder, } = this.state;
    const id = sharedFolder.ID;
    const folderName = sharedFolder.NAME;
    const sharedEmail = this.sharedInput.value;
    const{folderType} = this.props


    // Call the delete folder endpoint
    fetch(`${endpoint}/share_saved_columns_filters?id=${id}&sharedEmail=${sharedEmail}&folderType=${folderType}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,

      },
      body: JSON.stringify({ id, folderName, sharedEmail, folderType}),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.success) {
          console.log('Folder shared:', result.success);
          // Set successDialogOpen to true when sharing is successful
          this.setState({ successDialogOpen: true });
        } else {
          console.error('Error sharing folder:', result.error);
          // Set errorDialogOpen to true when an error occurs
          this.setState({
            errorDialogOpen: true,
            errorMessage: result.error
          });
        }

        // Handle any necessary state updates or UI changes after successful deletion
      })
      .catch((error) => {
        console.error('Error sharing folder:', error);
        // Set errorDialogOpen to true when an error occurs
        this.setState({
          errorDialogOpen: true,
          errorMessage: error
        });
        // Handle the error case
      });
    this.fetchFolders()

    // Reset the confirmation dialog state
    // Perform your sharing logic here with sharedEmail and selectedFolder
    // Close the dialog
    this.handleCloseShareDialog();
  };


  handleFolderMenuItemClick = (folderId, row, familyId, unifiedSongId, spotifyId, folderName) => {
    console.log('hahah')
    // thisRow?.folderIds?.includes(folder.ID);
    const { authState,columns,filters,sort} = this.props;
    console.log(columns)
    console.log(filters)
    const accessToken = authState.accessToken.accessToken;
    if (folderId === 'newFolder') {
      // Handle the logic to create a new folder with the given folderName
      console.log('Create new folder:', folderName);

      // Perform the API call to add the new folder to the database
      const requestData = {
        user_id: 1, // Replace with the actual user ID value
        name: folderName,
        type:this.props.folderType,
        columns: columns,
        filters: filters,
        sort:sort
      };

      console.log(folderName);
      fetch(`${endpoint}/add_saved_columns_filters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestData), // Send the requestData object as the request body
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('New folder added:', result);
          this.fetchFolders()
          // Handle any necessary state updates or UI changes
        })
        .catch((error) => {
          console.error('Error adding new folder:', error);
          // Handle the error case
        });



    } else {
      // Handle the logic to add the trackedIdMRC to the selected folder
      console.log('Add trackedIdMRC to folder:', folderId);
      console.log('Family ID:', familyId);
      console.log('Unified Song ID:', unifiedSongId);

      if (row?.folderIds?.includes(folderId)) {
        let user_id = 1;
        fetch(`${endpoint}/delete_from_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family deleted from folder:', result);
            // Handle any necessary state updates or UI changes
            this.fetchData()
          })
          .catch((error) => {
            console.error('Error deleting family from folder:', error);
            // Handle the error case
          });

      } else {
        let user_id = 1;
        fetch(`${endpoint}/add_to_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family added to folder:', result);
            this.fetchData()
            // Handle any necessary state updates or UI changes
          })
          .catch((error) => {
            console.error('Error adding family to folder:', error);
            // Handle the error case
          });
      }

    }

    // Close the folder menu
    this.setState({
      folderMenuAnchor: null,
    });

  };

  render() {
    const { folders, successDialogOpen,
      errorDialogOpen,
      errorMessage,  selectedFolder, isShareDialogOpen, deletingFolder,isDeleteConfirmationOpen } = this.state;

    return (
      <div>
        <FormControl color="primary" style={{}}>
          <Select
            ref={this.folderSelectRef}
            labelId="demo-simple-select-label"
            value={selectedFolder}
            onChange={this.handleSavedColumnsFiltersSelect}
            onClick={(event) => {
              this.setState({ isMenuOpen: true });
              event.stopPropagation();
            }}
            inputProps={{
              name: 'folder',
              id: 'folder-select',
            }}
            style={{ width: '100%' }}
          >
            <MenuItem value="-1">Saved Columns / Filters</MenuItem>
            {folders.map((folder) => (
              <MenuItem key={folder.ID} value={folder.ID}>
                {folder.NAME}
                {selectedFolder !== folder.ID && folder.NAME !== "TRACKED" && (
                  <div>
                    <IconButton onClick={(event) => {
                      event.stopPropagation();
                      this.handleShareClick(folder);
                    }}>
                      <ShareIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event) => {
                        event.stopPropagation();
                        this.setState({
                            deletingFolder: folder,
                            isDeleteConfirmationOpen: true,
                        });
                        }}
                    >

                        <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </MenuItem>
            ))}
            <MenuItem>
                <TextField
                placeholder="New Folder"
                inputRef={this.newFolderNameRef}
                // onChange={(event) => this.setState({ newFolderName: event.target.value })}
                InputProps={{
                    endAdornment: (
                    <IconButton
                        onClick={() => {
                        this.handleFolderMenuItemClick('newFolder', null, null, null, null, this.newFolderNameRef.current.value)

                        }}

                    >
                        <AddIcon />
                    </IconButton>
                    ),
                }}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
                onClick={(event) => {
                    event.stopPropagation(); // Prevent selecting the menu item
                }}
                />
            </MenuItem>
          </Select>
        </FormControl>
        <ConfirmationDialog
                  open={isDeleteConfirmationOpen}
                  title="Delete Folder"
                  message={`Are you sure you want to delete folder ${deletingFolder?.NAME}?`}
                  onConfirm={this.handleDeleteConfirmation}
                  onCancel={this.handleDeleteConfirmation}
                />
        <Dialog open={isShareDialogOpen} onClose={this.handleCloseShareDialog}>
          <DialogContent>
            <TextField
              onChange={(e) => this.setState({ sharedEmail: e.target.value })}
            />
            <TextField
              label="Insert Email"
              placeholder="Insert email"
              variant="outlined"
              size="small"
              fullWidth
              inputRef={(input) => (this.sharedInput = input)}
            />
            <Button onClick={this.handleShare} color="primary">
              Share
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={successDialogOpen}
          onClose={() => this.setState({ successDialogOpen: false })}
        >
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <DialogContentText>The folder has been successfully shared.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ successDialogOpen: false })} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
        open={errorDialogOpen}
        onClose={() => this.setState({ errorDialogOpen: false })}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ errorDialogOpen: false })} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    );
  }
}

export default withOktaAuth(SavedFiltersColumnsComponent);
