import React, { Component } from 'react';
// import DataLoader from './DataLoader';
import TableComponent from './TableComponent';
import './App.css'; 
import ExpandedRow_Label from './ExpandedRow_Label';
import {endpoint} from './mainEndpoint';

class App_Label extends Component {
  render() {
    // const endpoint = 'http://10.28.166.217:3500'; // Replace with your desired endpoint
    // const endpoint = 'http://localhost:3500'
    const table_dir = 'label_table'
    console.log(endpoint)


  


    const columns = [
      { key: 'PRIMARY_LABEL_NAME', id: 'LABEL_ID', displayName: 'LABEL', type: 'string' , fix: true},
      { key: 'TOTAL_TW_STREAMS', displayName: 'This Week Streams', type: 'number', format :'number'},
      { key: 'TW_PCHANGE', displayName: 'Percent Change Last Week', type: 'number', format :'number'},
      { key: 'TOTAL_LW_STREAMS', displayName: 'Last Week Streams', type: 'number', format :'number'},
      { key: 'ROLLING_3WEEK_PCHANGE', displayName: 'Rolling 3 Week Percent Change', type: 'number',format:'percentChange'},
      { key: 'ROLLING_5WEEK_PCHANGE', displayName: 'Rolling 5 Week Percent Change', type: 'number',format:'percentChange'},
      { key: 'ROLLING_10WEEK_PCHANGE', displayName: 'Rolling 10 Week Percent Change', type: 'number',format:'percentChange'},
      { key: 'EXPONENTIAL_SCORE', displayName: 'Exponential Score', type: 'number',format:'number'},
      { key: 'PCT_WEEKS_GROWN', displayName: 'Percent Weeks Grown', type: 'number',format:'percentChange'},
      { key: 'PEAK', displayName: 'Peaking?', type: 'string'},
      { key: 'NUM_ARTIST', displayName: 'Artist Count', type: 'number',format:'number'},
      { key: 'TOTAL_YTD_STREAMS', displayName: 'YTD', type: 'number',format :'number' },
      { key: 'TOTAL_ATD_STREAMS', displayName: 'ATD', type: 'number',format :'number' },
      
      
      
      
    ];
    return (
      
      <div>
        {/* <DataLoader > */}
          <TableComponent columns ={columns} table_dir={table_dir}endpoint={endpoint} ExpandedRowComp={ExpandedRow_Label} id_column = 'LABEL_ID' pageSize = {100} default_sort_column_key= {'TOTAL_TW_STREAMS'}/>
        {/* </DataLoader> */}
      </div>
    );
  }
}

export default App_Label;


