import React, { Component } from 'react';
import Highcharts, { Color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';
import { Paper, Typography } from '@material-ui/core';
import { formatValue } from './Format';

class BarPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    console.log(data)
    if (data) {
      this.setState({ data, loading: false });
    } else {
      this.fetchData();
    }
  }

  fetchData() {
    const { sub_endpoint, xKeyName, yKeyName, artistId, authState, properties } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const queryParams = new URLSearchParams();
    console.log('ahah')
    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        const value = properties[key];
        queryParams.append(key, value);
      }
    }
    const queryString = queryParams.toString();

    fetch(`${endpoint}/${sub_endpoint}?${queryString}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      this.setState({
        data,
        loading: false,
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      this.setState({ loading: false });
    });
  }

  render() {
    const { colorShade, xKeys, xKeyTitle,xKeyTitles, yKeyName, yKeyTitle, title, backgroundColor, percent,annotation } = this.props;
    const { loading } = this.state;
    const series = xKeys.map((xKey, index) => ({
      name: xKey.xKeyTitle,
      // data: this.state.data.map(item => item[xKey.xKeyName]),
      data: this.state.data.map(item => ({
        y: item[xKey.xKeyName],
        annotation: item[annotation]
      })),
      color: Highcharts.getOptions().colors[index % Highcharts.getOptions().colors.length],
    }));
    console.log(series)
    const chartOptions = {
      chart: {
        type: 'bar',
        backgroundColor: backgroundColor,
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: this.state.data.map(item => item[xKeyTitles]),
        
      },
      yAxis: {
        title: {
          text: yKeyTitle || yKeyName,
        },
        
      },
      plotOptions: {
        bar: {
          stacking: percent, // Enable stacking
          dataLabels: {
            enabled: annotation != null,
            align: 'left', // Align labels to the left of the bars
            verticalAlign: 'middle',
            inside: false, // Place the data labels outside the bars
            x: 5, // Adjust this value to position the label further away from the bar
            formatter: function() {
              // Only display the annotation for the first series
              if (this.series.index === 0) {
                return this.point.annotation;
              }
              return null;
            },
          },
        },
        
      },
      series: series,
      legend: {
        reversed: true, // Ensure legend items are displayed in the same order as series
      },
      exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                enabled: true,
                menuItems: ['downloadCSV', 'downloadPNG'],
                csv: {
                    dateFormat: '%d/%m/%Y'
                }
            },
        }
    }
    };

    if (loading) {
      return <Typography>Loading...</Typography>;
    }

    // return (
    //   <div style={{ backgroundColor }}>
    //     <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //   </div>
    // );
    return (
      <div style={{ backgroundColor }}>
        {loading ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </>
        )}
      </div>
    );
  }
}

export default withOktaAuth(BarPlot);
