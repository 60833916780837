import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core';

class PercentileWeight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      weights: this.getWeightsFromFilters(props.filters),

    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  getWeightsFromFilters = (filters) => {
    const weights = {};

    filters.forEach((filter) => {
      if (filter.operator === 'weight') {
        // Assuming the operator 'weight' is used for percentile weights
        weights[filter.column] = filter.value.toString(); // Convert to string for the TextField value
      }
    });

    return weights;
  };


//   handleInputChange = (columnName, value) => {
//     this.setState((prevState) => ({
//       weights: {
//         ...prevState.weights,
//         [columnName]: value,
//       },
//     }));
//   };
  handleInputChange = (columnName, value) => {
    // Ensure the input value is a valid decimal between 0 and 1
    const parsedValue = parseFloat(value);
    const clampedValue = isNaN(parsedValue) ? '' : Math.min(Math.max(parsedValue, 0), 1);

    // Format the clamped value with 2 decimal places
    const formattedValue = clampedValue === '' ? '' : clampedValue.toFixed(2);

    this.setState((prevState) => ({
      weights: {
        ...prevState.weights,
        [columnName]: formattedValue,
      },
    }));
  };

  handleApplyWeights = () => {
    const { weights } = this.state;
    const { filters, setFilters, setSort } = this.props;
  
    // Create a new filters object and copy existing filters into it
    const updatedFilters = [...filters];
    console.log(weights)
    // Iterate through each column with weights
    Object.keys(weights).forEach((columnName) => {
      // Check if the weight is not null and not NaN
      if (weights[columnName] !== null && !isNaN(weights[columnName]) && weights[columnName] !== '') {
        // Find the existing filter for the column
        const existingFilterIndex = updatedFilters.findIndex(
          (filter) => filter.column === columnName && filter.operator === 'weight'
        );
  
        if (existingFilterIndex !== -1) {
          // Update the existing filter with the weight property
          updatedFilters[existingFilterIndex].value = parseFloat(weights[columnName]);
        } else {
          // Add a new filter with the weight property
          updatedFilters.push({
            column: columnName,
            operator: 'weight', // Assuming 'weight' is the operator for percentile weights
            value: parseFloat(weights[columnName]),
          });
        }
      }else {
        // Remove the existing filter from updatedFilters if it exists
        const existingFilterIndex = updatedFilters.findIndex(
          (filter) => filter.column === columnName && filter.operator === 'weight'
        );
        if (existingFilterIndex !== -1) {
          updatedFilters.splice(existingFilterIndex, 1);
        }
      }
    });
  
    // Set the updated filters using the setFilters prop
    setFilters(updatedFilters);
    // if length filters with operator = weight greater then or equal to 1 then do below
    const weightFiltersCount = updatedFilters.filter((filter) => filter.operator === 'weight').length;
    if (weightFiltersCount >= 1) {
        // Set the sort order based on the condition
        setSort({ key: 'NET_PERCENTILE', type: 'number', order: 'desc' });
    } else {
        setSort({key: 'STREAMS_PERCENTILE', type:'number',order: 'desc'});
    }
    // Close the dialog
    this.handleClose();
  };
  
  render() {
    const { open, weights } = this.state;
    const { columns,filters} = this.props;
    console.log(filters)
    return (
      <div>
        <Button color="primary" variant="outlined" onClick={this.handleOpen}>
          Percentile Weights
        </Button>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>Percentile Weights</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {columns
                .filter((column) => column.percentile)
                .map((column) => (
                  <Grid item xs={12} key={column.key}>
                    <TextField
                      label={column.displayName}
                      type="number"
                      inputProps={{ step: 0.01, min: 0, max: 1 }}
                      value={weights[column.key] || ''}
                      onChange={(e) =>
                        this.handleInputChange(column.key, e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleApplyWeights} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default PercentileWeight;
