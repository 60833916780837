import React, { Component } from 'react';
import TableHeaderColumn from './TableHeaderColumn';
import {
  Table, TableHead, TableRow, TableBody, TableCell,MenuItem,Button,ClickAwayListener,Menu
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import CheckIcon from '@material-ui/icons/Check';
import FolderIcon from '@material-ui/icons/Folder';
import {endpoint} from './mainEndpoint';

class DynamicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    folderMenuAnchor: null,
    thisRow: null,
    expandedRow: null,
    }

    this.lastRowRef = React.createRef();
  }
  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }


  handleScroll = () => {
    const { loading } = this.props;
    const lastRow = this.lastRowRef.current;

    if (lastRow && !loading) {
      const lastRowRect = lastRow.getBoundingClientRect();

      // Check if the last row is near the bottom of the viewport
      if (lastRowRect.top <= window.innerHeight && lastRowRect.bottom >= 0) {
        // Load more data when the last row is near the bottom
        console.log(this.props.loading)
        if (!this.props.loading){
          this.props.setLoading()
          this.loadMoreData()
          
        }
       
      }
    }
  };

  loadMoreData = () => {
    const { onLoadMore } = this.props;
    // Call the onLoadMore function provided by the parent component
    console.log(!this.props.loading)
    console.log(onLoadMore)
    if (onLoadMore && !this.props.loading) {
      console.log('loading More')
      onLoadMore();
    }
  };

  formatValue = (value, type, format) => {
    if (value === null) {
      return '';
    }

    if (type === 'number') {
      if (format === 'literal') {
        return value || ''; // If value is undefined, return an empty string
      } else if (format === 'percentChange') {
        const roundedValue = Math.round(value * 10) / 10;
        return (roundedValue || 0).toFixed(1) + '%'; // If value is undefined, return '0%'
      } else if (format === 'number') {
        const absValue = Math.abs(value);
        if (absValue >= 1e9) {
          return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
        } else if (absValue >= 1e6) {
          return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
        } else if (absValue >= 1e3) {
          return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
        } else {
          return value || ''; // If value is undefined, return an empty string
        }
      }
    } else if (type === 'date') {
      const formattedDate = new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return formattedDate;
    }
    return value;
  };

  handleFolderClick = (event, row, familyId, unifiedSongId) => {
    // console.log(familyId)
    // console.log(event.currentTarget)
    console.log(event.currentTarget)
    this.setState({
      folderMenuAnchor: event.currentTarget,
      thisRow: row,
      // trackedIdMRC: familyId,
      // trackedIdSodatone: unifiedSongId
    });

    console.log('folder click done')
  };

  handleFolderMenuItemClick = (folderId, row, familyId, unifiedSongId, spotifyId, artistId, folderName) => {
    console.log('hahah')
    // const { endpoint } = this.props;
    // thisRow?.FOLDERIDS?.includes(folder.FOLDER_ID);
    console.log(row?.FOLDERIDS?.includes(folderId))
    const { authState } = this.props;
    const { fetchData } = this.props;
    const {folderType} = this.props;
    const accessToken = authState.accessToken.accessToken;
    if (folderId === 'newFolder') {
      // Handle the logic to create a new folder with the given folderName
      console.log('Create new folder:', folderName);

      // Perform the API call to add the new folder to the database
      const requestData = {
        user_id: 1, // Replace with the actual user ID value
        folder_name: folderName,
      };

      console.log(folderName);

      fetch(`${endpoint}/add_folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestData), // Send the requestData object as the request body
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('New folder added:', result);
          this.fetchFolders()
          // Handle any necessary state updates or UI changes
        })
        .catch((error) => {
          console.error('Error adding new folder:', error);
          // Handle the error case
        });



    } else {
      // Handle the logic to add the trackedIdMRC to the selected folder
      console.log('Add trackedIdMRC to folder:', folderId);
      console.log('Family ID:', familyId);
      console.log('Unified Song ID:', unifiedSongId);

      if (row?.FOLDERIDS?.includes(folderId)) {
        let user_id = 1;
        fetch(`${endpoint}/delete_from_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId, artistId, folderType }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family deleted from folder:', result);
            // Handle any necessary state updates or UI changes
            fetchData(true, true)
          })
          .catch((error) => {
            console.error('Error deleting family from folder:', error);
            // Handle the error case
          });

      } else {
        let user_id = 1;

        fetch(`${endpoint}/add_to_folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ user_id, folderId, familyId, unifiedSongId, spotifyId, artistId, folderType }),
        })
          .then((response) => response.json())
          .then((result) => {
            console.log('Family added to folder:', result);
            fetchData(true, true)
            // Handle any necessary state updates or UI changes
          })
          .catch((error) => {
            console.error('Error adding family to folder:', error);
            // Handle the error case
          });
        
      }

    }

    // Close the folder menu
    this.setState({
      folderMenuAnchor: null,
    });

  };
  handleExpandRow = (rowId) => {
    console.log(this.expandedRow)
    console.log(rowId)
    // if (rowId.FAMILY_ID !== null) {
    //   this.setState((prevState) => ({
    //     expandedRow: prevState.expandedRow === rowId ? null : rowId,
    //   }));
    // }
    this.setState((prevState) => ({
      expandedRow: prevState.expandedRow === rowId ? null : rowId,
    }));

  };


  render() {
    const { folderMenuAnchor, thisRow,expandedRow } = this.state;
    const { loading, data, columns, filters, sort, table_dir, handleSort, handleFilterChange, folders, ExpandedRowComp, id_column } = this.props;
    const visibleColumns = columns.filter((column) => !column.hide);
    
    return (
      <div style={{ position: 'relative', height: '90vh', overflow: 'auto' }} onScroll={this.handleScroll}>
        <div style={{ position: 'sticky', top: '0', zIndex: 50, background: '#fff' }}>
          <Table aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                {visibleColumns.map((column, index) => (
                  <TableHeaderColumn
                    column={column}
                    index={index}
                    handleSort={handleSort}
                    sortColumn={sort.key}
                    sortOrder={sort.order}
                    handleFilterChange={handleFilterChange}
                    filters={filters}
                    table_dir={table_dir}
                  />
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </div>
        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
          <TableBody>
          {loading && data.length === 0 ? (
                // Display loading message when loading is true
                <TableRow>
                  <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'center' }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (data.map((row, rowIndex) => (
              <React.Fragment key={row.id}>
                <TableRow key={rowIndex} onClick={() => this.handleExpandRow(row)} ref={rowIndex === data.length - 1 ? this.lastRowRef : null }>
                  {visibleColumns.map((column, index) => (
                    <React.Fragment key={column.key}>
                      {!column.track && (
                        <TableCell
                          key={column.key}
                          className={column.fix ? 'pinned-column' : ''}
                          style={{
                            width: column.fix ? '118px' : '100px',
                            position: column.fix ? 'sticky' : 'relative',
                            left: column.fix ? `${index * 150}px` : 'auto',
                            zIndex: column.fix ? 1 : 'auto',
                            background: column.fix ? '#fff' : 'inherit',
                          }}
                        >
                          {this.formatValue(row[column.key], column.type, column.format)}
                        </TableCell>
                      )}
                      {column.track && (
                        <TableCell
                          key={column.key}
                          className={column.fix ? 'pinned-column' : ''}
                          style={{
                            width: column.fix ? '118px' : '100px',
                            position: column.fix ? 'sticky' : 'relative',
                            left: column.fix ? `${index * 150}px` : 'auto',
                            zIndex: column.fix ? 1 : 'auto',
                            background: column.fix ? '#fff' : 'inherit',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                              {this.formatValue(row[column.key], column.type, column.format)}
                            </div>
                            <div style={{ position: 'relative', display: 'inline-flex', marginRight: '8px' }}>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ padding: '4px', minWidth: 'unset', width: '24px', height: '24px' }}
                                onClick={(event) => {
                                  this.handleFolderClick(event, row, row.FAMILY_ID, row.UNIFIED_SONG_ID, row.FOLDERIDS);
                                  event.stopPropagation();
                                }}
                              >
                                <FolderIcon style={{ fontSize: '16px' }} />
                              </Button>
                              {folderMenuAnchor && ((this.props.folderType === 'songs' && thisRow.FAMILY_ID === row.FAMILY_ID && thisRow.UNIFIED_SONG_ID === row.UNIFIED_SONG_ID)
                              || (this.props.folderType === 'artists' && thisRow.SODATONE_ARTIST_ID=== row.SODATONE_ARTIST_ID ))
                              && (
                                <ClickAwayListener onClickAway={(event) => {
                                  const isMenuClick = event.target.closest('#simple-menu');
  
                                  // If the click was within the menu, return early to prevent closing the menu
                                  if (isMenuClick) {
                                    return;
                                  }
  
                                  this.setState({ folderMenuAnchor: null }); // Close the menu
                                }}>
                                  <Menu
                                    id="simple-menu-"
                                    anchorEl={folderMenuAnchor}
                                    open={Boolean(folderMenuAnchor)}
                                    onClose={() => this.setState({ folderMenuAnchor: null })}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    {folders.map((folder) => (
                                      <MenuItem
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          this.handleFolderMenuItemClick(folder.FOLDER_ID, row, row.FAMILY_ID, row.UNIFIED_SONG_ID, row.SPOTIFY_ID, row.SODATONE_ARTIST_ID, null);
                                        }}
                                        key={folder.FOLDER_ID}
                                        value={folder.FOLDER_NAME}
                                      >
                                        {folder.FOLDER_NAME}
                                        {row?.FOLDERIDS?.includes(folder.FOLDER_ID) && <CheckIcon style={{ marginLeft: 'auto' }} />}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </ClickAwayListener>
                              )}
                            </div>
                          </div>
                        </TableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
                {(ExpandedRowComp && expandedRow) === row && (
                  <TableRow className="expanded-row" style={{ zIndex: 2, width: '100vw' }}>
                    <TableCell colSpan={visibleColumns.length} style={{ padding: 0, backgroundColor: '#fff', width: '100vw' }}>
                      <div style={{ position: 'sticky', left: 0, width: '100vw', overflow: 'hidden' }}>
                        <ExpandedRowComp endpoint={endpoint} row={expandedRow} id_column={id_column} />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )))}
            {loading && data.length !== 0 && (
              // Display loading message when loading is true
              <TableRow>
                <TableCell colSpan={visibleColumns.length} style={{ textAlign: 'center' }}>
                  Loading...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
  
}
export default withOktaAuth(DynamicTable);
