

import React, { Component } from 'react';
// import DataLoader from './DataLoader';
import DynamicTable from './DynamicTable';
import './App.css';
import ExpandedRow from './ExpandedRow';
import { endpoint } from './mainEndpoint';
import ColumnSettings from './ColumnSettings';
import SettingsDialog from './SettingsDialog';
import {
    TextField, Checkbox, Table,
    TableHead, TableBody, TableRow, TableCell, IconButton,
    TablePagination, Button, Menu, MenuItem, Typography,
    FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { Sort } from '@material-ui/icons';
import Table_All from './Table_All';
import ExportDataArtists from './ExportDataArtists';
import FolderComponent from './FolderComponent';
import SavedFiltersColumnsComponent from './SavedFiltersColumnsComponent'
import PercentileWeight from './PercentileWeight';
import ScheduleEmail from './ScheduleEmail';
import AnalyzeData from './AnalyzeData';
class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
          table_dir: 'artist_table',
          columns: [
            // { key: 'ARTIST', displayName: 'Artist', type: 'category', format:'category', fix: true, optionsMatchingKey:'SODATONE_ARTIST_ID', table:'ALL_ARTIST_DATA'},
            { key: 'ARTIST', displayName: 'Artist', type: 'string',fix: true,track: true,linkKey:'SODATONE_URL'},
            {key:'SODATONE_URL',hide:true,alwaysHide:true},
            { key: 'SODATONE_ARTIST_ID', displayName: 'Sodatone Artist ID', type: 'number',format :'literal', fix: true, hide:true, alwaysHide: true},
            { key: 'LABEL', displayName: 'Label', type: 'category', format:'category', optionsMatchingKey:'LABEL', table:'ALL_ARTIST_DATA'},
            { key: 'COUNTRY', displayName: 'Country', type: 'category', format:'category', optionsMatchingKey:'COUNTRY', table:'ALL_ARTIST_DATA'},

            // { key: 'SODATONE_GENRE', displayName: 'Genre', type: 'category', format:'category', optionsMatchingKey:'SODATONE_GENRE', table:'ALL_ARTIST_DATA'},
            { key: 'SODATONE_GENRE', displayName: 'Genre', type: 'string'},


            { key: 'LIST_GENRES', displayName: 'Spotify Genres', type: 'string'},
            { key: 'NET_PERCENTILE', displayName: 'Net Percentile', type: 'number', format :'number'},

    
            { key: 'EST_BW_AUDIO_STREAMS', displayName: 'Estimated Building Week Audio Streams', type: 'number', format :'number'},
            { key: 'BW_PCT_CHANGE', displayName: 'Percent Change Building Week', type: 'number',format :'percentChange'},
    
            { key: 'AUDIO_STREAMS_CURRENT_VALUE', displayName: 'Last Week Audio Streams', type: 'number', format :'number'},
            { key: 'AUDIO_STREAMS_PCT_CHANGE', displayName: 'Percent Change Last Week', type: 'number',format :'percentChange'},
            
            { key: 'SPL_CURRENT_VALUE', displayName: 'Streams Per Listener', type: 'number', format :'number'},
            // { key: 'SPL_PREVIOUS_VALUE', displayName: 'Streams Per Listener 7 Days Ago', type: 'number', format :'number'},
    
            { key: 'STREAMS_PERCENTILE', displayName: 'Streams Percentile', type: 'number', format :'number', percentile:'true'},
    
            
            { key: 'SPOTIFY_FOLLOWERS', displayName: 'Spotify Followers', type: 'number',format :'number'},
            { key: 'SP_FOL_PCT_CHANGE', displayName: '7 Day Percent Change Spotify Followers', type: 'number',format :'percentChange'},
            { key: 'SP_FOL_PERCENTILE', displayName: 'Spotify Follower Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'INSTAGRAM_FOLLOWERS', displayName:'Instagram Followers', type: 'number',format :'number'},
            { key: 'IG_FOL_PCT_CHANGE', displayName: '7 Day Percent Change Instagram Followers', type: 'number',format :'percentChange'},
            { key: 'IG_FOL_PERCENTILE', displayName: 'Instagram Follower Growth Percentile', type: 'number',format :'number',percentile:'true'},
            { key: 'IG_ENG', displayName: 'Instagram Engagement Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'TIKTOK_FOLLOWERS', displayName:'TikTok Followers', type: 'number',format :'number'},
            { key: 'TT_FOL_PCT_CHANGE', displayName: '7 Day Percent Change TikTok Followers', type: 'number',format :'percentChange'},
            { key: 'TT_FOL_PERCENTILE', displayName: 'TikTok Follower Growth Percentile', type: 'number',format :'number',percentile:'true'},
            { key: 'TT_ENG', displayName: 'TikTok Engagement Percentile', type: 'number',format :'number',percentile:'true'},
            { key: 'TT_CREATES', displayName: 'Last 7 Days TikTok Creates', type: 'number', format :'number'},
            { key: 'TT_CREATES_PERCENTILE', displayName: 'TikTok Creates Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'TT_LIKES', displayName: 'Last 7 Days TikTok Likes', type: 'number', format :'number'},
            { key: 'TT_LIKES_PERCENTILE', displayName: 'TikTok Likes Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
    
            { key: 'SHAZAM_TAGS', displayName: 'Last 7 Days Shazam Tags', type: 'number', format :'number'},
            { key: 'SHAZAM_TAGS_PERCENTILE', displayName: 'Shazam Tags Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'SOUNDCLOUD_PLAYS', displayName: 'Last 7 Days Soundcloud Plays', type: 'number', format :'number'},
            { key: 'SOUNDCLOUD_PLAYS_PERCENTILE', displayName: 'Soundcloud Plays Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'AUDIOMACK_PLAYS', displayName: 'Last 7 Days Audiomack Plays', type: 'number', format :'number'},
            { key: 'AUDIOMACK_PLAYS_PERCENTILE', displayName: 'Audiomack Plays Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'YOUTUBE_SUBSCRIBERS', displayName: 'YouTube Subscribers', type: 'number', format :'number'},
            { key: 'YT_SUB_PCT_CHANGE', displayName: '7 Day Percent Change YouTube Subscribers', type: 'number',format :'percentChange'},
            { key: 'YT_SUB_PERCENTILE', displayName: 'YouTube Subscriber Growth Percentile', type: 'number',format :'number',percentile:'true'},
    
            { key: 'MOST_RECENT_RELEASE', displayName: 'Most Recent Release Date', type: 'date'},
            { key: 'FIRST_RELEASE', displayName: 'First Release Date', type: 'date'},
             
          ],
          filters :[{
            column: 'FOLDER_ID',
            operator: 'FOLDER',
            value: -1
        }],
        
          sort: {key: 'STREAMS_PERCENTILE', type:'number',order: 'desc'},
          data:[],
          folders:[],
          folderType:'artists',
          selectedFolder: -1,
          selectedFilterColumn:-1
        };
        

    }
    handleApplyColumnSettings = (columnHideSettings) => {
        // Separate pinned and alwaysHide columns
        const pinnedColumns = [];
        const alwaysHideColumns = [];
        const otherColumns = [];
        console.log(columnHideSettings)
        this.state.columns.forEach((setting) => {
          const correspondingColumn = columnHideSettings.find((column) => column.key === setting.key);
          if (setting) {
            if (setting.fix) {
              pinnedColumns.push({ ...setting});
            } else if (setting.alwaysHide) {
              alwaysHideColumns.push({ ...setting });
            } else {
            //   otherColumns.push({ ...correspondingColumn });
            }
          }
        });
        console.log(pinnedColumns)
        console.log(alwaysHideColumns)
        console.log(otherColumns)
        // Combine columns with the desired order
        const updatedColumns = [...pinnedColumns, ...alwaysHideColumns, ...columnHideSettings];
      
        // Update the state with the modified columns
        this.setState({
          columns: updatedColumns,
        });
      };
      setSelectedFilterColumns = (folderId) => {
        this.setState((prevState) => {
            const { filters } = prevState;
            const columnKeyToUpdate = 'FOLDER_ID';
            return {
              // filters: [...filters.filter((filter) => filter.column !== columnKeyToUpdate), existingColumn],
              // pageNumber: 1,
              selectedFilterColumn: folderId,
          };
            // let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
    
            // if (existingColumn) {
            //     existingColumn.value = folderId;
    
            //     // Return the updated state
                
            // }
            
    
            return prevState; // No changes needed if the column is not found
        });
    };
    setSelectedFolder = (folderId) => {
        // this.setState({ selectedFolder: folderId });
        const { filters } = this.state;
        console.log('aa')
        console.log(filters)
        const columnKeyToUpdate = 'FOLDER_ID';
    
        let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
        console.log(existingColumn)
        if (existingColumn) {
          // Set the existingColumn.hide as the inverse of its current value
          existingColumn.value = folderId;
    
          // Update the state with the modified columns
          
          this.setState(
            {
              filters: [...filters.filter((filter) => filter.column !== columnKeyToUpdate), existingColumn],
              pageNumber: 1
            },
            // this.fetchData
          );
        } else {
          // Add a new filter with column 'FOLDER_ID'
          const newFilter = {
              column: 'FOLDER_ID',
              operator: 'FOLDER', // You can set the operator as per your requirement
              value: folderId
          };
      
          // Update the state with the new filter
          this.setState(
              {
                  filters: [...filters, newFilter],
                  pageNumber: 1
              },
              // this.fetchData
          );
      }
        console.log(this.state.filters)
      };
      setData = (data) => {
        console.log('Setting data in Table_All:', data);
        this.setState({ data: data });
      };
      setFilters = (filters) => {
        this.setState({ filters: filters });
      };
      setColumns = (columns) => {
        this.setState({columns: columns });
      };
      

      setSort = (sort) => {
        this.setState({ sort: sort });
      };
      setFolders = (folders) => {
        this.setState({ folders: folders });
      };
      handleSearchChange = (event) => {
        const searchValue = event.target.value;
        this.setState(
          {
            searchValue,
          },
        );
      };

    render() {
        // const endpoint = 'http://localhost:3500'; // Replace with your desired endpoint
        // const { columns, filters,table_dir,sort } = this.state;
        const { columns, filters,table_dir,sort,searchValue, selectedFolder,data,folders,folderType,selectedFilterColumn} = this.state;
        console.log(filters)
        console.log(selectedFilterColumn)
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft:'7px'}}>
                
                <TextField
                  type="text"
                  placeholder="Search Song or Artist..."
                  value={searchValue}
                  onChange={this.handleSearchChange}
                />
                
                <div style={{ marginLeft: '10px' }}>
                <FolderComponent
                setSelectedFolder={this.setSelectedFolder}
                setFolders={this.setFolders}
                folderType={folderType}
                />
                </div>
                <div style={{ marginLeft: '10px' }}>
                <SavedFiltersColumnsComponent 
                columns={columns}
                filters={filters}
                sort={sort}
                setColumns={this.setColumns}
                setSort={this.setSort}
                setFilters={this.setFilters}
                setSelectedFilterColumns = {this.setSelectedFilterColumns}
                folderType={folderType}
                />
                </div>

                <div style={{ marginLeft: '10px' }}>
                <SettingsDialog
                columns={columns}
                handleApplyColumnSettings={this.handleApplyColumnSettings}
                
                />
                </div>
                <div style={{ marginLeft: '10px' }}>
                <PercentileWeight columns={columns}
                filters={filters} 
                setFilters={this.setFilters}
                setSort={this.setSort}
                />
                </div>

                <div style={{ marginLeft: '10px' }}>
                <ExportDataArtists
                columns={columns}
                filters={filters}
                table_dir={table_dir}
                sort={sort}
                // selectedFolder={selectedFolder}
                data={data}
                folders={folders}
                table_endpoint={'artist_table'}
                />
                </div>

                <div style={{ marginLeft: '10px' }}>
                <ScheduleEmail
                // columns={columns}
                selectedFilterColumn={selectedFilterColumn}
                // handleApplyColumnSettings={this.handleApplyColumnSettings}
                
                />
                </div>
                <div style={{ marginLeft: '10px' }}>
                <AnalyzeData
                    columns={columns}
                    // filters={filters}
                    // table_dir={table_dir}
                    // sort={sort}
                    // selectedFolder={selectedFolder}
                    data={data}
                    chart_dotTitle={'ARTIST'}
                    // folders={folders}
                    />
                  </div>
                

                </div>
                <Table_All
                    columns={columns}
                    filters={filters}
                    table_dir={table_dir}
                    sort= {sort} 
                    
                    selectedFolder={selectedFolder} 
                    setData= {this.setData}
                    folders={folders}
                    setFilters={this.setFilters}
                    setSort={this.setSort}
                    searchValue={searchValue}
                    folderType={folderType}
                    />
            </div>
        );

    }
}

export default withOktaAuth(App);


