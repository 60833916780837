import React, { useState } from 'react';
import SettingsIcon from '@material-ui/icons/Settings'; // Import the settings icon
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DynamicTable from './DynamicTable';
import ExpandedRow from './ExpandedRow';
import { endpoint } from './mainEndpoint';
import ColumnSettings from './ColumnSettings';

const SettingsDialog = ({ columns, handleApplyColumnSettings }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  return (
    <div>
<Button onClick={handleOpenDialog} style={{ cursor: 'pointer' }} variant="outlined" color="primary">
  Edit Columns
</Button>
      <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="lg">
        <DialogTitle style={{ width: '80vw' }}>Show / Hide</DialogTitle>
        <DialogContent>
          <ColumnSettings columns={columns} handleApplyColumnSettings={handleApplyColumnSettings} closeDialog={handleCloseDialog} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingsDialog;



