import React, { Component } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { endpoint } from './mainEndpoint';
import { withOktaAuth } from '@okta/okta-react';

class ScheduleEmailNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      editDialogOpen: false,
      hour: '',
      minute: '',
      dayOfWeek: '',
      recipients: '',
      isScheduledSuccessfully: false,
      schedules: [],
      editingSchedule: null,
      scheduleTable: props.scheduleTable,
      filterTable: props.filterTable,
      reportType: 'Table Export',
      includeBuildingWeekStreams: null
    };
  }

  componentDidMount() {
    this.fetchSchedules();
  }

  fetchSchedules = async () => {
    const { authState } = this.props;
    const {scheduleTable, filterTable} = this.state;

    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/get_user_schedules`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            scheduleTable,
            filterTable
          }),
      });
      if (response.ok) {
        const schedules = await response.json();
        this.setState({ schedules });
      } else {
        console.error('Error fetching schedules');
      }
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  handleOpenDialog = () => {
    this.fetchSchedules();
    this.setState({ isOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isOpen: false });
  };

  handleSuccessDialogClose = () => {
    this.setState({ isOpen: false, isScheduledSuccessfully: false });
  };

  handleSubmit = async () => {
    const { hour, minute, dayOfWeek, recipients, scheduleTable, reportType,includeBuildingWeekStreams } = this.state;
    const { authState, selectedFilterColumn } = this.props;
    let includeBuildingWeekStreams1 = includeBuildingWeekStreams;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/schedule_artist_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          hour,
          minute,
          dayOfWeek,
          recipients,
          selectedFilterColumn,
          scheduleTable,
          reportType,
          includeBuildingWeekStreams: reportType === 'Table Export' ? null : includeBuildingWeekStreams,
        }),
      });

      if (response.ok) {
        this.setState({ isScheduledSuccessfully: true });
      } else {
        console.error('Error sending schedule email request');
      }
    } catch (error) {
      console.error('Error sending schedule email request:', error);
    }
  };

  handleDelete = async (id) => {
    const { authState } = this.props;
    const {scheduleTable} = this.state;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/delete_email_schedule`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ id, scheduleTable}),
      });
      if (response.ok) {
        this.fetchSchedules();
      } else {
        console.error('Error deleting email schedule');
      }
    } catch (error) {
      console.error('Error deleting email schedule:', error);
    }
  };

  handleEditOpen = (schedule) => {
    this.setState({
      editDialogOpen: true,
      editingSchedule: schedule,
      hour: schedule.HOUR,
      minute: schedule.MINUTE,
      dayOfWeek: schedule.DAY_OF_WEEK,
      recipients: schedule.RECIPIENTS,
      reportType: schedule.REPORT_TYPE,
      includeBuildingWeekStreams: schedule.BW_OPTION
    });
  };

  handleEditClose = () => {
    this.setState({ editDialogOpen: false, editingSchedule: null });
  };

  handleEditSubmit = async () => {
    const { hour, minute, dayOfWeek, recipients,scheduleTable, editingSchedule, reportType,includeBuildingWeekStreams } = this.state;
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    try {
      const response = await fetch(`${endpoint}/update_email_schedule`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          id: editingSchedule.ID,
          hour,
          minute,
          dayOfWeek,
          recipients,
          scheduleTable,
          reportType,
          includeBuildingWeekStreams: reportType === 'Table Export' ? null : includeBuildingWeekStreams
        }),
      });

      if (response.ok) {
        this.fetchSchedules();
        this.handleEditClose();
      } else {
        console.error('Error updating schedule');
      }
    } catch (error) {
      console.error('Error updating schedule:', error);
    }
  };

  render() {
    const { isOpen, schedules, isScheduledSuccessfully, editDialogOpen, hour, minute, dayOfWeek, recipients, includeBuildingWeekStreams, reportType,scheduleTable} = this.state;
    const { selectedFilterColumn } = this.props;
    
    return (
      <div>
        <Button onClick={this.handleOpenDialog} variant="outlined" color="primary">
          Schedule
        </Button>
        <Dialog open={isOpen} onClose={this.handleCloseDialog} maxWidth="lg">
          <DialogTitle>Schedule</DialogTitle>
          <DialogContent>
  {selectedFilterColumn === -1 ? (
    <p>Please select a saved columns / filters</p>
  ) : (
    <>
      <p>Create a new schedule for the selected saved columns / filters (Use Eastern Standard Time)</p>
      <p>Hour 0 - 23, Minute 0 - 59</p>

      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '10px' }}>
          <TextField
            label="Hour"
            type="number"
            value={hour}
            onChange={(e) => this.setState({ hour: parseInt(e.target.value) })}
            inputProps={{ min: 0, max: 23 }}
          />
        </div>
        <div style={{ marginRight: '20px' }}>
          <TextField
            label="Minute"
            type="number"
            value={minute}
            onChange={(e) => this.setState({ minute: parseInt(e.target.value) })}
            inputProps={{ min: 0, max: 59 }}
          />
        </div>
        <div style={{ marginRight: '10px', marginTop: 'auto' }}>
          <Select
            value={dayOfWeek}
            onChange={(e) => this.setState({ dayOfWeek: e.target.value })}
            displayEmpty
            style={{ marginTop: 'auto' }}
          >
            <MenuItem value="" disabled>
              Select Day of Week
            </MenuItem>
            <MenuItem value={6}>Sunday</MenuItem>
            <MenuItem value={0}>Monday</MenuItem>
            <MenuItem value={1}>Tuesday</MenuItem>
            <MenuItem value={2}>Wednesday</MenuItem>
            <MenuItem value={3}>Thursday</MenuItem>
            <MenuItem value={4}>Friday</MenuItem>
            <MenuItem value={5}>Saturday</MenuItem>
          </Select>
        </div>
        <div style={{ marginRight: '10px', width: '300px' }}>
          <TextField
            label="Recipients"
            fullWidth
            placeholder="Enter email addresses, comma-separated"
            value={recipients}
            onChange={(e) => this.setState({ recipients: e.target.value })}
          />
        </div>

        {scheduleTable === 'EMAIL_SCHEDULE_ROSTER' && (
        <div style={{ marginRight: '10px', marginTop: 'auto' }}>
            <Select
            value={reportType}
            onChange={(e) => this.setState({ reportType: e.target.value })}
            displayEmpty
            style={{ marginTop: 'auto' }}

            >
            <MenuItem value="" disabled>
                Select Report Type
            </MenuItem>
            {/* <MenuItem value="Formatted Stream Report">Formatted Stream Report</MenuItem> */}
            <MenuItem value="Table Export">Table Export</MenuItem>
            </Select>
        </div>
        
          
        )}
        {reportType === 'Formatted Stream Report' && (
            <div style={{ marginRight: '10px', marginTop: 'auto' }}>

                <Select
                  value={includeBuildingWeekStreams}
                  onChange={(e) => this.setState({ includeBuildingWeekStreams: e.target.value })}
                  displayEmpty

                  style={{ marginTop: 'auto' }}

                >
                  <MenuItem value="" disabled>
                    Include Building Week Streams
                  </MenuItem>
                  <MenuItem value="Building Week Streams">Building Week Streams</MenuItem>
                  <MenuItem value="Estimated Building Week Streams">Estimated Building Week Streams</MenuItem>
                  <MenuItem value="No Building Week Streams">No Building Week Streams</MenuItem>
                </Select>
            </div>
            )}

        <div>
          <Button onClick={this.handleSubmit} color="primary">Submit</Button>
        </div>
      </div>
    </>
  )}

            <h3>Current Scheduled Emails</h3>
            <TableContainer style={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                <TableRow>
                    <TableCell>Filter/Columns</TableCell>
                    <TableCell>Hour</TableCell>
                    <TableCell>Minute</TableCell>
                    <TableCell>Day of Week</TableCell>
                    {scheduleTable === 'EMAIL_SCHEDULE_ROSTER' && (
                    <>
                        <TableCell>Report Type</TableCell>
                        <TableCell>BW Option</TableCell>
                    </>
                    )}
                    <TableCell>Recipients</TableCell>
                    
                    <TableCell style={{ position: 'sticky', right: 0, background: '#fff' }}>Actions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {schedules.map((schedule) => (
                    <TableRow key={schedule.ID}>
                    <TableCell>{schedule.NAME}</TableCell>
                    <TableCell>{schedule.HOUR}</TableCell>
                    <TableCell>{schedule.MINUTE}</TableCell>
                    <TableCell>{schedule.DAY_OF_WEEK}</TableCell>
                    {scheduleTable === 'EMAIL_SCHEDULE_ROSTER' && (
                        <>
                        <TableCell>{schedule.REPORT_TYPE || 'N/A'}</TableCell>
                        <TableCell>{schedule.BW_OPTION || 'N/A'}</TableCell>
                        </>
                    )}
                    <TableCell>{schedule.RECIPIENTS}</TableCell>
                   
                    <TableCell style={{ position: 'sticky', right: 0, background: '#fff' }}>
                        <Button onClick={() => this.handleEditOpen(schedule)} color="primary">Edit</Button>
                        <Button onClick={() => this.handleDelete(schedule.ID)} color="secondary">Delete</Button>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Edit Schedule Dialog */}
        <Dialog open={editDialogOpen} onClose={this.handleEditClose} maxWidth="lg">
  <DialogTitle>Edit Schedule</DialogTitle>
  <DialogContent style={{ minWidth: '500px', maxHeight: '500px', overflowY: 'auto' }}>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <TextField
      label="Hour"
      type="number"
      value={hour}
      onChange={(e) => this.setState({ hour: parseInt(e.target.value) })}
      inputProps={{ min: 0, max: 23 }}
      style={{ marginBottom: '10px' }}
    />
    <TextField
      label="Minute"
      type="number"
      value={minute}
      onChange={(e) => this.setState({ minute: parseInt(e.target.value) })}
      inputProps={{ min: 0, max: 59 }}
      style={{ marginBottom: '10px' }}
    />
    <Select
      value={dayOfWeek}
      onChange={(e) => this.setState({ dayOfWeek: e.target.value })}
      displayEmpty
      style={{ marginBottom: '10px' }}
    >
      <MenuItem value="" disabled>Select Day of Week</MenuItem>
      <MenuItem value={6}>Sunday</MenuItem>
      <MenuItem value={0}>Monday</MenuItem>
      <MenuItem value={1}>Tuesday</MenuItem>
      <MenuItem value={2}>Wednesday</MenuItem>
      <MenuItem value={3}>Thursday</MenuItem>
      <MenuItem value={4}>Friday</MenuItem>
      <MenuItem value={5}>Saturday</MenuItem>
    </Select>
    <TextField
      label="Recipients"
      value={recipients}
      onChange={(e) => this.setState({ recipients: e.target.value })}
      placeholder="Enter email addresses, comma-separated"
      fullWidth
      style={{ marginBottom: '10px' }}
    />

    {scheduleTable === 'EMAIL_SCHEDULE_ROSTER' && (
      <>
        <Select
          value={reportType}
          onChange={(e) => this.setState({ reportType: e.target.value })}
          displayEmpty
          style={{ marginBottom: '10px' }}
        >
          <MenuItem value="" disabled>Select Report Type</MenuItem>
          <MenuItem value="Formatted Stream Report">Formatted Stream Report</MenuItem>
          <MenuItem value="Table Export">Table Export</MenuItem>
        </Select>

        {reportType === 'Formatted Stream Report' && (
          <Select
            value={includeBuildingWeekStreams}
            onChange={(e) => this.setState({ includeBuildingWeekStreams: e.target.value })}
            displayEmpty
            style={{ marginBottom: '10px' }}
          >
            <MenuItem value="" disabled>Include Building Week Streams</MenuItem>
            <MenuItem value="Building Week Streams">Building Week Streams</MenuItem>
            <MenuItem value="Estimated Building Week Streams">Estimated Building Week Streams</MenuItem>
            <MenuItem value="No Building Week Streams">No Building Week Streams</MenuItem>
          </Select>
        )}
      </>
    )}
  </div>
</DialogContent>

  <DialogActions>
    <Button onClick={this.handleEditClose}>Cancel</Button>
    <Button onClick={this.handleEditSubmit} color="primary">Save</Button>
  </DialogActions>
</Dialog>


        {/* Success Dialog */}
        <Dialog open={isScheduledSuccessfully} onClose={this.handleSuccessDialogClose}>
          <DialogTitle>Report scheduled successfully</DialogTitle>
          <DialogContent>
            <p>Your report has been scheduled successfully.</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSuccessDialogClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withOktaAuth(ScheduleEmailNew);
