import React from 'react';
import { InputBase, makeStyles, fade, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { endpoint } from './mainEndpoint.js';
import { withOktaAuth } from '@okta/okta-react';

// import { useNavigate } from 'react-router-dom';

const styles = {
  search: {
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: fade('#FFFFFF', 0.15),
    '&:hover': {
      backgroundColor: fade('#FFFFFF', 0.25),
    },
    marginLeft: 2,
    width: 'auto',
  },
  searchIcon: {
    padding: '0px 10px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'white', // Set text color to white
  },
  inputInput: {
    padding: '3px 4px',
    paddingLeft: '40px',
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    width: '100%',
    color: 'white', // Set text color to white
  },
  suggestionList: {
    padding: 0,
    position: 'absolute',
    top: '48px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: 9999, // Increase the z-index value to ensure it appears in front
    color: '#4554B7', // Set the text color to #4554B7
  },
  listItem: {
    paddingLeft: '16px',
  },
};

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      suggestions: [],
      loading: false,
      isInputFocused: false, // Track input focus state
    };

    this.debounceTimer = null;
  }

  fetchSuggestions(userInput) {
    clearTimeout(this.debounceTimer);
    this.setState({ loading: true });
    const { authState } = this.props;
    const accessToken = authState.accessToken.accessToken;
    const headers = {
       Authorization: `Bearer ${accessToken}`
     };

    this.debounceTimer = setTimeout(() => {
      fetch(`${endpoint}/search?query=${userInput}`, {
        headers: headers // Add the headers to the fetch options
      })
        .then((response) => response.json())
        .then((data) => this.setState({ suggestions: data, loading: false }));
    }, 300);
  }

  handleInputChange(event) {
    const userInput = event.target.value;
    this.setState({ query: userInput });

    if (!userInput) {
      // Hide suggestions when there's no input
      this.setState({ suggestions: [] });
    } else {
      this.fetchSuggestions(userInput);
    }
  }

  handleSuggestionClick(artistId) {
    console.log('sug');
    console.log(artistId);
    const { navigate } = this.props;
    navigate(`/artist/${artistId}`);

    // Close the suggestions list after clicking
    this.setState({ suggestions: [] });
    this.setState({ query: '' });
  }

  // Handle input focus events
  handleInputFocus = () => {
    this.setState({ isInputFocused: true });
  };

  handleInputBlur = () => {
    this.setState({ isInputFocused: false });
  };

  render() {
    const { query, suggestions, loading, isInputFocused } = this.state;
    console.log(suggestions);
  
    return (
      <div style={styles.search}>
        <div style={styles.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search..."
          style={styles.inputInput}
          inputProps={{ 'aria-label': 'search' }}
          value={query}
          onChange={(event) => this.handleInputChange(event)}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
        />
        {loading && <CircularProgress size={20} style={{ position: 'absolute', top: '8px', right: '12px', color: 'white' }} />}
        {isInputFocused && suggestions.length > 0 && (
          <List style={styles.suggestionList}>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {suggestions.map((suggestion) => (
            <ListItem
              button
              key={suggestion.ID}
              style={styles.listItem}
              onMouseDown={() => this.handleSuggestionClick(suggestion.ID)}
            >
              <ListItemText primary={suggestion.NAME} />
            </ListItem>
          ))}
           </div>
        </List>
        )}
      </div>
    );
  }
  
}

export default withOktaAuth(SearchBar);
