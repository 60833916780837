import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from '@material-ui/core';
import ScatterPlot from './ScatterPlot';
import BarPlot from './BarPlot';
class AnalyzeData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      step: 1,
      selectedRows: [],
      graphType: 'bar',
      xKeyName: '', // State to store the selected X-axis key
      xKeyName1: '', // State to store the selected X-axis key
      yKeyName: '', // State to store the selected Y-axis key
      dotSize: '', // State to store the selected dot size key
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, step: 1 });
  };

  handleNext = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleBack = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  handleRowSelection = (index) => {
    const { selectedRows } = this.state;
    const updatedRows = selectedRows.includes(index)
      ? selectedRows.filter((id) => id !== index)
      : [...selectedRows, index];
  
    this.setState({ selectedRows: updatedRows });
  };
  

  handleGraphTypeChange = (event) => {
    this.setState({ graphType: event.target.value });
  };

  handleAxisColumnChange = (axis, column) => {
    this.setState({ [`${axis}Column`]: column });
  };
  handleXKeyChange = (event) => {
    this.setState({ xKeyName: event.target.value });
  };
  handleXKeyChange1 = (event) => {
    this.setState({ xKeyName1: event.target.value });
  };

  handleYKeyChange = (event) => {
    this.setState({ yKeyName: event.target.value });
  };

  handleDotSizeChange = (event) => {
    this.setState({ dotSize: event.target.value });
  };

  handleSelectAll = () => {
    const { data } = this.props;
    this.setState((prevState) => ({
      selectedRows: prevState.selectedRows.length === data.length ? [] : data.map((_, index) => index),
    }));
  };


  render() {
    const { open, step, selectedRows, graphType, xAxisColumn,xKeyName,xKeyName1,yKeyName,dotSize, yAxisColumn, dotSizeColumn} = this.state;
    const { data, columns } = this.props;
    const isAllSelected = selectedRows.length === data.length;
    const isIndeterminate = selectedRows.length > 0 && selectedRows.length < data.length;

    return (
      <div>
        <Button variant="outlined" color="primary" onClick={this.handleOpen}>Analyze Data
                        </Button>
        <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth="lg">
          <DialogTitle>Analyze Data</DialogTitle>
          <DialogContent>
            {step === 1 && (
              <div>
              <h2>Select Rows</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Select</TableCell> */}
                    <TableCell>
                    <Checkbox
                      indeterminate={isIndeterminate}
                      checked={isAllSelected}
                      onChange={this.handleSelectAll}
                    />
                  </TableCell>
                    {/* Render table headers based on columns */}
                    {columns.filter(column => !column.alwaysHide).map((column) => (
                      <TableCell key={column.key}>{column.displayName}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Render table rows with checkboxes */}
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.includes(index)}
                          onChange={() => this.handleRowSelection(index)}
                        />
                      </TableCell>
                      {/* Render table cells based on non-hidden columns */}
                      {columns.filter(column => !column.alwaysHide).map((column) => (
                        <TableCell key={column.key}>{row[column.key]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            
            )}
            {step === 2 && (
              <div>
                <h2>Select Graph Type</h2>
                <FormControl>
                  <InputLabel>Graph Type</InputLabel>
                  <Select value={graphType} onChange={this.handleGraphTypeChange}>
                    <MenuItem value="bar">Bar Plot</MenuItem>
                    <MenuItem value="scatter">Scatter Plot</MenuItem>
                  </Select>
                </FormControl>
                
              </div>
            )}

            {step === 3 && graphType === 'scatter' && (
              <div>
              <h2>Select Columns for Scatter Plot</h2>
              
              <div>
                <FormControl style={{ width: '200px' }}>
                  <InputLabel>X-Axis Column</InputLabel>
                  <Select value={xKeyName} onChange={this.handleXKeyChange}>
                    {columns
                      .filter(column => column.type === 'number' && !column.alwaysHide)
                      .map(column => (
                        <MenuItem key={column.key} value={column.key}>
                          {column.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            
              <div>
                <FormControl style={{ width: '200px' }}>
                  <InputLabel>Y-Axis Column</InputLabel>
                  <Select value={yKeyName} onChange={this.handleYKeyChange}>
                    {columns
                      .filter(column => column.type === 'number' && !column.alwaysHide)
                      .map(column => (
                        <MenuItem key={column.key} value={column.key}>
                          {column.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            
              <div>
                <FormControl style={{ width: '200px' }}>
                  <InputLabel>Dot Size</InputLabel>
                  <Select value={dotSize} onChange={this.handleDotSizeChange}>
                    {columns
                      .filter(column => column.type === 'number' && !column.alwaysHide)
                      .map(column => (
                        <MenuItem key={column.key} value={column.key}>
                          {column.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            
              {/* Repeat for y-axis and dot size */}
            </div>
            
            
            )}
            {step === 3 && graphType === 'bar' && (
              <div>
              <h2>Select Columns for Scatter Plot</h2>
              
              <div>
                <FormControl style={{ width: '200px' }}>
                  <InputLabel>X-Axis Column</InputLabel>
                  <Select value={xKeyName} onChange={this.handleXKeyChange}>
                    {columns
                      .filter(column => column.type === 'number' && !column.alwaysHide)
                      .map(column => (
                        <MenuItem key={column.key} value={column.key}>
                          {column.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div>
              <FormControl style={{ width: '200px' }}>
                <InputLabel>X-Axis Column #2</InputLabel>
                <Select value={xKeyName1} onChange={this.handleXKeyChange1}>
                    <MenuItem key={0} value={undefined}>Undefined</MenuItem>
                    {columns
                    .filter(column => column.type === 'number' && !column.alwaysHide)
                    .map(column => (
                        <MenuItem key={column.key} value={column.key}>
                        {column.displayName}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
              </div>
            
              <div>
                <FormControl style={{ width: '200px' }}>
                  <InputLabel>Label</InputLabel>
                  <Select value={yKeyName} onChange={this.handleYKeyChange}>
                    {columns
                      .filter(column => !column.alwaysHide)
                      .map(column => (
                        <MenuItem key={column.key} value={column.key}>
                          {column.displayName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            
              
            
              {/* Repeat for y-axis and dot size */}
            </div>
            
            
            )}

            {step === 4  && graphType === 'scatter' && (
              <div>
                <ScatterPlot
                    data={data.filter((_, index) => selectedRows.includes(index))}
                    xKeyName={xKeyName}
                    xKeyTitle={columns.find(column => column.key === xKeyName)?.displayName || xKeyName}
                    yKeyName={yKeyName}
                    yKeyTitle={columns.find(column => column.key === yKeyName)?.displayName || yKeyName}
                    dotSize={dotSize}
                    chart_dotTitle={this.props.chart_dotTitle}
                    title="Scatter Plot"
                    backgroundColor="#ffffff"
                    avg={true}
                    />
              </div>
            )}
             
             {step === 4  && graphType === 'bar'  && (
              <div>
                <BarPlot
                    data={data.filter((_, index) => selectedRows.includes(index))}
                    xKeys={[
                        { xKeyName: xKeyName, xKeyTitle: columns.find(column => column.key === xKeyName)?.displayName || xKeyName },
                        { xKeyName: xKeyName1, xKeyTitle: columns.find(column => column.key === xKeyName1)?.displayName || xKeyName1 },
                        // Add more xKeys objects as needed
                    ]}
                    xKeyTitles={this.props.chart_dotTitle}
                    // yKeyName={yKeyName}
                    // yKeyTitle={columns.find(column => column.key === yKeyName)?.displayName || yKeyName}
                    // chart_dotTitle={this.props.chart_dotTitle}
                    percent='normal'
                    annotation={yKeyName}
                    title="Bar Plot"
                    backgroundColor="#ffffff"
                    avg={true}
                />

              </div>
            )}


            {/* Remaining steps can be similar to the previous code */}
          </DialogContent>
          <DialogActions>
            {step > 1 && <Button onClick={this.handleBack}>Back</Button>}
            {step < 4 && <Button onClick={this.handleNext}>Next</Button>}
            
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AnalyzeData;
