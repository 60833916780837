import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PopupState from 'material-ui-popup-state';

class NumberFilter extends Component {
  constructor(props) {
    super(props);

    // Initialize state to store input values
    this.state = {
      '<': this.props.existingFilters.find((v) => v.operator === '<')?.value || '',
      '>': this.props.existingFilters.find((v) => v.operator === '>')?.value || '',
      '=': this.props.existingFilters.find((v) => v.operator === '=')?.value || '',
    };
  }

  handleInputChange = (event, operator) => {
    const inputValue = event.target.value;

    // Update the state based on the operator
    this.setState({
      [operator]: inputValue,
    });
  };

  handleApplyFilter = () => {
    const { handleFilterChange, column } = this.props;
    handleFilterChange(null, column.key, column.type, null, this.state, null, this.props.popupState);

    // Iterate through each operator in the state and apply the filter only if the value has changed
    
  };
  
  

  render() {
    return (
      <div style={{ fontSize: '12px' }}>
        <div>
          <TextField
            value={this.state['<']}
            type="text"
            placeholder="Less Than"
            inputProps={{ operator: '<' }}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => this.handleInputChange(event, '<')}
          />
        </div>
        <div>
          <TextField
            value={this.state['>']}
            type="text"
            placeholder="Greater Than"
            inputProps={{ operator: '>' }}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => this.handleInputChange(event, '>')}
          />
        </div>
        <div>
          <TextField
            value={this.state['=']}
            type="text"
            placeholder="Equal To"
            inputProps={{ operator: '=' }}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => this.handleInputChange(event, '=')}
          />
        </div>
        <Button
          onClick={this.handleApplyFilter}
          variant="contained"
          color="primary"
          style={{ fontSize: '12px', marginTop: '10px' }}
        >
          Apply
        </Button>
      </div>
    );
  }
}

export default NumberFilter;
