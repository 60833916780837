import React, { Component } from 'react';
// import DataLoader from './DataLoader';
import DynamicTable from './DynamicTable';
import './App.css';
import ExpandedRow from './ExpandedRow';
import { endpoint } from './mainEndpoint';
import ColumnSettings from './ColumnSettings';
import SettingsDialog from './SettingsDialog';
import {
    TextField, Checkbox, Table,
    TableHead, TableBody, TableRow, TableCell, IconButton,
    TablePagination, Button, Menu, MenuItem, Typography,
    FormControl, InputLabel, Select, ClickAwayListener
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import { Sort } from '@material-ui/icons';
import Table_All from './Table_All';
import FolderComponent from './FolderComponent';
import ExportData from './ExportData'
import SavedFiltersColumnsComponent from './SavedFiltersColumnsComponent'
import AnalyzeData from './AnalyzeData';
import ScheduleEmailNew from './ScheduleEmailNew';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            table_dir: 'streams_table',
            columns: [
                { key: 'ARTIST', displayName: 'Artist', type: 'category', format: 'category', fix: true, optionsMatchingKey: 'SODATONE_ARTIST_ID', table: 'STREAMS_TABLE' },
                { key: 'WARNER_ARTIST_ID', displayName: 'Warner Artist ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'SODATONE_ARTIST_ID', displayName: 'Sodatone Artist ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'TITLE', displayName: 'Title', type: 'string', id: 'FAMILY_ID', track: true, fix: true, linkKey:'SPOTIFY_ID'},
                { key: 'FAMILY_ID', displayName: 'Family ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'UNIFIED_SONG_ID', displayName: 'Unified Song ID', type: 'number', format: 'literal', fix: true, hide: true, alwaysHide: true },
                { key: 'SPOTIFY_ID', displayName: 'Spotify ID', type: 'string', fix: true, hide: true, alwaysHide: true },
                // {key: 'FOLDER_ID', type:'folder', hide:true, alwaysHide: true, fixedValue:true, value:-1 },
                {key: 'MRC_TOGGLED', type:'folder', hide:true,alwaysHide: true,  value:true },
                { key: 'WEEK_0', displayName: 'Estimated Building Week Streams', type: 'number', format: 'number' },
                { key: 'BUILDING_WEEK_STREAM_COUNT', displayName: 'Building Week Streams', type: 'number', format: 'number' },
                { key: 'WEEK_0_PCT_CHANGE', displayName: 'Percent Change Building Week', type: 'number', format: 'percentChange' },
                { key: 'WEEK_1', displayName: 'Last Week Streams', type: 'number', format: 'number' },
                { key: 'WEEK_1_PCT_CHANGE', displayName: 'Percent Change Last Week', type: 'number', format: 'percentChange' },

                
                
                { key: 'GLOBAL_WEEK_0', displayName: 'Global Estimated Building Week Streams', type: 'number', format: 'number', hide: true },
                { key: 'GLOBAL_BUILDING_WEEK_STREAM_COUNT', displayName: 'Global Building Week Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_WEEK_0_PCT_CHANGE', displayName: 'Global Percent Change Building Week', type: 'number', format: 'percentChange' ,hide: true},

                { key: 'GLOBAL_WEEK_1', displayName: 'Global Last Week Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_WEEK_1_PCT_CHANGE', displayName: 'Global Percent Change Last Week', type: 'number', format: 'percentChange',hide: true },

                
                { key: 'ORGANIC_WEEK_0', displayName: 'Organic Estimated Building Week Streams', type: 'number', format: 'number', hide: true },
                { key: 'ORGANIC_BUILDING_WEEK_STREAM_COUNT', displayName: 'Organic Building Week Streams', type: 'number', format: 'number',hide: true },
                { key: 'ORGANIC_WEEK_0_PCT_CHANGE', displayName: 'Organic Percent Change Building Week', type: 'number', format: 'percentChange' ,hide: true},

                { key: 'ORGANIC_WEEK_1', displayName: 'Organic Last Week Streams', type: 'number', format: 'number',hide: true },
                { key: 'ORGANIC_WEEK_1_PCT_CHANGE', displayName: 'Organic Percent Change Last Week', type: 'number', format: 'percentChange',hide: true },

                { key: 'PROGRAMMED_WEEK_0', displayName: 'Programmed Estimated Building Week Streams', type: 'number', format: 'number', hide: true },
                { key: 'PROGRAMMED_BUILDING_WEEK_STREAM_COUNT', displayName: 'Programmed Building Week Streams', type: 'number', format: 'number',hide: true },
                { key: 'PROGRAMMED_WEEK_0_PCT_CHANGE', displayName: 'Programmed  Percent Change Building Week', type: 'number', format: 'percentChange' ,hide: true},

                { key: 'PROGRAMMED_WEEK_1', displayName: 'Programmed Last Week Streams', type: 'number', format: 'number',hide: true },
                { key: 'PROGRAMMED_WEEK_1_PCT_CHANGE', displayName: 'Programmed Percent Change Last Week', type: 'number', format: 'percentChange',hide: true },


                // { key: 'WEEK_2', displayName: '2 Week Ago Streams', type: 'number',format :'number'},
                // { key: 'WEEK_2_PCT_CHANGE', displayName: 'Percent Change 2 Weeks Ago', type: 'number',format :'percentChange' },
                // { key: 'WEEK_3', displayName: '3 Week Ago Streams', type: 'number',format :'number'},
                // { key: 'WEEK_3_PCT_CHANGE', displayName: 'Percent Change 3 Weeks Ago', type: 'number', format :'percentChange'},


                { key: 'BUILDING_WEEK_POST_COUNT', displayName: 'Building Week TikTok Creates', type: 'number', format: 'number',hide: true },
                { key: 'PCT_CHANGE_POST_COUNT', displayName: 'Percent Change Building Week TikTok Creates', type: 'number', format: 'percentChange',hide: true },




                { key: 'BW_SPOTIFY_SKIP_RATE', displayName: 'BW Spotify Skip Rate', type: 'number', format: 'number',hide: true },
                { key: 'BW_SPOTIFY_PASSION_SCORE', displayName: 'BW Spotify Passion Score', type: 'number', format: 'number' ,hide: true},
                { key: 'BW_PCT_US_ORGANIC_STREAMS', displayName: 'BW Percentage US Organic Streams', type: 'number', format: 'percentChange',hide: true },

                { key: 'LW_SPOTIFY_PASSION_SCORE', displayName: 'LW Passion Score', type: 'number', format: 'number',hide: true },
                { key: 'LW_SPOTIFY_SKIP_RATE', displayName: 'LW Skip Rate', type: 'number', format: 'percentChange',hide: true },

                { key: 'LW_US_ORGANIC_STREAMS', displayName: 'LW US Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'LW_PCT_US_ORGANIC_STREAMS', displayName: 'LW Percentage US Organic Streams', type: 'number', format: 'percentChange',hide: true },


                { key: 'FIRST_FRIDAY_STREAM_COUNT', displayName: 'First Friday Streams', type: 'number', format: 'number' ,hide: true},
                { key: 'FIRST_FRIDAY_ORGANIC_STREAMS', displayName: 'First Friday Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIRST_FRIDAY_PROGAMMED_STREAMS', displayName: 'First Friday Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIRST_FRIDAY_PCT_ORGANIC_STREAMS', displayName: 'First Friday % Organic', type: 'number', format: 'number',hide: true },
                { key: 'FIRST_FRIDAY_PASSION_SCORE', displayName: 'First Friday Passion Score', type: 'number', format: 'number',hide: true },
                { key: 'FIRST_FRIDAY_SKIP_RATE', displayName: 'First Friday Skip Rate', type: 'number', format: 'number',hide: true },

                { key: 'THREE_DAY_STREAM_COUNT', displayName: 'Three Day Streams', type: 'number', format: 'number',hide: true },
                { key: 'THREE_DAY_ORGANIC_STREAMS', displayName: 'Three Day Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'THREE_DAY_PROGRAMMED_STREAMS', displayName: 'Three Day Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'THREE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Three Day % Organic', type: 'number', format: 'percentChange',hide: true },
                { key: 'THREE_DAY_PASSION_SCORE', displayName: 'Three Day Passion Score', type: 'number', format: 'number',hide: true  },
                { key: 'THREE_DAY_SKIP_RATE', displayName: 'Three Day Skip Rate', type: 'number', format: 'number',hide: true },

                { key: 'FIVE_DAY_STREAM_COUNT', displayName: 'Five Day Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIVE_DAY_ORGANIC_STREAMS', displayName: 'Five Day Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIVE_DAY_PROGRAMMED_STREAMS', displayName: 'Five Day Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIVE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Five Day % Organic', type: 'number', format: 'percentChange',hide: true },
                { key: 'FIVE_DAY_PASSION_SCORE', displayName: 'Five Day Passion Score', type: 'number', format: 'number' ,hide: true},
                { key: 'FIVE_DAY_SKIP_RATE', displayName: 'Five Day Skip Rate', type: 'number', format: 'number',hide: true },


                { key: 'FIRST_WEEK_STREAM_COUNT', displayName: 'First Week Streams', type: 'number', format: 'number' ,hide: true},
                { key: 'FIRST_WEEK_ORGANIC_STREAMS', displayName: 'First Week Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIRST_WEEK_PROGRAMMED_STREAMS', displayName: 'First Week Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'FIRST_WEEK_PCT_ORGANIC_STREAMS', displayName: 'First Week % Organic', type: 'number', format: 'percentChange',hide: true },
                { key: 'FIRST_WEEK_PASSION_SCORE', displayName: 'First Week Passion Score', type: 'number', format: 'number' ,hide: true},
                { key: 'FIRST_WEEK_SKIP_RATE', displayName: 'First Week Skip Rate', type: 'number', format: 'percentChange',hide: true },


                { key: 'GLOBAL_FIRST_FRIDAY_STREAM_COUNT', displayName: 'Global First Friday Streams', type: 'number', format: 'number' ,hide: true},
                { key: 'GLOBAL_FIRST_FRIDAY_ORGANIC_STREAMS', displayName: 'Global First Friday Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIRST_FRIDAY_PROGAMMED_STREAMS', displayName: 'Global First Friday Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIRST_FRIDAY_PCT_ORGANIC_STREAMS', displayName: 'Global First Friday % Organic', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIRST_FRIDAY_PASSION_SCORE', displayName: 'Global First Friday Passion Score', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIRST_FRIDAY_SKIP_RATE', displayName: 'Global First Friday Skip Rate', type: 'number', format: 'number',hide: true },

                { key: 'GLOBAL_THREE_DAY_STREAM_COUNT', displayName: 'Global Three Day Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_THREE_DAY_ORGANIC_STREAMS', displayName: 'Global Three Day Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_THREE_DAY_PROGRAMMED_STREAMS', displayName: 'Global Three Day Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_THREE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Global Three Day % Organic', type: 'number', format: 'percentChange',hide: true },
                { key: 'GLOBAL_THREE_DAY_PASSION_SCORE', displayName: 'Global Three Day Passion Score', type: 'number', format: 'number',hide: true  },
                { key: 'GLOBAL_THREE_DAY_SKIP_RATE', displayName: 'Global Three Day Skip Rate', type: 'number', format: 'number',hide: true },

                { key: 'GLOBAL_FIVE_DAY_STREAM_COUNT', displayName: 'Global Five Day Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIVE_DAY_ORGANIC_STREAMS', displayName: 'Global Five Day Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIVE_DAY_PROGRAMMED_STREAMS', displayName: 'Global Five Day Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIVE_DAY_PCT_ORGANIC_STREAMS', displayName: 'Global Five Day % Organic', type: 'number', format: 'percentChange',hide: true },
                { key: 'GLOBAL_FIVE_DAY_PASSION_SCORE', displayName: 'Global Five Day Passion Score', type: 'number', format: 'number' ,hide: true},
                { key: 'GLOBAL_FIVE_DAY_SKIP_RATE', displayName: 'Global Five Day Skip Rate', type: 'number', format: 'number',hide: true },


                { key: 'GLOBAL_FIRST_WEEK_STREAM_COUNT', displayName: 'Global First Week Streams', type: 'number', format: 'number' ,hide: true},
                { key: 'GLOBAL_FIRST_WEEK_ORGANIC_STREAMS', displayName: 'Global First Week Organic Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIRST_WEEK_PROGRAMMED_STREAMS', displayName: 'Global First Week Programmed Streams', type: 'number', format: 'number',hide: true },
                { key: 'GLOBAL_FIRST_WEEK_PCT_ORGANIC_STREAMS', displayName: 'Global First Week % Organic', type: 'number', format: 'percentChange',hide: true },
                { key: 'GLOBAL_FIRST_WEEK_PASSION_SCORE', displayName: 'Global First Week Passion Score', type: 'number', format: 'number' ,hide: true},
                { key: 'GLOBAL_FIRST_WEEK_SKIP_RATE', displayName: 'Global First Week Skip Rate', type: 'number', format: 'percentChange',hide: true },


                { key: 'YTD', displayName: 'YTD', type: 'number', format: 'number' },
                { key: 'ATD', displayName: 'ATD', type: 'number', format: 'number' },
                { key: 'RELEASE_DATE', displayName: 'Release Date', type: 'date' },
                { key: 'DAYS_SINCE_RELEASE', displayName: 'Days Since Release', type: 'number', format:'number'}


            ],
            filters: [{
                column: 'MRC_TOGGLED',
                operator: 'TOGGLE',
                value: true
            },
            {
                column: 'FOLDER_ID',
                operator: 'FOLDER',
                value: -1
            }
            ],
            sort: {key: 'WEEK_0', type:'number',order: 'desc'},
            selectedFolder: -1,
            selectedFilterColumn:-1,
            data:[],
            folders:[],
            searchValue: '',
            folderType:'songs',
 
        };

    }
    handleToggleChange = (event) => {
        const { filters } = this.state;
        const columnKeyToUpdate = 'MRC_TOGGLED';
    
        let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
        console.log(existingColumn)
        if (existingColumn) {
          // Set the existingColumn.hide as the inverse of its current value
          existingColumn.value = !existingColumn.value;
    
          // Update the state with the modified columns
          
          this.setState(
            {
              filters: [...filters.filter((filter) => filter.column !== columnKeyToUpdate), existingColumn],
              pageNumber: 1
            },
            // this.fetchData
          );
        }
        // const toggleValue = event.target.checked;
        // this.setState(
        //   {
        //     toggleValue,
        //     pageNumber: 1
        //   },
        //   this.fetchData
        // );
    
      };
      handleApplyColumnSettings = (columnHideSettings) => {
        // Separate pinned and alwaysHide columns
        const pinnedColumns = [];
        const alwaysHideColumns = [];
        const otherColumns = [];
        console.log(columnHideSettings)
        this.state.columns.forEach((setting) => {
          const correspondingColumn = columnHideSettings.find((column) => column.key === setting.key);
          if (setting) {
            if (setting.fix) {
              pinnedColumns.push({ ...setting});
            } else if (setting.alwaysHide) {
              alwaysHideColumns.push({ ...setting });
            } else {
            //   otherColumns.push({ ...correspondingColumn });
            }
          }
        });
        console.log(pinnedColumns)
        console.log(alwaysHideColumns)
        console.log(otherColumns)
        // Combine columns with the desired order
        const updatedColumns = [...pinnedColumns, ...alwaysHideColumns, ...columnHideSettings];
      
        // Update the state with the modified columns
        this.setState({
          columns: updatedColumns,
        });
      };
      


      setSelectedFilterColumns = (folderId) => {
        this.setState((prevState) => {
            const { filters } = prevState;
            const columnKeyToUpdate = 'FOLDER_ID';
    
            let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
    
            if (existingColumn) {
                existingColumn.value = folderId;
    
                // Return the updated state
                return {
                    filters: [...filters.filter((filter) => filter.column !== columnKeyToUpdate), existingColumn],
                    pageNumber: 1,
                    selectedFilterColumn: folderId,
                };
            } else {
              return {
                selectedFilterColumn: folderId
              }
            }
            
    
            return prevState; // No changes needed if the column is not found
        });
    };
    
      // Callback function to set the selected folder in the state
      setSelectedFolder = (folderId) => {
        // this.setState({ selectedFolder: folderId });
        const { filters } = this.state;
        console.log('aa')
        console.log(filters)
        const columnKeyToUpdate = 'FOLDER_ID';
    
        let existingColumn = filters.find((filter) => filter.column === columnKeyToUpdate);
        console.log(existingColumn)
        if (existingColumn) {
          // Set the existingColumn.hide as the inverse of its current value
          existingColumn.value = folderId;
    
          // Update the state with the modified columns
          
          this.setState(
            {
              filters: [...filters.filter((filter) => filter.column !== columnKeyToUpdate), existingColumn],
              pageNumber: 1,
              selectedFolder: folderId
            },
            // this.fetchData
          );
        } else {
          // Add a new filter with column 'FOLDER_ID'
          const newFilter = {
              column: 'FOLDER_ID',
              operator: 'FOLDER', // You can set the operator as per your requirement
              value: folderId
          };
      
          // Update the state with the new filter
          this.setState(
              {
                  filters: [...filters, newFilter],
                  pageNumber: 1,
                  selectedFolder: folderId
              },
              // this.fetchData
          );
      }
        console.log(this.state.filters)
      };
      setData = (data) => {
        console.log('Setting data in Table_All:', data);
        this.setState({ data: data });
      };
      setFilters = (filters) => {
        this.setState({ filters: filters });
      };
      setColumns = (columns) => {
        this.setState({columns: columns });
      };
      

      setSort = (sort) => {
        this.setState({ sort: sort });
      };
      
      setFolders = (folders) => {
        this.setState({ folders: folders });
      };

      handleSearchChange = (event) => {
        const searchValue = event.target.value;
        this.setState(
          {
            searchValue,
          },
        );
      };
      
    render() {
        // const endpoint = 'http://localhost:3500'; // Replace with your desired endpoint
        const { columns, selectedFilterColumn, filters,table_dir,sort,searchValue, selectedFolder,data,folders, folderType} = this.state;
        console.log(selectedFilterColumn)
   
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft:'7px'}}>
                
                    <TextField
                      type="text"
                      placeholder="Search Song or Artist..."
                      value={searchValue}
                      onChange={this.handleSearchChange}
                    />
                    <div style={{ marginLeft: '10px' }}>
                    <span >MRC Data</span>

                    <Checkbox
                    checked={filters.find((filter) => filter.column === 'MRC_TOGGLED').value}
                    onChange={this.handleToggleChange}
                    color="primary"
                    />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                    <FolderComponent
                    setSelectedFolder={this.setSelectedFolder}
                    setFolders={this.setFolders}
                    folderType={folderType}
                    
                    />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                    <SavedFiltersColumnsComponent 
                    columns={columns}
                    filters={filters}
                    sort={sort}
                    setColumns={this.setColumns}
                    setSort={this.setSort}
                    setFilters={this.setFilters}
                    setSelectedFilterColumns = {this.setSelectedFilterColumns}
                    folderType={folderType}
                    />
                    </div>

                    <div style={{ marginLeft: '10px' }}>
                    <SettingsDialog
                    columns={columns}
                    handleApplyColumnSettings={this.handleApplyColumnSettings}
                    
                    />
                    </div>

                    <div style={{ marginLeft: '10px' }}>
                    <ExportData
                    columns={columns}
                    filters={filters}
                    table_dir={table_dir}
                    sort={sort}
                    // selectedFolder={selectedFolder}
                    data={data}
                    folders={folders}
                    table_endpoint={'streams_table'}
                    searchValue={searchValue}
                    />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                    <AnalyzeData
                    columns={columns}
                    // filters={filters}
                    // table_dir={table_dir}
                    // sort={sort}
                    // selectedFolder={selectedFolder}
                    data={data}
                    chart_dotTitle={'TITLE'}
                    // folders={folders}
                    />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                    <ScheduleEmailNew
                    // columns={columns}
                    selectedFilterColumn={selectedFilterColumn}
                    scheduleTable={"EMAIL_SCHEDULE_ROSTER"}
                    filterTable={"SAVED_COLUMNS_FILTERS" }
                    // handleApplyColumnSettings={this.handleApplyColumnSettings}
                    
                    />
                    </div>
                    

                    </div>

                <Table_All
                    columns={columns}
                    filters={filters}
                    table_dir={table_dir}
                    sort= {sort}
                    selectedFolder={selectedFolder} 
                    setData= {this.setData}
                    folders={folders}
                    setFilters={this.setFilters}
                    setSort={this.setSort}
                    searchValue={searchValue}
                    ExpandedRow={ExpandedRow}
                    folderType={folderType}
                    />
            </div>
        );

    }
}

export default withOktaAuth(App);


